.page--quests {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

    }

    .navigation-title {

        margin-bottom: 30px;

    }

    .toolbar {

        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;

    }

    .league-card {

        margin-bottom: 20px;

    }

    .leagues {

        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;

        .league-card {

            width: calc( ( ( 100% - 60px ) / 4 ) - 40px );

        }

    }

    .quests-toolbar {

        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;

        button {

            cursor: pointer;

        }

    }

    .new-quest-button {

        background-color: #FFFFFF;
        border: 3px solid #FFD201;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.08);
        border-radius: 8px;
        color: #363638;
        font-size: 16px;
        font-weight: 700;
        line-height: 17px;
        margin-right: 10px;
        height: 47px;
        width: 474px;

    }

    .new-quest-group-button {

        background-color: #FFFFFF;
        border: 3px solid #0267AA;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.08);
        border-radius: 8px;
        color: #363638;
        font-size: 16px;
        font-weight: 700;
        line-height: 17px;
        margin-right: 10px;
        height: 47px;
        width: 474px;

    }

    .modal {

        text-align: center;

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 40px;

    }

    .modal-description {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 28px;
        padding: 0 100px;

    }

    .button {

        margin: 0 10px;

        &--warning {

            background-color: rgba( 195, 8, 112, 0.2 );
            border-color: #C30870;
            color: #C30870;

        }

    }

}