.questionnaire-user-answer-table-container {

    .questionnaire-user-answer-table {

        &__questionnaire-user-answer-element {
            list-style: none;
            width: 100%;
            height: 100%;
            margin-top: 20px;      
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            background-color: white !important;
            border: 1px solid transparent;
            border-radius: 8px;
            box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
   
            .questionnaire-user-answer-element-indicator {
                width: 10px;
                display: flex;
                padding: 0;
                margin: 0;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border: 1px solid var(--color-blue);
                background-color: var(--color-blue);
            }

            .text-section-container{
                display: flex;
                width: 100%;

                flex-direction: column;
                justify-content: center;
                align-items: center;
             }

            .questionnaire-user-answer-element-container {
                padding: 20px;
                width: calc(100% - 20px - 20px);
            }

            .question-image {
                max-height: 300px;
                margin-top: 20px;
            }

        }
  
        
    }

    .input-container-header {
        text-align: bottom !important;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0px;
        width: 100%;
    }

    .percent-text-div {
        display: flex;
        width: 255px;
        margin-top: 10px;
        flex-direction: row;
        justify-content: left;
        align-items: stretch;

        .percent-div {
            height:40px;
            width: 50%;
            margin-right: 10px;
            display: flex;
            flex-direction: row;
            justify-content: left;
            background: lightgray;

            .percent {
                width: 100%;
                background-color: var(--color-primary);
            }
        }

        .text {
            width: 50%;
            flex-direction: row;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: left;
            font-size: 14px;
            line-height: 16px;
        }
    }


    .questionnaire-question-answer-table {
        padding: 0px;
        margin: 0px;
    }

    .question-scale-container-div{
        width: 100%;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
    }

    .questionnaire-question-answer-image-table-container {
        margin-top: 0px !important;
    }
}
