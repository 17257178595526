.politicians-modal {

    .politicians-table-list {

        &__news-category-element {
            list-style: none;
            width: calc(100% - 16px - 16px);
            padding: 0px 16px;
            height: 41px;
            display:flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            background-color: white !important;
            border: 1px solid var(--color-light-gray);
            border-radius: 8px;
            margin-bottom: 8px;
            cursor: pointer;

            &:hover {
                border: 1px solid var(--color-primary);
                background-color: var(--color-highlight) !important;
            }
        }

        .active {
            border: 1px solid var(--color-primary);
        }
  
        .name{
            width: 60%;
            font-weight: 700;
            font-size: 16px;
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    }

}
