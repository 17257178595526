.add-image {
    .add-image-container {
        height: 100%;
        border-radius: 5px;
        text-align: center;
        border: 1px solid var(--color-primary);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        transition: background-color var(--transition-speed);

        &:hover {
            background-color: var(--color-highlight);
        }

        .add-image-inner-container {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            background: var(--color-primary);
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 10px;

            color: white !important;
        }
    }

    .add-image-wrapper {
        width: 100%;
        border-radius: 10px;
        position: relative;

        .button--menu--container {
            position: absolute;
            z-index: 1;
            bottom: 10px;
            right: 10px;
            width: 26px;
            height: 26px;
        }
    }

    .add-image-image {
        width: 100%;
        height: 100%;
        border: 1px solid transparent;
        border-radius: 10px;
        border: 1px solid var(--color-primary);
    }
}
