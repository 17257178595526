.dashboard-table-element {
    list-style: none;
    width: 100% ;
    display:flex;
    flex-direction: column;
    justify-content: left;

    .dashboard-table-element-container {
        width: 100% ;
        margin-bottom: 12px; 
        margin-top: 12px;            
        display: flex;
        flex-direction: row;
        justify-content: left;

        .dashboard-table-element-image {
            width: 48px ;
            height: 36px ; 
            object-fit: cover;         
            margin-right: 20px;   
            border-radius: 4px;
            border: 1px solid var(--color-primary);     
            background-color:  var(--color-primary);         
        }

        .dashboard-table-element-text-container {
            width: 100% ;
            display: flex;
            margin: 0;
            flex-direction: column;
            justify-content: left;

            .dashboard-table-element-title-text {
                margin: 0;
                margin-top: 2px;
                margin-bottom: 2px;
                text-align: left;
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;
                color: var(--color-font);
                font-family: var(--font-primary);
                white-space: pre-line;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 16px;
                width: 80%;

            }
    
            .dashboard-table-element-description-text {
                margin: 0;
                text-align: left;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                color: var(--color-font);
                font-family: var(--font-primary);
                white-space: pre-line;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 14px;
                width: 80%;

            }
    
        }

    }

    .dashboard-table-element-divider {
        margin: 0;
        width: 100% ;
        height: 1px ;          
        background-color: var(--color-border);    
    }
    
}