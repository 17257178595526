.page-questionnaires {
    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        padding: 15px;

    }

    .main-container {

        .card-div {

            @media (max-width: 576px) {

                flex-direction: column;

            }

        }

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: 100%;

        }

        a {
            color: inherit;
            text-decoration: none;
        }

        .toolbar {
            margin-top: 28px;
            height: 36px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: left;
        }

        .sub-container {
            width: 33%;
            display: flex;
            flex-direction: column;
            justify-content: left;

            @media (max-width: 576px) {

                margin-bottom: 30px;
                width: 100%;

                &:last-child {

                    margin-bottom: 0;

                }

            }
        }

        .title-text-h3-margin {
            margin-top: 18px;
            margin-bottom: 18px;
        }

        .separator {
            background-color: var(--color-light-gray);
            height: 100%;
            margin-left: 33px;
            margin-right: 33px;
            width: 1px;

            @media (max-width: 576px) {

                display: none;

            }
        }

        .news_category_container {

            height: 115px !important;
            justify-content: left;
            align-items: center;
            display: flex;
            flex-direction: row;

            @media (max-width: 576px) {

                margin-top: 15px;
                height: unset !important;

            }

            .image {
                height: 48px;
                width: 80px;
                margin-top: 15px;
                margin-bottom: 15px;
            }

            .text_container {
                margin: 16px 20px;

                .description {
                    margin-top: 8px;
                }
            }

            .card_container {
                cursor: pointer;
                transition: background-color var(--transition-speed);

                &:hover {
                    background-color: var(--color-highlight);
                }
            }


        }

    }

}
