.layout--login {

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: var(--inner-height);
    position: relative;

    .background {

        background-image: url('../../../public/image/dk-logo.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        filter: blur(36px);
        -webkit-filter: blur(36px);
        height: 279.31px;
        left: calc(50% - (314px / 2));
        opacity: 0.13;
        pointer-events: none;
        position: absolute;
        top: calc(50% - (279.31px / 2));
        width: 314px;
        z-index: -1;
        
    }

}
