.area--text {
    margin-top: 15px;
    
    textarea {
        background-color: white !important;
        border: 1px solid var(--color-border) !important;
        border-radius: 8px !important;
        font-size: 18px !important;
        line-height: 20px !important;
        padding: 18px 16px !important;
        width: calc(100% - 16px - 16px) !important;
//height: calc(48px - 16px - 16px) !important;
        resize: none;
        resize: none;
        overflow: hidden;
    }

    .lead {
        color: var(--color-font);
        font-size: 16px;
        font-weight: 700;
        line-height: 17px;
        min-height: calc(78px - 16px - 16px) !important;
    }

    .description {
        color: var(--color-font);
        font-size: 16px;
        font-weight: 400;
        line-height: 17px;
        min-height: calc(180px - 16px - 16px) !important;
    }

    .question {
        color: var(--color-font);
        font-size: 16px;
        font-weight: 700;
        line-height: 17px;
        min-height: calc(57px - 16px - 16px) !important;
    }

    .answer {
        color: var(--color-font);
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        min-height: calc(57px - 20px - 20px) !important;
        padding: 18px 16px !important

    }

    .position {
        color: var(--color-font);
        font-size: 16px;
        font-weight: 700;
        line-height: 17px;
        min-height: calc(57px - 16px - 16px) !important;
    }

}
