.info-slot {
  align-items: center;
  background-color: var(--color-white);
  border-radius: 8px;
  display: flex;
  padding: 12px 20px;

  &__description {
    display: flex;
    flex-direction: column;
    margin-right: auto;

    .caption {
      margin-bottom: 4px;
    }

    .additional-info {
      font-size: 12px;
      line-height: 13px;
    }
  }

  &__info {
    font-size: 17px;
    font-weight: 700;
    line-height: 18px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: right;

    @media (max-width: 576px) {
      margin-right: auto;
      margin-left: auto;
    }
    .value {
      @media (max-width: 576px) {
        font-size: 16px;
      }
      font-size: 24px;
    }
  }

  &--green {
    box-shadow: 0 0 10px 0 rgba(39, 176, 108, 0.3);

    .additional-info {
      color: #27b06c;
    }
  }

  &--red {
    box-shadow: 0 0 10px 0 rgba(195, 8, 112, 0.3);

    .additional-info {
      color: #c30870;
    }
  }
}
