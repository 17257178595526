.folder {

    border: 1px solid transparent;
    border-radius: 6px;
    padding: 8px 7px 5px 7px;

    &__icon {

        aspect-ratio: 161 / 107;
        margin-bottom: 9px;
        position: relative;
        width: 100%;

        &::before {

            background-color: var(--color-primary);
            content: "";
            height: 75px;
            left: calc(50% - (103px / 2));
            position: absolute;
            top: calc(50% - (75px / 2));
            width: 103px;

        }

        &::after {

            background-color: var(--color-white);
            content: "";
            height: 5px;
            left: calc(50% - (103px / 2) + (103px * 0.6));
            position: absolute;
            top: calc(50% - (75px / 2));
            width: calc(103px * 0.4);

        }

    }

    &__name {

        color: #363638;
        font-size: 14px;
        line-height: 16px;
        max-height: 32px;
        text-align: center;
        overflow: hidden;

    }

    &.selected {

        border-color: var(--color-primary);

    }

}
