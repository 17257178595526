.prize-card-div {

    flex-direction: column !important;
    padding: 20px !important;
    width: calc( 100% - 40px ) !important;

    .columns {

        display: flex;

    }

    &__title {

        color: var( --color-primary );
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 20px;

    }


    .field {

        margin-bottom: 15px;
        width: 100%;

        &--name {

            margin-right: 20px;
            width: calc( 100% - 300px - 20px );

        }

        &--type {

            width: 300px;

        }

        &--image {

            margin-right: 20px;
            width: 260px;

        }

        label {

            color: var( --color-primary );
            display: flex;
            font-size: 18px;
            line-height: 20px;
            font-weight: 700;
            min-height: 20px;
            margin-bottom: 13px;

        }

        input, textarea {

            color: #363638 !important;
            font-size: 16px !important;
            font-weight: 700 !important;
            line-height: 17px !important;
            padding: 14px 20px !important;
            width: calc( 100% - 40px ) !important;

        }

        textarea {

            color: var( --color-primary ) !important;
            font-weight: 700 !important;
            height: calc( 3 * 17px ) !important;

        }

        .description-editor-container {

            margin-left: auto;

        }

        .area--description {

            min-height: calc( 3 * 19px ) !important;

        }

        .select-box--box, .select-box--container {

            border-radius: 8px;
            height: unset !important;

        }

        .select-box--selected-item {

            font-size: 16px !important;
            height: 19px;
            line-height: 21px !important;
            padding: 14px 20px !important;

        }

        .select-box--arrow {

            padding-top: 12px;

        }

    }

    footer {

        display: flex;
        justify-content: flex-end;

    }

}