.quotes-table {

    .table__header {

        img {

            cursor: pointer;
            margin-right: 15px;

        }

    }

}

.quotes-table-confirm-modal {

    text-align: center;

    .button {

        margin: 0 10px;

        &--warning {

            background-color: rgba( 195, 8, 112, 0.2 );
            border-color: #C30870;
            color: #C30870;

        }

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 40px;

    }

    .modal-description {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 28px;
        padding: 0 100px;

    }

}