.page--quotes {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        padding: 15px;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: 100%;

        }

    }

    .navigation-title {

        margin-bottom: 28px;

    }

    .toolbar {

        display: flex;
        margin-bottom: 33px;

        @media (max-width: 576px) {

            flex-wrap: wrap;

            .field--search {

                width: calc( 100% - 22px - 16px - 16px - 2px - 16px );

            }

            .deleted-button {

                margin-left: 0 !important;
                margin-top: 11px;
                min-width: unset !important;
                width: calc( 50% - 5.5px );

            }

            .parties-button {

                margin-top: 11px;
                min-width: unset !important;
                width: calc( 50% - 5.5px );

            }

            .new-a {

                margin-left: 0 !important;
                margin-top: 11px;
                width: calc( 50% - 5.5px );

                .new-button {

                    margin-left: 0;
                    min-width: unset !important;
                    width: 100%;

                }

            }

        }

        .button-icon {

            margin-right: 5px;

        }

        .button {

            margin-left: 10px;

            @media (max-width: 576px) {

                height: 38px;
                margin-left: 11px;

            }

        }

    }

    .card-div {

        border: none;
        display: flex;
        flex-direction: column;
        margin-bottom: 72px;
        overflow: hidden;
        padding: 20px;

        .title-text-h2 {

            margin-bottom: 20px;

        }

        &:last-child {

            margin-bottom: 0;

        }

    }

    .sch-button {

        @media (max-width: 576px) {

            min-width: unset !important;

        }

    }

    .scheduled-button {

        @media (max-width: 576px) {

            min-width: 100% !important;

        }

    }

    .quote-cards {

        display: flex;

        @media (max-width: 576px) {

            align-items: center;
            flex-direction: column;

        }

        .quote-card {

            cursor: pointer;
            margin-right: 15px;

            @media (max-width: 576px) {

                margin-bottom: 15px;

            }

            &:last-child {

                margin-right: 0;

            }

        }

    }

    .quote-info {

        border-radius: 8px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
        color: var(--color-dark);
        font-size: 14px;
        line-height: 16px;
        margin-left: 15px;
        padding: 17px 12px 17px 16px;
        width: calc(382px - 12px - 16px);

        @media (max-width: 576px) {

            margin-left: 0;
            width: calc( 100% - 12px - 16px );

        }

        &__title {

            color: var(--color-primary);
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 10px;

        }

    }

    .quote-cards-footer {

        display: flex;
        justify-content: flex-end;
        margin-top: 30px;

        @media (max-width: 576px) {

            flex-direction: column;

        }


        .button {

            margin-left: 15px;

            @media (max-width: 576px) {

                margin-left: 0;

            }

            &:first-child {

                margin-left: 0;

                @media (max-width: 576px) {

                    margin-bottom: 15px;

                }

            }

        }

    }

    .quotes-table {

        margin: -20px;

        label.checkbox {

            margin: 0;

        }

    }

    .filters {

        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;

        .select-box--box {

            margin-top: 0;

        }

        .politician-filter {

            margin-right: 20px;
            width: 300px;

        }

    }

}
