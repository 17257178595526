.quest-group-quests {

    margin-top: 20px;

    .caption {

        border-bottom: 1px dashed var( --color-border );
        color: var( --color-primary );
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        padding-bottom: 20px;

    }

}