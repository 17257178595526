.politician-biography-list {

    &__politician-biography-element {
        list-style: none;
        width: 100%;
        height: auto;
        display:flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        margin-top: 38px;


        .column {
            width: 100%;
            display: flex;
            flex-direction: row !important;
            justify-content: left;
        
            .year-div {
                width: 16% !important;
                margin-right: 38px ;
            }

            .title-div {
                width: 84% !important;

                .title-div-header {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
            }
        }

        .row {
            width: 100%;
            display:flex;
            flex-direction: column;
            justify-content: left;
            align-items: left;
        }

        .bottom-margin {
            margin-bottom: 15px;
        }

        .delete {
            padding: 4px;
            margin-bottom: 10px;
            border: 1px solid transparent;
            border-radius: 4px;
            cursor: pointer;
            transition: background-color var(--transition-speed);
        
            &:hover {
                background-color: var(--color-highlight);
            }

        }
    
    }

}

.politician-biography-confirm-modal {

    text-align: center;

    .button {

        margin: 0 10px;

        &--warning {

            background-color: rgba( 195, 8, 112, 0.2 );
            border-color: #C30870;
            color: #C30870;

        }

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 40px;

    }

    .modal-description {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 28px;
        padding: 0 100px;

    }

    footer {

        justify-content: center !important;
        margin-bottom: 0 !important;

    }

}