.admin-modal {
  position: relative;
  z-index: 10000;

  .modal {
    padding: 35px;
    @media (max-width: 576px) {
      padding: 5.167vw;
    }
  }

  header {
    margin-bottom: 31px;
    text-align: center;
    @media (max-width: 576px) {
      margin-bottom: 4.056vw;
    }
  }

  main {
    margin-bottom: 30px;
    @media (max-width: 576px) {
      margin-bottom: 3.778vw;
    }
    .info-text {
      color: #0267aa;
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 30px;
      margin-left: 260px;
      margin-top: 30px;
      text-align: center;
      width: 218px;
      @media (max-width: 576px) {
        font-size: 4.056vw;
        line-height: 4.444vw;
        margin-bottom: 3.778vw;
        margin-left: 26.625vw;
        margin-top: 3.778vw;
        width: 20.306vw;
      }
    }

    .row {
      align-items: center;
      display: flex;
      margin-bottom: 6px;
      @media (max-width: 576px) {
        margin-bottom: 0.833vw;
      }
      label {
        color: #0267aa;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        margin-right: 56px;
        width: 119px;
        @media (max-width: 576px) {
          font-size: 3.5vw;
          line-height: 3.778vw;
          margin-right: 16.556vw;
          width: 10.028vw;
        }
      }

      .field {
        margin-right: 85px;
        width: 398px;
        @media (max-width: 576px) {
          margin-right: 14.889vw;
          width: 44.056vw;
        }
        input {
          color: #363638 !important;
          font-size: 16px !important;
          font-weight: 400;
          height: unset !important;
          line-height: 17px !important;
          padding: 11px 16px !important;
          @media (max-width: 576px) {
            font-size: 3.222vw !important;
            line-height: 3.389vw !important;
            padding: 1.764vw 2.111vw !important;
          }
        }

        &--email,
        &--password {
          width: calc(392px);
          @media (max-width: 576px) {
            width: calc(43.222vw);
          }
          input {
            border-radius: 8px !important;
            @media (max-width: 576px) {
              border-radius: 2.111vw !important;
            }
          }
        }
      }

      .select-box--box {
        border-radius: 8px;
        height: unset !important;
        width: 394px;
        @media (max-width: 576px) {
          border-radius: 2.111vw;
          width: 43.5vw;
        }
      }

      .select-box--container {
        height: unset !important;
      }

      .select-box--arrow {
        padding-top: 8px;
        @media (max-width: 576px) {
          padding-top: 1.444vw;
        }
      }

      .select-box--selected-item {
        color: #363638 !important;
        font-size: 16px !important;
        font-weight: 400;
        height: unset !important;
        line-height: 17px !important;
        padding: 11px 16px !important;
        @media (max-width: 576px) {
          font-size: 3.222vw !important;
          line-height: 3.389vw !important;
          padding: 1.764vw 1.111vw !important;
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;

    .button {
      min-width: 253px;
      @media (max-width: 576px) {
        min-width: 16.694vw;
      }
    }
  }

  &__title {
    color: #0267aa;
    font-size: 26px;
    font-weight: 900;
    line-height: 29px;
    margin-bottom: 30px;
    @media (max-width: 576px) {
      font-size: 4.611vw;
      line-height: 5.056vw;
      margin-bottom: 3.778vw;
    }
  }

  &__sub-title {
    color: #0267aa;
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    @media (max-width: 576px) {
      font-size: 4.056vw;
      line-height: 4.444vw;
    }
  }
}
