.season {

    align-items: center;
    border-bottom: 1px solid #F0F0F0;
    display: flex;
    padding: 30px 6px;

    &:last-child {

        border-bottom: none;

    }

    &__image {

        margin-right: 30px;

        img {

            height: 42px;
            object-fit: contain;
            object-position: center center;
            width: 42px;

        }

    }

    &__details {

        display: flex;
        flex-direction: column;

    }

    &__actions {

        margin-left: auto;
        width: 60px;

    }

    &__name {

        color: var( --color-primary );
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        margin-bottom: 10px;

    }

    &__info {

        color: #59595B;
        font-size: 12px;
        line-height: 14px;

    }

}