.questionnaire-table-container {
    margin-top: 30px;
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: left;
    background-color: white;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.08);

    @media (max-width: 576px) {

        overflow-x: scroll;
        width: calc( 100vw - 30px );

    }


}

