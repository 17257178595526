.achievement-group {

    align-items: center;
    cursor: pointer;
    display: flex;

    &__image {

        margin-right: 30px;

        img {

            height: 112px;
            object-fit: contain;
            object-position: center center;
            width: 112px;

        }

    }

    &__name {

        color: var( --color-primary );
        font-size: 16px;
        font-weight: 900;
        line-height: 19px;
        margin-bottom: 8px;

    }

    &__description {

        font-size: 16px;
        font-weight: 400;
        line-height: 19px;

    }

}