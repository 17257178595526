.season-modal {

    &__title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 30px;
        margin-bottom: 25px;

    }

    .form-field {

        align-items: center;
        display: flex;
        margin-bottom: 20px;

    }

    label {

        color: var( --color-primary );
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        margin-right: 60px;
        width: 115px;

    }

    .field {

        margin-top: 0;

        input {

            color: #363638 !important;
            font-size: 16px !important;
            font-weight: 400 !important;
            line-height: 19px !important;
            padding: 11px 16px !important;
            width: 429px !important;

        }

    }

    .form-fields {

        display: flex;
        justify-content: space-between;

        > div {

            width: 269px;

        }

        label {

            display: flex;
            margin-bottom: 9px;
            width: 100%;

        }

    }

    .react-datepicker-wrapper {

        .react-datepicker__input-container {

            height: 48px;

            input {
                cursor: pointer;
                border-radius: 8px;
                border: 1px solid var(--color-border);
                height:calc(48px - 14px - 14px) !important;
                min-width: 180px;
                padding: 14px 16px ;
                text-align: left;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: var(--color-font);
                font-family: var(--font-primary);
                width: calc( 100% - 32px );
            }

        }
    }

    .info {

        color: #363638;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-top: 20px;
        width: 573px;

    }

    .label-text {

        color: var( --color-primary );
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        text-align: center;

    }

    .icon-white {

        color: white;

    }

    .image {

        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;

        > span {

            display: flex;
            margin-left: 20px;

        }

    }

    &__footer {

        display: flex;
        justify-content: flex-end;

    }

}