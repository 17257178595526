.quotes-modal {
  max-height: 80vh;
  max-width: 80vw;
  position: relative;
  width: calc(1217px - 25px - 25px);

  header {
    align-items: center;
    display: flex;
    margin-bottom: 25px;
  }

  footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
  }

  &__title {
    color: var(--color-primary);
    font-size: 26px;
    font-weight: 900;
    line-height: 29px;
    white-space: nowrap;
    @media (max-width: 576px) {
      white-space: pre-wrap;
      margin-bottom: 15px;
      margin-left: 15px;
    }
  }

  .field--search {
    margin-left: 30px;
    margin-right: 30px;
    @media (max-width: 576px) {
      margin-left: 15px;
    }
  }

  .close-modal {
    cursor: pointer;
    height: 15px;
    width: 15px;
  }

  .quote {
    align-items: center;
    border: 1px solid var(--color-border);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    padding: 10.5px;
    margin-bottom: 8.5px;

    &__id {
      color: var(--color-dark);
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      width: 5%;
    }

    &__quote {
      font-size: 16px;
      line-height: 19px;
      height: 19px;
      overflow: hidden;
      width: 60%;

      .politician {
        font-weight: 700;
      }
    }

    &__popular_reaction,
    &__views {
      text-align: center;
      width: 7.5%;
    }

    &__views {
      font-size: 14px;
      line-height: 16px;
    }

    &__status {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      width: 20%;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &--selected {
      border-color: var(--color-primary);
      border-width: 2px;
    }
  }
}
