.leaderboard-page {

    .caption {

        color: var( --color-primary );
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 20px;

    }

    .card-div {

        margin-bottom: 30px;
        padding: 0;

        .table {

            width: 100%;

        }

    }

}