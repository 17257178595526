.page-politician-questions {
    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        padding: 15px;

    }

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: 100%;

        }

        .toolbar {
            margin-top: 28px;
            margin-bottom: 30px !important;
            height: 36px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: left;

            @media (max-width: 576px) {

                flex-wrap: wrap;

                .button {

                    margin-left: 0 !important;
                    margin-top: 11px;
                    min-width: unset;
                    width: 50%;

                }

            }
        }

        .button {
            margin-left: 15px;
        }

        .politician-question-container{
            width: 100%;
            display:flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            
           .politician-question-list {
                list-style-type: none;
                width: 100%;
                margin: 0;
                padding: 0;

               @media (max-width: 576px) {

                   .drag_icon {

                       width: 24px !important;

                   }

               }

            }
        
        }
    }
    
}
