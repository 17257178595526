.questionnaire-user-answer-table-container {
    width: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;

    .questionnaire-user-answer-table {
        border-bottom: 1px solid var(--color-light-gray);
        padding: 0 0 0 0px;
        margin: 0;
        width: 100%;
    }
}

