.target-table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: self-start;

    .target-table {
        padding: 0 0 0 0px;
        margin: 0;
        width: 100%;
    }

    .button {
        margin-top: 23px;
    }
}
