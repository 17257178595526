@keyframes loading {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.icon--loading {
    display: inline-flex;
    font-size: 17px;
    height: 17px;
    line-height: 17px;

    div {
        animation-name: loading;
        animation-duration: 1200ms;
        animation-iteration-count: infinite;

        &:nth-child(2) {
            animation-delay: 400ms;
        }

        &:nth-child(3) {
            animation-delay: 800ms;
        }
    }
}
