.field--text {
    // margin-top: 15px;

    input {
        background-color: white !important;
        border: 1px solid var(--color-border) !important;
        border-radius: 8px !important;
        font-size: 18px !important;
        line-height: 20px !important;
        padding: 18px 16px !important;
        width: calc(100% - 18px - 18px) !important;
        height: calc(57px - 18px - 18px) !important;
    }

    .statement {
        color: var(--color-primary);
        font-size: 18px;
        font-weight: 900 ;
    }

    .title {
        color: var(--color-primary);
        font-size: 18px;
        font-weight: 700;
    }

    .position {
        color: var(--color-dark);
        font-size: 16px;
        font-weight: 700;
    }

    .question {
        color: var(--color-dark);
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        height: calc(48px - 18px - 18px) !important;
    }

    .year {
        color: var(--color-dark);
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
    }
    
}
