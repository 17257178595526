.page--league {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

    }

    .navigation-title {

        margin-bottom: 30px;

    }

    .league-top {

        margin-bottom: 30px;
        padding: 25px;
        width: calc( 100% - 50px );

    }

    .league-detail-card {

        display: flex;
        flex-direction: column;
        width: 100%;

        &__top {

            align-items: center;
            border-bottom: 1px solid #F5F7FA;
            display: flex;
            padding-bottom: 25px;

            img {

                height: 30px;
                margin-right: 25px;
                object-fit: contain;
                object-position: center center;
                width: 45px;

            }

            .league-name {

                color: var( --color-primary );
                font-size: 26px;
                font-weight: 900;
                line-height: 29px;
                margin-right: auto;

            }

        }

        &__bottom {

            display: flex;
            padding-top: 20px;

        }

    }

    .info-box {

        border-right: 1px solid #F5F7FA;
        padding-left: 50px;
        padding-right: 50px;

        &:first-child {

            padding-left: 0;

        }

        &:last-child {

            border-right: none;
            padding-right: 0;

        }

        &__caption {

            color: var( --color-primary );
            font-size: 22px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 8px;

        }

        &__value {

            color: #59595B;
            font-size: 14px;
            line-height: 16px;

        }

    }

    .page-selector {

        margin-bottom: 30px;
        padding: 30px 30px 12.5px 30px;
        width: calc( 100% - 60px );

        .pages {

            border-bottom: 1px solid var( --color-primary );
            display: flex;
            justify-content: center;
            width: 100%;

        }

        a {

            border-bottom: 1px solid transparent;
            color: #363638;
            display: flex;
            font-size: 16px;
            line-height: 17px;
            padding: 0 10px 10px 10px;
            text-decoration: none;

            &.active {

                border-bottom: 1px solid var( --color-primary );
                color: var( --color-primary );
                font-weight: 700;

            }

        }

    }

}