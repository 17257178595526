.news-modal {

    height: 100%;

    &__title {
        color: var(--color-primary);
        font-size: 26px;
        font-weight: 900;
        line-height: 30px;
        margin-bottom: 25px;
    }

    .news-modal-content {
        width: 390px ;
        padding-bottom: 16px;
        display:flex;
        flex-direction: column;
        justify-content: left;
        border: 1px solid transparent;
        border-radius: 8px;
        box-shadow: 0 0px 14px 0 rgba(0, 0, 0, 0.08);
    
        .news-image {
            width:100%;
            min-height: 200px;
            border: 1px solid transparent;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px; 
            box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);

        }

        .content {
            width: calc(100% - 15px - 15px);
            display:flex;
            flex-direction: column;
            justify-content: left;
            margin: 0 15px !important;

            .title {
                padding: 10px 0;
                line-height: 26px;
                font-size: 22px;

                .statement{
                    font-weight: 900;
                }
            }
    
            .lead {
                padding: 10px 0;
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
            }

            .description {
                padding: 10px 0;
                line-height: 19px;
                font-size: 16px;
            }

            .separator-text {
                padding: 20px 0;
                line-height: 16px;
                font-size: 14px;
                text-align: center;
            }

        }
    
      
        .title-text-loading {
            height: 60px;
            background-color: var(--color-border);
            margin: 8px 10px;
            border-radius: 6px;
            border: 1px solid var(--color-border);
        }
    
        .text-loading {
            height: 20px;
            background-color: var(--color-border);
            margin: 0 10px;
            width: 100px;
            border-radius: 6px;
            border: 1px solid var(--color-border);
        }
    
        .category-div {
            width: 100% ;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 14px;

            .date {
                padding: 0 10px;
                text-align: right;
                font-size: 14px;
                line-height: 16px;
                font-weight: 700;
                line-height: 13px;
                font-family: var(--font-primary);
                width: 100%;
            }
    
            .icon {
                padding: 0 0px;
                text-align: right;
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
            }
               
            .category {
                height: 18px;
                background-color: var(--color-primary);
                border: 1px solid transparent;
                border-radius: 18px;
                color: white;
                font-family: var(--font-primary);
                font-size: 12px;
                font-weight: 700;
                height: 20px;
                line-height: 18px;
                padding: 1px 18px;
                margin: 0 15px;
            }
        }
    
    }


    footer {
        margin-top: 25px;

        display: flex;
        justify-content: flex-end;
    }

}
