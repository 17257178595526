.page.page--forgot-password {

    margin-left: 0 !important;

}

.page--forgot-password {

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: var(--inner-height);

    .forgot-password-form {

        max-width: calc(100vw - 10px - 10px);
        text-align: center;
        width: 420px;

        &__logo {

            height: 47px;
            margin-bottom: 30px;

        }

        h1 {
            color: var(--color-primary);
            font-size: 26px;
            font-weight: 900;
            line-height: 29px;
            margin: 0 0 30px 0;
        }

        .description {

            color: var(--color-primary);
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
            margin-bottom: 51px;

        }

        .field {

            margin-bottom: 30px;

            input {
                background-color: rgba(2, 103, 170, 0.1);
                border: 1px solid transparent;
            }

        }

        button {

            min-width: 265px;

        }

    }

}
