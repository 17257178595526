.profile-modal {

    &__title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 30px;

    }

    &__form {

        > .field {

            align-items: center;
            display: flex;
            margin-bottom: 20px;

            label {

                color: var( --color-primary );
                font-size: 18px;
                font-weight: 700;
                line-height: 20px;
                margin-right: 30px;
                width: 200px;

            }

            input {

                color: #363638 !important;
                font-size: 16px !important;
                font-weight: 700 !important;
                line-height: 17px !important;
                margin-bottom: 0 !important;
                padding: 9px 16px !important;
                width: calc( 300px - 32px ) !important;

            }

        }

    }

    &__footer {

        display: flex;
        justify-content: flex-end;

    }

}