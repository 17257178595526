.page-statistics {
    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    padding: 30px;

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;
    }

    .tab-view {

        header {
            display: flex;
            margin-bottom: 27px;
            margin-top: 35px;
            padding-top: 32px;
            background-color: white;
            width: 100% ;
            padding-bottom: 16px;
            display:flex;
            flex-direction: row;
            justify-content: center;
            border: 1px solid transparent;
            border-radius: 8px;
            box-shadow: 0 0px 14px 0 rgba(0, 0, 0, 0.08);

            .tab {

                border-bottom: 2px solid transparent;
                color: var(--color-primary);
                font-size: 18px;
                font-weight: 700;
                line-height: 20px;
                margin-right: 32px;
                padding-bottom: 6px;

                &--active {

                    border-color: var(--color-primary);

                }

            }

        }

    }

    .card-div {
        border: none;
        flex-direction: column;
        padding: 20px;
        overflow: hidden;
        height: fit-content;
        margin-top: 18px;

        header {
            margin-bottom: 18px;
        }

        .caption {
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            margin-bottom: 15px;
        }

        .button {
            font-size: 12px;
            font-weight: 700;
            line-height: 14px;
            margin-top: 8px;
            width: fit-content;
            min-width: fit-content;
            background-color: transparent;
            color: var(--color-primary);
            cursor: pointer;
            font-family: var(--font-primary);
            padding: 8px 0px;
            transition: background-color var(--transition-speed);
        
            &:hover {
                background-color: var(--color-highlight);
            }
        }

        .description {
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            margin: 30px;
            text-align: center;
            color: var(--color-font);
            font-family: var(--font-primary);
           
        }
    }

    .card-div-bottom-padding{
        padding-bottom: 8px !important;
    }

    .container-top-margin {
        margin-top: 28px;
    }

    .container {
        margin-top: 12px;
    }

    .inner-container {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    h2 {
        color: var(--color-primary);
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        margin: 0;
    }

    .info-slot {
        display: flex;
        justify-content: space-between;

        &__info {
            margin-left: 0px ;
            margin-right: 0px;
        }
    
    }

    .card-div-right-margin {
        margin-right: 24px;
    }

    .dashboard-table {
        margin: 0;
        margin-top: 18px;            
        padding: 0;
    }

    .dashboard-divider {
        margin: 0;
        margin-top: 12px;
        width: 100% ;
        height: 1px ;          
        background-color: var(--color-border);    
    }

    .react-datepicker-wrapper {
       
        .react-datepicker__input-container {
            height: 48px;

            input {
                cursor: pointer;
                border-radius: 8px;
                border: 1px solid var(--color-border);
                height:calc(48px - 14px - 14px) !important;
                min-width: 180px;
                padding: 14px 16px ;
                text-align: left;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: var(--color-font);
                font-family: var(--font-primary);
            }

        }
    }


    // .select-background {
    //     height:calc(48px - 14px - 14px);
    //     border-radius: 8px;
    //     text-align: left !important;
    //     border: 1px solid var(--color-border);
    //     display:flex;
    //     flex-direction: row;
    //     justify-content: left;
    //     padding: 14px 16px ;
    //     margin-top: 15px;
    //     min-width: 200px;
        
    //     .select-text {
    //         padding-top:8px;
    //         margin-left: 16px;
    //         margin-right: 8px;
    //         width: 40%;
    //     }

    //     .select-arrow {
    //         margin-bottom: 18px !important;

    //         width: 5px !important;
    //         height: 10px !important;
    //     }
    // }
}

