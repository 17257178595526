.page.page--login {

    margin-left: 0 !important;

}

.page--login {

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: var(--inner-height);

    .login-form {

        max-width: calc(100vw - 10px - 10px);
        text-align: center;
        width: 359px;

        &__logo {
            height: 47px;
            margin-bottom: 30px;
        }

        h1 {
            color: var(--color-primary);
            font-size: 26px;
            font-weight: 900;
            line-height: 29px;
            margin: 0 0 30px 0;
        }

        .field {
            margin-bottom: 10px;

            input {
                background-color: rgba(2, 103, 170, 0.1);
                border: 1px solid transparent;
            }
        }

        &__links {
            margin-bottom: 53.5px;
            margin-top: -2px;
            text-align: right;

            &__link {
                color: var(--color-primary);
                font-size: 12px;
                font-weight: 700;
                line-height: 13px;
                text-decoration: none;
                transition: color var(--transition-speed);

                &:hover {
                    color: var(--color-highlight);
                }

                &--error {
                    color: var(--color-warning);
                    text-decoration: underline;
                }
            }
        }

    }

}
