.table {

    @media (max-width: 576px) {

        overflow-x: scroll;
        width: calc( 100vw - 30px );

    }

    &__header {

        background-color: #F4F4F4;
        display: flex;
        padding: 14px;
        min-height: calc(66px - 14px - 14px);

        @media (max-width: 576px) {

            width: calc( 1000px - 20px - 20px );

        }

        &__column {

            align-items: center;
            display: flex;
            font-size: 16px;
            font-weight: 700;
            line-height: 17px;
            position: relative;

            &--align-left {

                text-align: left;
                justify-content: flex-start;

            }

            &--align-center {

                text-align: center;
                justify-content: center;

            }

            &--align-right {

                text-align: right;
                justify-content: flex-end;

            }

            .order {

                left: calc(100% - 24px - 12px);
                pointer-events: none;
                position: absolute;

            }

        }

    }

    &__body {

        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;

        @media (max-width: 576px) {

            width: 1000px;

        }

        &__row {

            box-shadow: inset 0 -1px 0 0 #F5F7FA;
            display: flex;
            padding: 14px 14px;
            min-height: calc(66px - 14px - 14px);
            align-items: center;

        }

        &__column {

            align-items: center;
            display: flex;
            font-size: 14px;
            line-height: 16px;

            &--align-left {

                text-align: left;
                justify-content: flex-start;

            }

            &--align-center {

                text-align: center;
                justify-content: center;

            }

            &--align-right {

                text-align: right;
                justify-content: flex-end;

            }

        }

        &__empty {

            font-size: 14px;
            text-align: center;
            padding: 14px;

        }

    }

    &__pagination {

        align-items: center;
        background-color: #F4F4F4;
        color: var(--color-dark);
        display: flex;
        font-size: 16px;
        font-weight: 700;
        justify-content: flex-end;
        line-height: 19px;
        padding: 8px;

        button {

            align-items: center;
            background-color: white;
            border: 0;
            border-radius: 50%;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
            display: flex;
            height: 26px;
            justify-content: center;
            margin: 0 25px;
            min-width: 26px;
            width: 26px;

            &[disabled] {

                pointer-events: none;

                img {

                    opacity: 0.5;

                }

            }

            img {

                height: 8px;

            }

        }

    }

    &--simple-header {

        .table__header {

            background-color: var(--color-white);
            box-shadow: inset 0 -1px 0 0 #F5F7FA;

        }

    }

}
