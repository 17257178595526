.description-area--text {

    .description-editor-container {
        height: 24px;
        border-radius: 3px;
        text-align: center;
        border: 1px solid var(--color-primary);
        padding: 1px;
        width: calc( 24px * 6 + 5px );
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;

        .editor-separator {
            background-color: var(--color-primary);
            height: 24px;
            width: 1px;
            margin: 0;
            padding: 0;
        }

        .editor-button {
            height: 24px;
            width: 24px;
            background-color: white;
            border: 1px solid transparent;
            cursor: pointer;
            transition: background-color var(--transition-speed);
        
            &:hover {
                background-color: var(--color-highlight);
            }
        }

        .bold {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
        }

        .italic {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 400 ;
            line-height: 16px;
            font-style: italic;
        }

        .underline {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 400 ;
            line-height: 16px;
            text-decoration: underline;
        }

        .ordered-list, .unordered-list {

            padding: 3px;

            img {

                height: 16px;
                object-fit: contain;
                object-position: center center;
                width: 16px;

            }

        }

        .link {
            color: var(--color-primary);
            font-size: 14px;
            font-weight: 200;
            line-height: 18px;
            margin: 0;
            padding: 0;
        }
    }
    
    .area--description {
        margin-top: 15px;
        background-color: white !important;
        border: 1px solid var(--color-border) !important;
        border-radius: 8px !important;
        padding: 18px 16px !important;
        width: calc(100% - 16px - 16px) !important;
        resize: none;
        overflow: hidden;
        color: var(--color-font);
        font-family: var(--font-primary) !important ;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 19px !important;
        min-height: calc(180px - 16px - 16px) !important;
    }
}

