.league-modal {

    &__title {

        color: var( --color-primary );
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 25px;

    }

    .row-field {

        align-items: center;
        display: flex;
        margin-bottom: 15px;
        width: calc( 138px + 37px + 398px );

        &--disabled {

            label {

                opacity: 0.4;

            }

        }

        &--options {

            .option {

                width: unset !important;

                label {

                    margin-right: unset !important;
                    width: unset !important;

                }

            }

        }

        label {

            color: var( --color-primary );
            display: flex;
            font-size: 18px;
            line-height: 20px;
            font-weight: 700;

            margin-right: 37px;
            width: 138px;

        }

        .field {

            width: calc( 100% - 138px - 37px );

        }

        input, textarea {

            color: #363638 !important;
            font-size: 16px !important;
            font-weight: 700 !important;
            line-height: 17px !important;
            padding: 14px 20px !important;
            width: calc( 100% - 40px ) !important;

        }

        textarea {

            color: var( --color-primary ) !important;
            font-weight: 700 !important;
            height: calc( 3 * 17px ) !important;

        }

        .description-editor-container {

            margin-left: auto;

        }

        .area--description {

            min-height: calc( 3 * 19px ) !important;

        }

        .option {

            align-items: center;
            display: flex;
            margin-right: 32px;

            input {

                margin-right: 8px;
                width: unset !important;

            }

        }

    }

    footer {

        display: flex;
        justify-content: flex-end;
        margin-top: 60px;

    }

}