.page-create-politician {
   


    .toolbar {

        display: flex;
        margin-bottom: 0 !important;
        margin-top: 28px;

        @media (max-width: 576px) {

            flex-wrap: wrap;
            margin-bottom: 68px !important;

        }

        button {

            margin-left: 15px;

            &:first-child {

                margin-left: 0;
                margin-right: auto;

            }

            @media (max-width: 576px) {

                margin-left: 11px;

            }

        }

        .back-button {

            @media (max-width: 576px) {

                margin-left: 0;
                min-width: unset;
                width: calc( 50% - 5.5px );

            }

        }

        .save-button {

            @media (max-width: 576px) {

                margin-left: 0;
                margin-top: 11px;
                min-width: unset;
                width: calc( 50% - 5.5px );

            }

        }

    }

    .bottom-toolbar {
        display: flex;
        justify-content: right !important;
        margin-top: 30px;
    }
    
    .card-div-right-margin {
        margin-right: 24px;
    }

    .card-div-bottom-padding {
        padding-bottom: 24px;
    }

    .title-bottom-margin {
        margin-bottom: 15px;
    }

    .single-button-margin {
        margin: 20px;
    }

    .card-sub-div-right-margin {
        margin-right: 30px;
    }

    .container-top-margin {
        margin-top: 20px;
    }

    .sub-title-text-center {
        text-align: center;
        width: 100%;
    }

    .title-container {
        display:flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
    }

    .main-container {

        @media (max-width: 576px) {

            flex-direction: column;

        }

    }

    .select-background {
        height:calc(48px - 14px - 14px);
        border-radius: 8px;
        text-align: left !important;
        border: 1px solid var(--color-border);
        display:flex;
        flex-direction: row;
        justify-content: left;
        padding: 14px 16px ;
        margin-top: 15px;
        min-width: 200px;
        
        .select-text {
            padding-top:8px;
            margin-left: 16px;
            margin-right: 8px;
            width: 40%;
        }

        .select-arrow {
            margin-bottom: 18px !important;
            width: 5px !important;
            height: 10px !important;
        }
    }

    .category-select-background {
        min-width: 280px;
    }

    .check-box-group {
        min-width: 200px;
    }       

    .image-section-container{
       // width: 226px;
        width: 184px;
        margin-bottom: 18px;
        margin-right: 20px;
    }

    .text-section-container{
        width: 100% !important;
    }

    .info-icon {
        margin-left: 24px;
        font-size: 18px;
        font-weight: 400;
    }

    .label-text-primary {
        color: var(--color-primary) ; 
        font-size: 12px;
        font-weight: 700;
        line-height: 13px;
        text-align: center;
    }

    .info {
        color: var(--color-text) !important; 
        font-weight: 400 !important;
        text-align: right !important;
        margin-top: 18px;
        margin-right: 4px;
    }
  
    .input-container-header {
        text-align: bottom !important;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .header-text-center {
        text-align: center ;
        display:flex;
        align-items: center;
    }

    .icon-white {
        color: white !important;        
    }

    .title-section-container{
        width: 100% !important;
        display:flex;
        flex-direction: row;
     
        .dk-icon {
            height: 20px;        
            margin-right: 10px;        
        }

        .pos-icon {
            height: 12px;    
            margin-top: 4px;    
            margin-right: 10px;        
        }
    }
    
    .title-text-bottom-padding {
        padding-bottom: 15px;
    }

    .description-edititor-container {
        height: 24px;
        border-radius: 3px;
        text-align: center;
        border: 1px solid var(--color-primary);
        padding: 1px;
        width: 100px;
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;

        .separator {
            background-color: var(--color-primary);
            height: 24px;
            width: 1px;
            margin: 0;
            padding: 0;
        }

        .editor-button {
            height: 24px;
            width: 24px;
            background-color: white;
            border: 1px solid transparent;
            cursor: pointer;
            transition: background-color var(--transition-speed);
        
            &:hover {
                background-color: var(--color-highlight);
            }
        }

        .bold {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
        }

        .italic {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 400 ;
            line-height: 16px;
            font-style: italic;
        }

        .underline {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 400 ;
            line-height: 16px;
            text-decoration: underline;
        }

        .link {
            color: var(--color-primary);
            font-size: 14px;
            font-weight: 200;
            line-height: 18px;
            margin: 0;
            padding: 0;
        }
    }

    .politician-section-container{
        margin-right: 36px;
        display:flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        margin-top: 15px;
        
     
        .politician-container{
            width: calc(236px - 14px - 14px);
            height: calc(304px - 22px - 22px);
            border-radius: 10px;
            text-align: center;
            border: 1px solid var(--color-primary);
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 14px 22px;
            margin-right: 20px;
            margin-bottom: 24px;

            cursor: pointer;
            transition: background-color var(--transition-speed);

            &:hover {
                background-color: var(--color-highlight);
            }

            .image-container-image{
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: var(--color-primary); 
                display:flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 10px;
            }
        }
    }
    
    .correct-answer-button {
        background-color: #27b06c;
        border: 1px solid transparent;
        border-radius: 21.5px;
        color: var(--color-white);
        cursor: pointer;
        font-family: var(--font-primary);
        font-size: 16px;
        font-weight: 700;
        line-height: 17px;
        min-width: 186px;
        padding: 8px 24px;
        margin-left: 40px;
        transition: background-color var(--transition-speed);

        &--ghost {
            background-color: white;
            border: 1px solid var(--color-border);
            color: var(--color-border);
            transition: border-color var(--transition-speed), color var(--transition-speed);

            &:hover {
                background-color: #27b06c;
                color: white;
            }
        }
    }

    .politician-question-answer-container{
        width: 100%;
        display:flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        
       .politician-question-answer-list {
            list-style-type: none;
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }

    .right-gravity-div {
        display:flex;
        flex-direction: row;
        justify-content: right ;
        margin-bottom: 0 ;
    }

    .add-title-container{
        display:flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        
        .title-list {
            list-style-type: none;
            margin: 0;
            padding: 0;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;

        }

        .add-title-button{
            height: 30px;
            border: 1px solid var(--color-primary);
            border-radius: 21px;
            background-color: white;
            border: 1px solid var(--color-primary);
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 12px;
            font-weight: 700;
            line-height: 14px;
            padding: 0 !important;
            margin-bottom: 10px; 
            margin-left: 0; 

        }
    }

    .tab-view {

        header {
            display: flex;
            margin-bottom: 27px;
            margin-top: 35px;
            padding-top: 32px;
            background-color: white;
            width: 100% ;
            padding-bottom: 16px;
            display:flex;
            flex-direction: row;
            justify-content: center;
            border: 1px solid transparent;
            border-radius: 8px;
            box-shadow: 0 0px 14px 0 rgba(0, 0, 0, 0.08);

            .tab {

                border-bottom: 2px solid transparent;
                color: var(--color-primary);
                font-size: 18px;
                font-weight: 700;
                line-height: 20px;
                margin-right: 32px;
                padding-bottom: 6px;

                &--active {

                    border-color: var(--color-primary);

                }

            }

        }

    }

    footer {

        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 31px;
        margin-top: 28px;

        @media (max-width: 576px) {

            flex-wrap: wrap;
            margin-bottom: 68px !important;

        }

        button {

            margin-left: 15px;

            @media (max-width: 576px) {

                margin-left: 11px;

            }

        }

        .save-button {

            @media (max-width: 576px) {

                min-width: unset;
                width: calc( 50% - 5.5px );

            }

        }

    }

    .xp-editor {

        display: flex;

        img {

            height: 87px;
            margin-right: 50px;
            object-fit: contain;
            object-position: center center;
            width: 87px;

        }

        .field {

            margin-right: 50px;
            width: 464px;

            label {
                color: var(--color-primary);
                display: flex;
                font-size: 18px;
                min-height: 20px;
                line-height: 20px;
                font-weight: 700;
                margin-bottom: 13px;
            }

            input {

                color: #363638 !important;
                font-size: 16px !important;
                font-weight: 700 !important;
                line-height: 17px !important;
                padding: 14px 20px !important;
                width: calc(100% - 40px) !important;

            }

        }

    }

    .columns {

        display: flex;

    }

    .card-div--options {

        flex-direction: column;

        label {

            margin-right: 20px;

        }

    }

    .check-box-group {

        padding-top: 0;

    }
      
}
