
.user-text-answer-table {

    margin: 0px;            
    padding: 0px;            
    margin-right: 20px;

    &__user-text-answer-element {
        list-style: none;
        width: 100% ;
        margin-top: 10px;            
        display:flex;
        flex-direction: row;
        justify-content: left;
        align-items: flex-start;
        background: var(--color-border);
        border: 1px solid transparent;
        border-radius: 4px;
        padding-left: 20px;

        .text-section-container {
            display:flex;
            width: 100% ;
            flex-direction: column ;
            align-items: flex-start !important;
        }

    }

}