.target-table-container {
    .target-table {
        &__target-element {
            list-style: none;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            .inner-container {
                list-style: none;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: stretch;
                border: 1px solid #dddddd;
                border-radius: 8px;
                padding: -1px;
                margin-bottom: 10px;

                .delete {
                    width: 16px;
                    height: 20px;
                    text-align: right;
                    cursor: pointer;
                    padding: 14px;
                }

                .divider {
                    width: 3px;
                    background-color: #dddddd;
                }

                .select-box--box-full {
                    margin-top: 0;
                }

                .select-box--box {
                    border: 0px solid transparent;
                    border-radius: 0;
                }

                .select-box--box:hover {
                    box-shadow: 0 0 0px var(--color-border);
                }
            }

            .and-label {
                list-style: none;
                width: 59px;
                height: 24px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                border: 1px solid transparent;
                border-radius: 4px;
                background-color: var(--color-primary);
                margin-left: 14px;

                color: white;
                font-family: var(--font-primary);
                font-size: 14px;
                font-weight: 700;
            }
        }
    }
}
