.push-message-settings-modal {
    max-width: 1156px;
    min-width: 600px;

    height: auto;

    @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        width: 100%;
    }

    &__header {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        color: var(--color-primary);
        font-size: 26px;
        font-weight: 900;
        line-height: 30px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-bottom: 15px;
    }

    &__item {
        list-style: none;
        width: 100%;
        display: flex;
        align-items: center;
        border: 1px solid #dddddd;
        border-radius: 8px;
        margin-bottom: 10px;
    }

    &__item-checkbox-container {
        width: 7%;

        display: flex;
        justify-content: center;
    }

    &__item-text-container {
        width: 93%;
        margin-right: 26px;
    }

    &__item-title {
        color: var(--color-primary);
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        margin: 16px 0 8px 0;
        padding: 0;
    }

    &__item-desc {
        color: var(--color-text);
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        margin: 0 0 16px 0;
        padding: 0;
    }

    .divider-container {
        width: 100%;
        display: flex;
        margin-bottom: 12px;

        .divider-line {
            flex: 1;
            border-bottom: 1px dashed black;
            height: 0.6em;
        }

        .divider-text {
            background-color: #fff;
            padding: 0 3px;
            color: var(--color-text);
            padding: 0 10px;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-align: center;
        }
    }

    .close-button {
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 13px;
        color: var(--color-border);
        font-family: var(--font-primary);
        font-size: 18px;
        font-weight: 300;
        line-height: 18px;
        cursor: pointer;
        width: 26px;
        height: 26px;
        transition: background-color var(--transition-speed);

        &:hover {
            background-color: var(--color-highlight);
        }
    }

    .button-left-margin {
        margin-left: 15px !important;
    }

    &__footer {
        display: flex;
        justify-content: right;
        align-items: right;
    }
}
