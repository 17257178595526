.button--menu {
    padding: 0;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.08);
    background-color: white;
    border: 1px solid transparent;
    cursor: pointer;
    transition: background-color var(--transition-speed);
    margin-bottom: 4px;

    &:hover {
        background-color: var(--color-highlight);
    }

    &[disabled] {
        background-color: var(--color-gray);
        cursor: not-allowed;
    }

    .points {
        width: 24px !important;
        height: 24px !important;
    }
    @media (max-width: 576px) {
        width: 22px;
        height: 22px;
    }
}

.button--menu-hidden {
    padding: 0;

    width: 26px;
    height: 26px;
    border-radius: 13px;
    box-shadow: 0 transparent;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    transition: background-color var(--transition-speed);

    &:hover {
        background-color: var(--color-highlight);
    }

    &[disabled] {
        background-color: var(--color-gray);
        cursor: not-allowed;
    }

    .points {
        width: 24px !important;
        height: 24px !important;
    }
}

.button--menu--container {
    height: 26px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button--menu--items div {
    border: 1px solid transparent;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.08);
    background-color: white;
    height: 33px;
    width: calc(100% - 12px - 12px);
    z-index: 9;
    position: relative;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    text-align: left;

    .icon {
        max-width: 16px;
        font-size: 16px;
        margin-right: 10px !important;
    }

    &.disabled {
        color: #c0c0c0;
        pointer-events: none;
    }

    .text {
        font-size: 14px;
        font-weight: 700;
        font-family: var(--font-primary);
        color: var(--color-font);
        padding-left: 10px !important;
        width: calc(100% - 10px);
    }
}
