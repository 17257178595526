.color-picker {

    width: 100%;

    svg {

        width: 67%;

        polygon {

            cursor: pointer;

        }

    }

}