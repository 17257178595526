.user-activity {

    display: flex;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
    padding-left: 28px;

    &__description {

        margin-right: auto;
        position: relative;

        .action-name {

            font-weight: 700;

        }

        &::before {

            background-color: var(--color-font);
            border-radius: 50%;
            content: "";
            left: -19px;
            height: 6px;
            position: absolute;
            top: 5px;
            width: 6px;

        }

    }

    &__date {

        margin-left: 46px;
        white-space: nowrap;

    }

    &:last-child {

        margin-bottom: 0;

    }

}
