.event-details-stats {

    margin-top: 30px;

    > .card-div {

        flex-direction: column;
        padding: 20px !important;
        width: calc( 100% - 40px ) !important;

    }

    span {

        margin-bottom: 20px;

    }

    .stats {

        display: grid;
        gap: 20px;
        grid-template-columns: repeat( 4, 1fr );

        .stat {

            display: flex;
            flex-direction: column;

            &__name {

                font-size: 14px;
                margin-bottom: 20px;

            }

            &__value {

                font-size: 18px;
                font-weight: 700;
                text-align: center;

            }

        }

    }

}