.tag-group__tag {

    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    display: flex;
    height: unset;
    margin-bottom: 10px;
    padding: 7.5px 15px;

    &__name {

        color: #59595B;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        margin-right: auto;

    }

    &__color {

        border-radius: 13px;
        height: 26px;
        width: 105px;

    }

    &__edit {

        cursor: pointer;
        height: 14px;
        margin-left: 20px;
        object-fit: contain;
        object-position: center center;
        width: 14px;

    }

    &:last-child {

        margin-bottom: 0;

    }

    &--selected {

        border-color: var( --color-primary );

    }

}