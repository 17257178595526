.page--admin-users {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .activities {

        .tabs {

            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;

            a {

                border-bottom: 2px solid transparent;
                cursor: pointer;
                font-size: 18px;
                line-height: 20px;
                margin-right: 20px;
                margin-bottom: 9px;
                padding-bottom: 6px;

                &.selected {

                    color: var( --color-primary );
                    border-color: var( --color-primary );
                    font-weight: 700;

                }

            }

        }

    }

    .card-div.tabs-select {

        padding: 30px 20px 10px 20px;
        width: calc( 100% - 40px );

        .tabs {

            align-items: center;
            border-bottom: 1px solid var( --color-primary );
            display: flex;
            justify-content: center;
            width: 100%;

            .tab {

                border-bottom: 3px solid transparent;
                color: #363638;
                cursor: pointer;
                font-size: 16px;
                line-height: 17px;
                margin-bottom: -2px;
                padding: 0 8px 12px 8px;

                &--selected {

                    border-color: var( --color-primary );
                    color: var( --color-primary );
                    font-weight: 700;

                }

            }

        }

    }

    .search {

        align-items: center;
        display: flex;

        .field--search {

            margin-right: 10px;

        }

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

    }

    .toolbar {

        align-items: center;
        display: flex;

        .search {

            width: calc(100% - 30px - 263px);

        }

        >.button {

            margin-left: 30px;
            width: 263px;

        }

    }

    .card-div {

        border: none;
        flex-direction: column;
        padding: 20px;
        margin-bottom: 30px;
        // overflow: hidden;

        header {

            margin-bottom: 18px;

        }

        .caption {

            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            margin-bottom: 15px;

        }

    }

    h2 {

        color: var(--color-primary);
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        margin: 0;

    }

    .info-slots {

        display: flex;
        justify-content: space-between;

        .info-slot {

            width: calc(50% - 20px - 20px - 8px);

        }

    }

    .table {

        margin: -20px;

        .table__body__column {

            height: 16px;

        }

        label {

            margin: -3px 0 0 0;

        }

        &__header {

            border-radius: 8px 8px 0 0;

        }

        &__pagination {

            border-radius: 0 0 8px 8px;

        }

    }

    .button--menu--items {

        .disabled {

            img,
            span {

                opacity: 0.25;

            }

        }

    }

    .admin-activities {

        max-height: 500px;
        padding-right: 20px;
        overflow-y: auto;

        &__empty {
            font-size: 14px;
            padding: 20px;
            text-align: center;
        }

    }

}
