.user-modal {

    &__title {

        color: var(--color-primary);
        font-size: 26px;
        font-weight: 900;
        line-height: 30px;
        margin-bottom: 25px;

    }

    .form-field {

        display: flex;
        margin-bottom: 23px;

        &__caption {

            color: var(--color-primary);
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
            margin-right: 23px;
            padding-top: 8px;
            width: 152px;

        }

        &__field {

            margin-right: 65px;
            width: 398px;

            .field,
            .select-box--box {

                margin: 0 0 6px 0;

                &:last-child {

                    margin: 0;

                }

            }

            .select-box--box {

                border-radius: 8px;
                height: unset;

                .select-box--container {

                    height: 38px;

                }

                .select-box--selected-item {

                    font-size: 16px;
                    padding: 11px 16px;

                }

                .select-box--arrow {

                    display: flex;
                    height: 24px;
                    padding: 0;
                    right: 7px;
                    top: calc(50% - 12px);
                    width: 24px;

                }

                &:hover {

                    box-shadow: none;

                }

            }

        }

        &--disabled {

            .form-field__caption {

                color: var(--color-gray);

            }

        }

    }

    footer {

        display: flex;
        justify-content: flex-end;

    }

}
