.lesson-quiz-question {

    position: relative;

    &::before {

        background-color: var( --color-primary );
        border-radius: 5px 0 0 5px;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 10px;

    }

    .form {

        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 100%;

        .shared-field {

            display: flex;
            margin-top: 15px;
            margin-bottom: 20px;

            .field {

                width: calc( 100% - 300px - 20px );

            }

            input {

                margin-bottom: 0;
                padding-top: 14px !important;
                padding-bottom: 13px !important;

            }

            .select-box--box {

                margin-top: 0;
                margin-left: 20px;
                width: 300px;

            }

        }

        .question-header {

            align-items: center;
            display: flex;
            margin-bottom: 10px;

        }

        .button--is-solution {

            border-color: #DDDDDD;
            color: #DDDDDD;
            font-size: 12px !important;
            line-height: 14px !important;
            padding: 6px 12px !important;
            min-width: 150px !important;
            margin-left: 45px;

            &--selected {

                background-color: rgba( 39, 176, 108, 0.2 );
                border-color: #27B06C;
                color: #27B06C;

            }

        }

        .quiz-question {

            margin-bottom: 20px;

            input {

                margin-bottom: 10px;

            }

        }

        .other-fields {

            border-top: 1px dashed #999999;
            padding-top: 20px;

            border-bottom: 1px dashed #999999;
            margin-bottom: 20px;

            .caption {

                margin-bottom: 20px;

            }

            input {

                margin-bottom: 20px;

            }

        }

        .question-footer {

            display: flex;
            justify-content: flex-end;

            .button {

                &--warning {

                    background-color: rgba( 195, 8, 112, 0.2 );
                    border-color: #C30870;
                    color: #C30870;

                }

            }

        }

        .answers--image {

            display: flex;
            flex-wrap: wrap;

            .quiz-question {

                display: flex;
                width: 50%;

                .answer-info {

                    align-items: flex-start;
                    display: flex;
                    flex-direction: column;
                    margin-left: 20px;
                    width: calc( 100% - 20px - 20px - 250px );

                    .tag-selector {

                        margin-top: auto;

                        header {

                            top: unset;
                            bottom: 0;
                            left: 0;
                            margin-bottom: 0 !important;

                        }

                        main {

                            align-items: flex-start !important;
                            height: 98px;

                        }

                    }

                }

            }

            .button--is-solution {

                margin-left: 0;

            }

        }

    }

    .button--expand {

        align-items: center;
        background-color: transparent;
        border-color: var( --color-border );
        display: flex;
        height: 35px;
        justify-content: center;
        margin-left: 20px;
        min-width: 35px !important;
        padding: 0 !important;

        img {

            height: 7px;
            object-fit: contain;
            object-position: center center;
            rotate: 180deg;
            width: 7px;

        }

    }

    &__header {

        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

    }

    &--open {

        .button--expand {

            img {

                rotate: 270deg;

            }

        }

    }

}