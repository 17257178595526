.page-politicians {
    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        padding: 15px;

    }

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: 100%;

        }

        .toolbar {

            margin-bottom: 30px !important;
            height: 36px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: left;

            @media (max-width: 576px) {

                flex-wrap: wrap;
                height: unset;
                margin-bottom: 15px !important;

                .field--search {

                    width: calc( 100% - 22px - 16px - 16px - 2px - 11px );

                }

                .titles-button {

                    margin-left: 0;
                    margin-top: 11px;
                    min-width: unset;
                    width: calc( 50% - 5.5px );

                }

                .questions-a {

                    width: calc( 50% - 5.5px );

                }

                .questions-button {

                    margin-top: 11px;
                    min-width: unset;
                    width: 100%;

                }

                .new-a {

                    width: calc( 50% - 5.5px );

                }

                .new-button {

                    margin-left: 0;
                    margin-top: 11px;
                    min-width: unset;
                    width: 100%;

                }

            }

        }

        .button {

            margin-left: 15px;

            @media (max-width: 576px) {

                height: 38px;
                margin-left: 11px;

            }

        }

        .main-container {

            @media (max-width: 576px) {

                margin-top: 0;

            }

        }
    
        .title-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            @media (max-width: 576px) {

                align-items: flex-start;
                flex-direction: column;

                h2 {

                    margin-bottom: 15px;

                }

            }

            .title-text-width {
                width: 100%;
            }

            .title-right-margin {
                margin-right: 18px;
            }

            .select-box--box {
                width: 366px !important;

                @media (max-width: 576px) {

                    width: 100% !important;

                }
            }

            .select-box--container {
                width: 366px !important;

                @media (max-width: 576px) {

                    width: 100% !important;

                }
            }
            
        }

        .politician-section-container{

            display:flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            margin-top: 18px;
            
           .politician-list {
                list-style-type: none;
                margin: 0;
                padding: 0;
                margin-right: -17px;
                // overflow-x: hidden;
                // overflow-y: scroll;

               @media (max-width: 576px) {

                   text-align: center;

               }

            }
        
        }
    }
    
}
