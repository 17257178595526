.calendar {
    background-color: white;

    @media (max-width: 576px) {

        overflow: scroll;
        width: calc( 100vw - 30px );

    }

    header {

        display: flex;
        justify-content: center;
        padding: 16px 0 12px 0;

        @media (max-width: 576px) {

            width: 1252px;

        }

        h2 {

            color: var(--color-primary);
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;
            margin: 1px 115.5px 0 115.5px;

            @media (max-width: 576px) {

                margin-left: auto;
                margin-right: auto;

            }

        }

        button {

            align-items: center;
            background-color: white;
            border: 0;
            border-radius: 50%;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
            cursor: pointer;
            display: flex;
            height: 26px;
            justify-content: center;
            margin: 0 25px;
            min-width: 26px;
            width: 26px;

            &[disabled] {

                pointer-events: none;

                img {

                    opacity: 0.5;

                }

            }

            img {

                height: 8px;

            }

        }

    }

    .column {

        background-color: #FFFFFF;
        width: calc(100% / 7);

    }

    &__header {

        display: flex;

        color: #59595B;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        text-align: center;

        @media (max-width: 576px) {

            width: 1252px;

        }

        .column {

            padding: 51px 0 19px 0;

        }

    }

    &__body {

        @media (max-width: 576px) {

            width: 1252px;

        }

        .row {

            display: flex;
            position: relative;

            span {

                background-color: var(--color-primary);
                color: white;
                cursor: pointer;
                font-size: 12px;
                font-weight: 700;
                line-height: 14px;
                margin: 0 6px;
                overflow: hidden;
                padding: 8px 12px;
                position: absolute;

                &.starting {

                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;

                }

                &.ending {

                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;

                }

            }

            .column {

                border-color: #EFEFEF #EFEFEF transparent transparent;
                border-width: 1px;
                border-style: solid;
                min-height: 165px;
                position: relative;

                .day {

                    align-items: center;
                    border-radius: 50%;
                    color: #363638;
                    display: flex;
                    font-size: 14px;
                    height: 30px;
                    justify-content: center;
                    line-height: 16px;
                    position: absolute;
                    right: 16px;
                    text-align: center;
                    top: 16px;
                    width: 30px;

                }

                &--today {

                    .day {

                        background-color: var(--color-primary);
                        color: white;
                        font-weight: 700;

                    }

                }

                &--not-this-month {

                    background-color: #F8F7F8;
                    opacity: 0.66;

                }

                &:last-child {

                    border-right-color: transparent;

                }

            }

        }

    }

}
