.questionnaire-question-answer-table-container {
    width: 100% !important;
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    .questionnaire-question-answer-table {
        padding: 0 0 0 0px;
        margin: 0;
        width: 100%;
    }

}

