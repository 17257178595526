.header {
    align-items: center;
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-light-gray);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    left: 0;
    padding: 11px 24px 10px 24px;
    position: fixed;
    top: 0;
    width: calc(100% - 24px - 24px);
    z-index: 10000;

    &__logo {
        height: 58px;
        margin-right: auto;
    }

    .notifications {
        position: relative;

        @media (max-width: 576px) {

            display: none;

        }

        .badge {
            align-items: center;
            background-color: var(--color-pink);
            border-radius: 50%;
            color: var(--color-white);
            display: flex;
            font-size: 14px;
            font-weight: 700;
            line-height: 14px;
            height: 22px;
            justify-content: center;
            text-align: center;
            top: -11px;
            position: absolute;
            right: -11px;
            width: 22px;
        }
    }

    .separator {
        background-color: var(--color-light-gray);
        height: 17px;
        margin-left: 21.5px;
        margin-right: 21.5px;
        width: 1px;

        @media (max-width: 576px) {

            display: none;

        }
    }
  
    .user-dropdown {
        align-items: center;
        display: flex;     
        cursor: pointer;

        @media (max-width: 576px) {

            display: none;

        }

        &__name {
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            margin-right: 9.75px;
        } 
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }
      
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: var(--color-white);
        box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.08);
        z-index: 1;

        .dropdown-content-item{
            height: 32px;
            display: flex;
            align-items: center;

            &__icon {
                height: 32px;
                width: 32px;
            }

            &__icon--small {
                height: 12px;
                width: 12px;
                padding: 10px;
            }

            &__title {
                margin-right: 16px;             
                font-size: 12px;
                font-weight: 700;
                color: var(--color-dark);
                font-family: var(--font-primary);
                text-decoration: 'none';
            }

            &:hover {
                background-color: var(--color-highlight);
            }
        }

    }
      
    
    .mobile-menu {

        display: none;
        flex-direction: column;
        height: 16px;
        justify-content: space-between;

        div {

            background-color: #979797;
            height: 2px;
            width: 20px;

        }

        @media (max-width: 576px) {

            display: flex;

        }

    }

}
