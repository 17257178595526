.politicians-modal {
  width: 1217px;
  // height: 782px;

  &__header {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: var(--color-primary);
    font-size: 26px;
    font-weight: 900;
    line-height: 30px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .close-button {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 13px;
    color: var(--color-border);
    font-family: var(--font-primary);
    font-size: 18px;
    font-weight: 300;
    line-height: 18px;
    cursor: pointer;
    width: 26px;
    height: 26px;
    transition: background-color var(--transition-speed);

    &:hover {
      background-color: var(--color-highlight);
    }
  }

  footer {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
  }

  .separator {
    background-color: var(--color-border);
    height: 660px;
    width: 1px;
    margin: 0px 30px;
    padding: 0;
  }

  .separator-horizontal {
    background-color: var(--color-border);
    width: 100%;
    height: 1px !important;
    margin-top: 13px;
    margin-bottom: 25px;

    padding: 0;
  }

  .detail_container {
    height: 100%;
    width: 1200px;

    .image {
      height: 291px;
      width: 236px;
      border: 1px solid transparent;
      border-radius: 10px;
    }
  }

  .detail-text-container {
    max-height: 442px;
    width: 410px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;

    .desc {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
    }
  }

  .title-margin {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .name-div {
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 10px 0px;
  }

  .politicians-table-container {
    background-color: white;
    width: calc(486px - 20px - 20px);
    height: calc(660px - 16px - 16px);
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: 0px 6px 6px 4px rgba(0, 0, 0, 0.08);
  }

  .politicians-table-list {
    padding: 0px 8px 0px 0px;
    margin: 0;
    margin-top: 20px;
    width: 440px;
    height: 100%;

    overflow-y: scroll;
    overflow-x: hidden;
  }

  .politician-phreses-list {
    padding: 0px 0px 0px 0px;
    margin: 0;
    margin-top: 20px;
    width: 656px;
  }

  .empty-text {
    width: 100%;
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .text-right-margin {
    margin-right: 16px;
  }

  .text-bottom-margin {
    margin-bottom: 16px;
  }

  .remove-margin {
    margin-top: 0px !important;
  }

  .lineBreak {
    display: inline-block;
  }

  @media (max-width: 576px) {
    width: 700px;
    height: 782px;
    .politicians-table-container {
      width: calc(243px - 20px - 20px);
      height: calc(400px - 16px - 16px);
    }
    .lineBreak {
      word-wrap: break-word;
      white-space: pre-wrap;
      margin-bottom: 5px;
    }
    .politicians-table-list {
      width: 200px;
    }
    .detail_container{
      width: 650px;
    }
    .detail-text-container {
      width: 200px;
    }
    .separator-horizontal{
      width: 50%;
    }
    .politician-phreses-list {
      width: 325px;
    }
  }

    > .container {

        max-height: calc(100vh - 160px - 25px - 25px - 10px - 10px - 63px - 35px - 30px);
        overflow-y: auto;
        overflow-x: hidden;

    }

    .news-modal__footer {

        margin-bottom: 0;

    }
}
