.achievement-modal {

    width: calc( 1180px - 40px - 2px );

    .modal-title {

        color: var( --color-primary );
        font-size: 18px;
        font-weight: 900;
        line-height: 21px;
        margin-bottom: 30px;

    }

}