.questionnaire-question-table-container {

    .questionnaire-question-table {

        &__questionnaire-question-element {
            list-style: none;
            width: 100%;
            height: 100%;
            margin-top: 20px;      
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            background-color: white !important;
            border: 1px solid transparent;
            border-radius: 8px;
            box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
   
            .questionnaire-question-element-indicator {
                width: 10px;
                display: flex;
                padding: 0;
                margin: 0;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border: 1px solid var(--color-blue);
                background-color: var(--color-blue);
            }

            .text-section-container{
                display: flex;
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .questionnaire-question-element-container {
                padding: 20px;
                width: calc(100% - 20px - 20px);
            }

            .question-image {
                max-height: 300px;
                margin-top: 20px;
            }
            
            .question-bottom-div {
                width: 100%;
                display:flex;
                justify-content: right;
                align-items: center;
                padding: 0;

                @media (max-width: 576px) {

                    justify-content: flex-start;
                    margin-top: 15px;
                    flex-wrap: wrap;

                    .button {

                        margin-left: 11px;
                        margin-top: 11px;
                        min-width: unset;
                        width: calc( 50% - 5.5px );

                        &:first-child, &:nth-child( 3 ) {

                            margin-left: 0;

                        }

                    }

                    .expand-button {

                        margin-left: auto;
                        margin-top: 11px;

                    }

                }

            }

            .question-scale-container-div{
                width: 100%;
                display:flex;
                justify-content: left;
                flex-direction: column;
                padding: 0;

                .question-scale-div{
                    width: 100%; //50+77+77
                    display:flex;
                    justify-content: left;
                    padding: 0;

                    .question-scale-from-div{
                        width: 77px ;
                        height: 47px !important;
                        display:flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid var(--color-border);
                        border-radius: 8px;
                        color: var(--color-dark);
                        font-family: var(--font-primary);
                        font-size: 14px;
                        font-weight: 400 ;
                        line-height: 19px;
                    }
    
                    .question-scale-div-center-div{
                        width: 50px ;
                        height: 47px !important;
                        display:flex;
                        justify-content: center;
                        align-items: center;
                        color: var(--color-dark);
                        font-size: 14px;
                        font-weight: 500 ;
                        line-height: 21px;
                    }

                    .select-box--box-full {   
                        width: 77px !important;
                    }
                    
                    .select-box--box {
                        min-width: 77px !important;
                    }

                }
                
            }

            .bottom-button-margin-left {
                margin-left: 20px;
            }

            .expand-button {
                width: 26px;
                height: 26px ;
                background-color: white;
                border: 1px solid transparent;
                border-radius: 13px;
                cursor: pointer;
                transition: background-color var(--transition-speed);
                display:flex;
                flex-direction: column;
                justify-content: left;
                align-items: center;
                margin-left: 20px;
                box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);

                &:hover {
                    background-color: var(--color-highlight);
                }
            }
            
        }
  
        .question-top-div {
            width: 100%;
            display:flex;
            align-items: flex-end;
            padding: 0;

            @media (max-width: 576px) {

                align-items: flex-start;
                flex-direction: column;

            }

            .question-question-div {
                width: 100%;
                margin-right: 0px;
            }

            .select-box--box {
                width: 300px !important;
                margin-left: 20px;

                @media (max-width: 576px) {

                    margin-left: 0 !important;
                    min-width: unset !important;
                    width: 100% !important;

                }
            }

            .select-box--container {
                width: 300px !important;

                @media (max-width: 576px) {

                    width: 100% !important;

                }
            }

            .add-image-button {
                width: 48px;
                height: 48px ;
                background-color: var(--color-primary);
                border: 1px solid transparent;
                border-radius: 24px;
                cursor: pointer;
                padding: 0 16px;
                transition: background-color var(--transition-speed);
                display:flex;
                flex-direction: column;
                justify-content: left;
                align-items: center;
                margin-left: 20px;

                &:hover {
                    background-color: var(--color-highlight);
                }

                .icon {
                    width: 44px ;
                    height: 44px ;
                    padding: 0 !important;
                    margin: 0 !important;
                }
            }

        }

    }

    .input-container-header {
        text-align: bottom !important;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0px;
        width: 100%;

    }

}
