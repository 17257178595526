.title-list {

    &__politician-title-element {
        height: 27px;
        position: relative;
        z-index: 0;
        border: 1px solid var(--color-primary);
        border-radius: 21px;
        display: inline-block;  
        margin-left: 10px; 
        margin-bottom: 10px; 
        padding: 1px 5px 0 10px; 
    }

    .name{
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
    }

    .delete {
        margin-left: 10px; 
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 13px;
        cursor: pointer;
        width: 26px;
        height: 26px;
        transition: background-color var(--transition-speed);
        
        &:hover {
            background-color: var(--color-highlight);
        }
    }
}


