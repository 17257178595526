.questionnaire-tag-modal {

    width: 1156px;
    height: 462px;

    @media (max-width: 576px) {

        left: 0;
        top: 0;
        height: 100%;
        overflow-y: scroll;
        position: fixed;
        width: 100%;

        .container {

            flex-direction: column;
            padding: 15px;
            width: calc( 100% - 30px );

            > div {

                width: calc( 100% );

                &.card-div {

                    margin-top: 30px;
                    width: calc( 100% - 40px );

                }

            }

        }

    }

    &__header {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;

    }

    &__title {
        color: var(--color-primary);
        font-size: 26px;
        font-weight: 900;
        line-height: 30px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .close-button {
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 13px;
        color: var(--color-border);
        font-family: var(--font-primary);
        font-size: 18px;
        font-weight: 300;
        line-height: 18px;
        cursor: pointer;
        width: 26px;
        height: 26px;
        transition: background-color var(--transition-speed);

        &:hover {
            background-color: var(--color-highlight);
        }
    }

    footer {
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;
    }

    .separator {
        background-color: var(--color-border);
        height: 382px;
        width: 1px;
        margin: 0px 32px;
        padding: 0;

        @media (max-width: 576px) {

            display: none;

        }

    }

    .title-margin{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .name-div {
        width: 100%;
        height: 36px;
        display: flex;
        justify-content: left;
        align-items: center;
        margin: 10px 0px;
    }

    .editor-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        margin: 0px 0px;
    }

    .colors-div {
        width: 216px;
        height: 69px;
        margin-bottom: 40px;
        margin-right: 60px;

        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
    }

    .color-button {
        background-color: var(--color-primary);
        border: 1px solid transparent;
        border-radius: 13px;
        color: var(--color-white);
        cursor: pointer;
        width: 26px;
        height: 26px;
        margin: 3px 5px;
        transition: background-color var(--transition-speed);

        &:hover {
            border: 1px solid black;
        }
    }

    .questionnaire-tag-list {
        padding: 0px 8px 0px 0px;
        margin: 0;
        width: 440px;
        height: 340px;
        overflow-y:auto;
        overflow-x:hidden;

        @media (max-width: 576px) {

            width: 100%;

        }

    }

    .empty-text {
        width: 100%;
        height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .text-right-margin {
        margin-right:30px;
    }

    .color {
        width: 105px;
        border: 1px solid transparent;
        border-radius: 18px;
        height: 26px;
    }

    .new-category-button-div {
        width: 100%;
        margin-top: 8px;
        margin-right: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .text-section-container{
        width: 70% !important;
    }

    .edit-button-top-margin{
        margin-top: 180px;
    }
}
