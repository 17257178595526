.event-card {

    background-color: white;
    width: 100%;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: 0 0px 14px 0 rgba(0, 0, 0, 0.08);

    .title-text-h3 {

        margin: 8px 10px !important;
        max-height: 40px;
        padding: 0 !important;
        overflow: hidden;

    }

    .event-image {

        aspect-ratio: 16 / 9;
        border: 1px solid transparent;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
        object-fit: cover;
        object-position: center center;
        width: 100%;

    }

    .image-loading {
        display: none;
    }

    .title {

        padding: 8px 10px;

    }

    .title-text-loading {
        height: 60px;
        background-color: var(--color-border);
        margin: 8px 10px;
        border-radius: 6px;
        border: 1px solid var(--color-border);
    }

    .text-loading {
        height: 20px;
        background-color: var(--color-border);
        margin: 0 10px;
        width: 100px;
        border-radius: 6px;
        border: 1px solid var(--color-border);
    }

    .bottom-div {

        display: flex;
        flex-direction: column;
        width: 100%;

        .time,
        .location {

            align-items: center;
            display: flex;
            margin-left: 18px;

            img {

                height: 14px;
                margin-right: 11px;
                width: 14px;

            }

            >div {

                font-size: 12px;
                line-height: 14px;

                >div:first-child {

                    margin-bottom: 4px;

                }

            }

        }

        .time {

            margin-bottom: 8px;

            >div {
                font-weight: 700;
            }

        }

    }

}
