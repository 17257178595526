.layout--default {
    background-color: #F5F7F4;
    display: flex;
    padding-top: 80px;

    .page {

        margin-left: 358px;
        min-height: calc(var(--inner-height) - 80px - 30px - 30px);
        padding: 30px;

        @media (max-width: 576px) {

            margin-left: 0 !important;
            min-height: calc(var(--inner-height) - 80px - 30px);
            padding: 15px;

        }

    }
}
