.page--seasons {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        align-items: flex-start;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: calc(100% - 0px);

        }

        .toolbar {

            display: flex;
            justify-content: flex-end;
            margin: 30px 0;

            .button {

                margin-left: 20px;

            }

        }

        .card-div--seasons {

            padding: 0 15px;

        }

        .seasons {

            display: flex;
            flex-direction: column;
            width: 100%;

            a {

                color: inherit;
                text-decoration: none;

            }

            &__active {

                width: 100%;

            }

            &__caption {

                background-color: #28A8DF;
                color: white;
                display: flex;
                font-size: 12px;
                font-weight: 700;
                justify-content: center;
                line-height: 14px;
                margin-top: -1px;
                padding: 3px 0;
                width: 100%;

            }

            .season-info {

                padding: 30px 0;
                text-align: center;

            }

        }

    }

}