.quizes-table {

    width: 100%;

    .table__header {

        border-radius: 8px 8px 0 0;

    }

    .table__pagination {

        border-radius: 0 0 8px 8px;

    }

}