.info-textbox {
  position: absolute;
  top: -1.5em;
  background-color: white;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 10px;
  opacity: 0;
  translate: -50% 0;
  z-index: 1;
}

.info-textbox.active {
  opacity: 1;
}

.info-hover {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: gray;
  font-family: var(--font-primary);
}
