.politician-question-answer-list {

    &__politician-question-answer-element {
        list-style: none;
        width: 100%;
        height: auto;
        display:flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;

        .politician-question-answer-element-container {
            width: 100%;
            height: auto;
            margin-top: 30px;
        }

    }

}