.page--settings {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        padding: 15px;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: 100%;

        }

    }

    .navigation-title {

        margin-bottom: 30px;

    }

    .columns {

        display: flex;
        justify-content: space-between;

        @media (max-width: 576px) {

            flex-direction: column;

        }

        > div {

            margin-bottom: 20px;
            width: calc( 50% - 10px - 40px );

            @media (max-width: 576px) {

                width: calc( 100% - 40px );

            }

        }

        .card-div {

            display: flex;
            flex-direction: column;
            padding: 15px 20px 30px 20px;
            text-align: center;

            h2 {

                margin-bottom: 20px;
                text-align: center;

            }

            a {

                color: var( --color-primary );
                font-size: 18px;
                font-weight: 700;
                line-height: 21px;
                text-decoration: none;
                word-break: break-all;

            }

        }

    }

    .application-versions-table {

        margin: -15px -21px;
        width: calc( 100% + 42px );

    }

    .button-container {

        display: flex;
        justify-content: flex-end;
        margin-top: 30px;

    }

}

.application-version-confirm-modal {

    text-align: center;

    .button {

        margin: 0 10px;

        &--warning {

            background-color: rgba( 195, 8, 112, 0.2 );
            border-color: #C30870;
            color: #C30870;

        }

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 40px;

    }

    .modal-description {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 28px;
        padding: 0 100px;

    }

}
