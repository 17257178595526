.confirm-message-modal {

    text-align: center;
    width: 1156px;

    .modal-title {

        color: #0267AA;
        font-size: 26px;
        font-weight: 900;
        line-height: 30px;
        margin-bottom: 40px;

    }

    .modal-message {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 30px;

    }

    footer {

        .button {

            min-width: 194px;

        }

    }

}