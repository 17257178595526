.politician-question-list {

    &__politician-question-element {
        list-style: none;
        width: 100%;
        min-height: 64px;
        display:flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        background-color: white !important;

        // border-color: var(--color-border);
        // border-radius: 10px;
        border-bottom: 1px solid #F0F0F0;

    }

    .drag_icon {
        width: 4%;
    }

    .question {
        width: 92%;
    }
    
    .menu-container {
        width: 4%;
    }

}

