.admin-activity {

    display: flex;
    font-size: 14px;
    line-height: 16px;
    padding: 7px 0 7px 28px;

    a {

        color: var( --color-primary );
        font-weight: 700;
        text-decoration: none;

    }

    strong {

        color: #606060;
    }

    &__description {

        margin-right: auto;
        position: relative;

        &::before {

            background-color: var(--color-font);
            border-radius: 50%;
            content: "";
            left: -19px;
            height: 6px;
            position: absolute;
            top: 5px;
            width: 6px;

        }

    }

    &__date {

        margin-left: 46px;
        white-space: nowrap;

    }

    &:last-child {

        margin-bottom: 0;

    }

}
