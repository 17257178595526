.page--view-quote {
  align-items: center;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  padding: 30px;

  .main-div {
    display: flex;
    flex-direction: column;
    justify-content: left;
    position: relative;
    width: 100%;
  }

  .navigation-title {
    margin-bottom: 28px;
  }

  .toolbar {
    display: flex;
  }

  @media (max-width: 576px) {
    main {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  main {
    display: flex;
  }

  .quote-card-front {
    background-color: var(--color-primary);
    border-radius: 24.5px;
    height: 507px;
    margin-right: 20px;
    min-width: 329px;
    overflow: hidden;
    position: relative;
    width: 329px;

    @media (max-width: 576px) {
      margin: 15px;
    }

    .layer {
      background: linear-gradient(
        180deg,
        rgba(2, 103, 170, 0) 0%,
        #0267aa 100%
      );
      height: 387px;
      left: 0;
      position: absolute;
      top: 132px;
      width: 100%;
    }

    img.background {
      height: 100%;
      object-fit: cover;
      object-position: center center;
      width: 100%;
    }

    img.party {
      height: 50px;
      object-fit: contain;
      object-position: center center;
      position: absolute;
      right: 20px;
      top: 20px;
      width: 50px;
    }

    .quote {
      bottom: 20px;
      color: white;
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      left: 35px;
      position: absolute;
      text-align: center;
      width: calc(100% - 70px);
      z-index: 10;
    }
  }

  .quote-card-back {
    background-color: var(--color-primary);
    border-radius: 24.5px;
    color: white;
    height: 507px;
    margin-right: 20px;
    min-width: 329px;
    text-align: center;
    width: 329px;
    @media (max-width: 576px) {
      margin: 15px;
    }

    .quote {
      font-size: 22px;
      font-weight: 700;
      height: 48px;
      line-height: 24px;
      margin: 68px 35px 17px 35px;
      overflow: hidden;
    }

    .caption {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 8px;
    }

    .value {
      font-size: 16px;
      line-height: 19px;
      height: 19px;
      overflow: hidden;
      margin-bottom: 18px;

      &--double-line {
        height: 38px;
        margin-bottom: 36px;
      }
    }

    .button--white {
      background-color: white;
      border-radius: 24.5px;
      color: var(--color-primary);
      font-size: 16px;
      line-height: 17px;
      margin-bottom: 10px;
      padding: 15px 0;
      width: calc(100% - 40px);
    }
  }

  .button--warning {
    background-color: rgba(195, 8, 112, 0.2);
    border-color: #c30870;
    color: #c30870;
  }

  .info-caption {
    color: var(--color-primary);
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 3px;
  }

  .info-stat {
    color: #27b06c;
    font-size: 29px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 22px;
  }

  .info-description {
    color: #363638;
    font-size: 14px;
    line-height: 16px;
  }

  footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

    .quote-confirm-modal {

        text-align: center;

        .button {

            margin: 0 10px;

            &--warning {

                background-color: rgba( 195, 8, 112, 0.2 );
                border-color: #C30870;
                color: #C30870;

            }

        }

        .modal-title {

            color: var( --color-primary );
            font-size: 26px;
            font-weight: 900;
            line-height: 29px;
            margin-bottom: 40px;

        }

        .modal-description {

            color: #59595B;
            font-size: 16px;
            line-height: 17px;
            margin-bottom: 28px;
            padding: 0 100px;

        }

        footer {

            justify-content: center !important;

        }

    }

}
