.stat-page {

    color: var( --color-primary );
    font-weight: 700;

    display: grid !important;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;

    margin-bottom: 30px;

    .card-div {

        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 20px 15px;
        text-align: center;

    }

    .label {

        font-size: 18px;
        line-height: 20px;
        margin-bottom: 17px;

    }

    .value {

        font-size: 26px;
        font-weight: 900;
        line-height: 29px;

    }

}