.politician-card {
    height: 276px;
    width: 226px;
    background-color: white;
    position: relative;
    z-index: 0;
    border: 1px solid transparent;
    border-radius: 10px;
    box-shadow: 0 0px 14px 0 rgba(0, 0, 0, 0.08);
    display: inline-block;  
    margin-right: 17px;
    margin-bottom: 17px;
    cursor: pointer;
    transition: background-color var(--transition-speed);

    &:hover {
        border-color: var(--color-highlight);
        border-radius: 10px;
        box-shadow: 0 6px 6px 0 var(--color-border);
    }

    .politician-image-div {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 2;
        top: 0px;
        left: 0px;
        z-index: 1;
        border-radius: 10px !important;
        overflow: hidden;
    
        .pol-image {
            height: auto;
            width: 100%;
            border: 1px solid transparent;
            border-radius: 10px;
            position: absolute;
            z-index: 2;
            top: 0px;
            left: 0px;
        }

    }

    .question-count {
        height: 33px;
        width: 33px;
        border: 1px solid transparent;
        border-radius: 16px;
        position: absolute;
        z-index: 5;
        top: 8px;
        right: 8px;
        background-color: #C30870;
        color: white;
        display:flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
    }

    .text-container{
        height: calc(276px - 14x - 14px);
        width: calc(226px - 14px - 14px);
        position: absolute;
        z-index: 3;
        left: 0px;
        bottom: 0px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        padding: 14px 14px;
        border-bottom-left-radius: 10px ;
        border-bottom-right-radius: 10px ;
        background: linear-gradient(180deg, rgba(2,103,170,0), rgba(2,103,170,0.46), rgba(2,103,170,0.78), #0267AA);
    
        .title-text {
            text-align: left;
            font-size: 22px;
            font-weight: 700;
            line-height: 22px;
            color: white;
            margin-bottom: 6px;
            overflow: hidden;
        }

        .id-text {
            text-align: left;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 6px;
            overflow: hidden;

            color: white;
        }

        .description-text {
            text-align: left;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            overflow: hidden;

            color: white;
        }
    }

    .close-button {
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 13px;
        color: white;
        font-family: var(--font-primary);
        font-size: 18px;
        font-weight: 300;
        line-height: 18px;
        cursor: pointer;
        width: 26px;
        height: 26px;
        transition: background-color var(--transition-speed);
        position: absolute;
        z-index: 5;
        right: 8px;
        top: 8px;

        &:hover {
            background-color: var(--color-highlight);
        }
    }
   
}