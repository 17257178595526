.quote-card {
  aspect-ratio: 148 / 227;
  border: 2px solid var(--color-primary);
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  width: 148px;

  &__add {
    align-items: center;
    background-color: var(--color-primary);
    border-radius: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 50%;
    translate: -50% -50%;
    width: 48px;

    span {
      color: white;
      font-size: 32px;
    }

    @media (max-width: 764px) {
      height: 30px;
      width: 30px;
      translate: -50% -150%;
    }
    @media (max-width: 576px) {
      height: 24px;
      width: 24px;
    }
    @media (max-width: 507px) {
      height: 18px;
      width: 18px;
    }
    @media (max-width: 380px) {
      height: 12px;
      width: 12px;
    }
    @media (max-width: 330px) {
      height: 8px;
      width: 8px;
      translate: -50% -250%;
    }
  }

  &__placeholder {
    bottom: 8px;
    color: var(--color-primary);
    font-size: 14px;
    left: 50%;
    line-height: 16px;
    position: absolute;
    text-align: center;
    translate: -50% 0;
    width: 70%;
    @media (max-width: 507px) {
      font-size: 12px;
      line-height: 13px;
    }
    @media (max-width: 470px) {
      font-size: 8px;
      line-height: 9px;
    }
    @media (max-width: 470px) {
      font-size: 6px;
      line-height: 7px;
    }
  }

  &__image {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    img {
      height: 100%;
      object-fit: cover;
      object-position: center center;
      width: 100%;
    }
  }

  &__party-image {
    height: 22px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 22px;
    z-index: 2;

    img {
      height: 100%;
      object-fit: contain;
      object-position: center center;
      width: 100%;
    }
  }

  &__remove {
    align-items: center;
    background-color: white;
    border-radius: 50%;
    display: flex;
    height: 26px;
    justify-content: center;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 26px;
    z-index: 2;

    span {
      color: var(--color-warning);
      font-size: 16px;
    }
  }

  &__quote {
    bottom: 8px;
    color: white;
    font-size: 14px;
    font-weight: 700;
    left: 50%;
    line-height: 16px;
    position: absolute;
    text-align: center;
    translate: -50% 0;
    width: 70%;
    z-index: 2;
  }

  &__quote-id {
    color: white;
    font-size: 14px;
    font-weight: 700;
    left: 50%;
    line-height: 16px;
    position: absolute;
    text-align: center;
    top: 6px;
    translate: -50% 0;
    width: 70%;
    z-index: 2;
  }

  .mask-top {
    background: linear-gradient(0deg, rgba(2, 103, 170, 0) 0%, #0267aa 100%);
    height: 76.36%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .mask-bottom {
    background: linear-gradient(180deg, rgba(2, 103, 170, 0) 0%, #0267aa 100%);
    height: 76.36%;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
  }
}
