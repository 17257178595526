.page--winners {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

    }

    .navigation-title {

        margin-bottom: 30px;

    }

    .toolbar {

        display: flex;
        margin-bottom: 30px;

        .button {

            margin-left: 11px;

            &--new {

                margin-left: 98.5px;
                min-width: 212px;

            }

        }

    }

    .card-div {

        border: none;

    }

    .table {

        margin: -14px -20px;
        width: calc( 100% + 40px );

        &__header {

            border-radius: 8px 8px 0 0;

        }

        &__pagination {

            border-radius: 0 0 8px 8px;

        }

    }

    .color-bar {

        border-radius: 13px;
        height: 26px;
        width: 100px;

    }

    .modal {

        text-align: center;

        .button {

            margin: 0 10px;

            &--warning {

                background-color: rgba( 195, 8, 112, 0.2 );
                border-color: #C30870;
                color: #C30870;

            }

        }

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 40px;

    }

    .modal-description {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 28px;
        padding: 0 100px;

    }

    .year, .week-group, .week {

        cursor: pointer;
        margin-bottom: 20px;

        &__header {

            align-items: center;
            box-shadow: 0 0 10px 0 rgba( 0, 0, 0, 0.08 );
            border-radius: 8px;
            background-color: white;
            color: var( --color-primary );
            display: flex;
            font-size: 22px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 20px;
            padding: 16px 35px;

            .button {

                align-items: center;
                background-color: transparent !important;
                box-shadow: 0 0 8px 0 rgba(0,0,0,0.1);
                display: flex;
                height: 26px !important;
                justify-content: center;
                margin-left: auto;
                min-width: unset !important;
                padding: 0 !important;
                width: 26px !important;

                img {

                    height: 8px;
                    object-fit: contain;
                    object-position: center center;
                    rotate: 180deg;
                    transition: rotate 0.33s;
                    width: 8px;

                }

            }

        }

        &__body {

            padding-left: 30px;

        }

    }

    .year {

        &--open {

            .year__header {

                .button {

                    img {

                        rotate: 270deg;

                    }

                }

            }

        }

    }

    .week-group {

        &--open {

            .week-group__header {

                .button {

                    img {

                        rotate: 270deg;

                    }

                }

            }

        }

    }

    .week {

        &--open {

            .week__header {

                .button {

                    img {

                        rotate: 270deg;

                    }

                }

            }

        }

    }

}