.sidebar {

    background-color: var(--color-white);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.08);
    height: calc(var(--inner-height) - 80px - 30px - 30px);
    padding: 30px 0;
    position: fixed;
    z-index: 10;

    .scroll-bar {

        height: calc(100% - 30px - 30px);
        position: absolute;
        right: 20px;
        top: 30px;
        width: 17px;

         @media(max-width: 1640px) {

             display: none;

         }

    }

    .scroll {

        --scroll-height: 50px;
        --scroll-top: 0px;

        background-color: var(--color-light-gray);
        border-radius: 8.5px;
        height: var(--scroll-height);
        position: absolute;
        top: var(--scroll-top);
        width: 17px;

    }



    @media (max-width: 576px) {

        height: calc( 100% - 80px );
        left: -100%;
        overflow-x: hidden;
        overflow-y: scroll;
        position: fixed;
        top: 80px;
        transition: left 200ms;
        width: 100%;

        scrollbar-width: 0;
        &::-webkit-scrollbar {

            display: none;

        }

    }


    @media (min-width:576px) and (max-width:1640px) {

        width: 53px;

        overflow-x: hidden;
        overflow-y: scroll;
        position: fixed;
        top: 80px;
        transition: left 200ms;
        height: calc(var(--inner-height) - 80px - 30px - 30px);

        scrollbar-width: 0;
        &::-webkit-scrollbar {

            display: none;

        }

           // @media(min-height: calc(var(--inner-height) - 80px - 30px - 30px)) {

        @media(min-height: 900px) {

            overflow-y: hidden;
            width: 53px;
    
        }
    }


}

.layout--mobile-menu-open {

    .sidebar {

        @media (max-width: 576px) {

            left: 0;

        }

    }

}
