.page--quotes-calendar {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    padding: 30px;

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 100%;

    }

    .navigation-title {

        margin-bottom: 28px;

    }

    .toolbar {

        display: flex;
        margin-bottom: 20px;

    }

    .card-div {

        .calendar {

            margin: -14px -20px;
            width: calc(100% + 40px);

        }

    }

    .day-quotes {

        flex-direction: column;

        .title-text-h2 {
            text-align: left;
            font-size: 22px;
            font-weight: 700;
            line-height: 24px;
            margin: 0 0 30px 0;
            color: var(--color-primary);
            font-family: var(--font-primary);
        }

        .quote-cards {
            display: flex;
            justify-content: center;

            .quote-card {
                margin-right: 15px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .quote-info {

            border-radius: 8px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
            color: var(--color-dark);
            font-size: 14px;
            line-height: 16px;
            margin-left: 15px;
            padding: 17px 12px 17px 16px;
            width: calc(382px - 12px - 16px);

            @media (max-width: 576px) {

                margin-left: 0;
                width: calc( 100% - 12px - 16px );

            }

            &__title {

                color: var(--color-primary);
                font-size: 18px;
                font-weight: 700;
                line-height: 20px;
                margin-bottom: 10px;

            }

        }

    }

}
