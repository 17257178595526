.news-table {
 
    .news-title {
        height: 16px;
        margin-right: 14px;
        overflow: hidden;
        word-break: break-all; 
    }

    .header-action-container {
        width:100%;
        margin-left: 20px;
        text-align: left;
     }

    .header-action-item {
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 16px;
        cursor: pointer;
        transition: background-color var(--transition-speed);

        .icon {
            margin-left: 7px;
            margin-right: 7px;
            text-align: center;
            font-size: 18px;
        }

        &:hover {
            background-color: var(--color-highlight);
        }
    }

    .tag-container {
        text-align: center;
        width: 100%;
    }
}

.news-table-confirm-modal {

    text-align: center;

    .button {

        margin: 0 10px;

        &--warning {

            background-color: rgba( 195, 8, 112, 0.2 );
            border-color: #C30870;
            color: #C30870;

        }

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 40px;

    }

    .modal-description {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 28px;
        padding: 0 100px;

    }

}
