.page--tags {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

    }

    .navigation-title {

        margin-bottom: 30px;

    }

    .toolbar {

        display: flex;
        margin-bottom: 30px;

        .button {

            margin-left: 15px;

        }

    }

    .columns-view {

        display: flex;

        .column {

            &:first-child {

                width: calc( 100% - 497px - 2px - 18px - 40px );

            }

            &:last-child {

                width: calc( 497px - 2px );

            }

        }

        .separator {

            background-color: #F0F0F0;
            border-radius: 9px;
            margin: 0 20px;
            width: 18px;

        }

    }

    .tag-editor {

        display: flex;
        flex-direction: column;
        padding: 30px;
        width: calc( 100% - 60px );

        &__title {

            color: var( --color-primary );
            font-size: 22px;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 25px;
            text-align: center;
            width: 100%;

        }

        > .field {

            align-items: center;
            display: flex;
            margin-bottom: 10px;
            width: 100%;

            &--color {

                margin-top: 50px;

            }

            label {

                color: var( --color-primary );
                font-size: 18px;
                font-weight: 700;
                line-height: 21px;
                margin-right: 24px;
                width: 151px;

            }

            .field {

                width: calc( 100% - 151px - 24px );

            }

            input {

                font-size: 16px !important;
                font-weight: 400;
                height: 19px !important;
                line-height: 19px !important;
                padding: 9px 15px !important;

            }

            .select-box--box {

                border-radius: 8px;
                height: unset;
                width: calc( 100% - 151px - 24px - 6px );

            }

            .select-box--arrow {

                margin-top: 4px;

            }

            .select-box--items {

                border: 1px solid #DDDDDD;
                border-width: 0 1px 1px 1px;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                margin-left: -1px;
                margin-top: -6px;
                position: absolute;
                width: calc( 100% );

                div {

                    width: calc( 100% - 2px - 16px - 6px );

                    &:last-child {

                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;

                    }

                }

            }

            .select-box--container {

                height: unset;

            }

            .select-box--selected-item {

                font-size: 16px !important;
                font-weight: 400;
                height: 19px !important;
                line-height: 19px !important;
                padding: 9px 15px !important;

            }

            .color-bar {

                border-radius: 13px;
                height: 26px;
                width: 105px;

            }

        }

        .color-picker {

            display: flex;
            justify-content: center;
            margin-top: 30px;
            margin-bottom: 30px;

        }

        footer {

            display: flex;
            justify-content: center;

            .button {

                margin: 0 10px;

                &--warning {

                    background-color: rgba( 195, 8, 112, 0.2 );
                    border-color: #C30870;
                    color: #C30870;

                }

            }

        }

    }

    .modal {

        text-align: center;

        .button {

            margin: 0 10px;

            &--warning {

                background-color: rgba( 195, 8, 112, 0.2 );
                border-color: #C30870;
                color: #C30870;

            }

        }

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 40px;

    }

    .modal-description {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 28px;
        padding: 0 100px;

        > .field {

            align-items: center;
            display: flex;
            margin-bottom: 10px;
            width: 100%;

            &--color {

                margin-top: 50px;

            }

            label {

                color: var(--color-primary);
                font-size: 18px;
                font-weight: 700;
                line-height: 21px;
                margin-right: 24px;
                width: 151px;

            }

            .field {

                width: calc(100% - 151px - 24px);

            }

            input {

                font-size: 16px !important;
                font-weight: 400;
                height: 19px !important;
                line-height: 19px !important;
                padding: 9px 15px !important;

            }

        }

    }

}

