.menu {

    .menu__menu-group {

        border-bottom: 1px solid var(--color-light-gray);
        padding: 0 0 0 10px;
        margin: 0;

        @media (max-width: 576px) {

            display: flex;
            flex-direction: column;

        }

        &:last-child {

            border-bottom: none;

        }

        @media(max-width: 1640px) {

            padding: 0 0 0 0;

        }

    }

    >.menu__menu-group {

        padding-bottom: 11px;
        padding-left: 0;
        padding-top: 20px;

        >.menu__menu-element {

            margin: 0 0 0 -6px;
            width: calc(100% + 6px + 6px);

       
        }

    }

}
