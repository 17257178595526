.page--course {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        align-items: flex-start;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: calc(100% - 0px);

        }

        .toolbar {

            display: flex;
            justify-content: space-between;
            margin: 30px 0;

        }

        .card-div {

            border: none;
            margin-bottom: 10px;
            padding: 20px;
            width: calc(100% - 40px);

        }

        .info {

            color: #59595B;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 5px;
            text-align: right;

        }

        .grid {

            display: flex;
            flex-wrap: wrap;

            .card-div--00 {

                margin-right: 10px;
                width: calc( 67% - 40px - 5px );

                .col {

                    margin-right: 10px;
                    width: calc( ( 100% - 20px ) / 3 );

                }

            }

            .card-div--01 {

                width: calc( 33% - 40px - 5px );

            }

            .card-div--02 {

                .col {

                    &:nth-child( 1 ) {

                        margin-right: 18px;

                    }

                    &:nth-child( 2 ) {

                        margin-right: 50px;
                        width: calc( ( 100% - 40px - 50px - 18px - 87px - 130px ) / 2 )

                    }

                    &:nth-child( 3 ) {

                        width: calc( ( 100% - 40px - 50px - 18px - 87px - 130px ) / 2 )

                    }

                }

            }

            .card-div--03 {

                margin-bottom: 20px;
                margin-right: 10px;
                width: calc( 30% - 40px )

            }

            .card-div--04 {

                margin-bottom: 20px;
                width: calc( 33% - 40px );

                .cols {

                    justify-content: center;
                    text-align: center;

                    .col {

                        width: 100%;

                    }

                    .button {

                        width: 80%;

                    }

                }

            }

            .card-div--05 {

                flex-direction: column;
                margin-bottom: 20px;

                .caption {

                    margin-bottom: 20px;

                }

                .cols {

                    align-items: center;
                    margin-bottom: 20px;
                    width: 100%;

                }

                .col {

                    width: 100%;

                    .caption {

                        font-size: 18px;
                        line-height: 20px;

                    }

                    &.course-image {

                        margin-right: 30px;
                        width: 215px;

                    }

                    &.course-title {

                        width: calc( 100% - 30px - 215px );

                        .caption {

                            margin-bottom: 0;

                        }

                    }

                }

            }

            .card-div--06 {

                margin-bottom: 20px;
                width: calc( 33% - 40px );

                .politician-selector {
                    display: flex;
                    margin-top: 15px;

                    .politician-image {
                        border-radius: 8px;
                        margin-right: 15px;
                        overflow: hidden;

                        img {
                            height: 100px;
                            object-fit: cover;
                            object-position: center center;
                            width: 80px;
                        }
                    }

                    .politician-name {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 19px;
                        margin-bottom: 45px;
                    }
                }

            }

            .card-div--07 {

                margin-bottom: 20px;
                flex-direction: column;

                .caption--small {

                    font-size: 18px;
                    line-height: 21px;
                    margin-bottom: 0;

                }

                .award-name {

                    margin-bottom: 20px;
                    width: 100%;

                }

                .award-images {

                    margin-bottom: 20px;
                    margin-right: 50px;
                    width: 87px;

                }

                .bronze-award-image, .silver-award-image {

                    margin-bottom: 20px;
                    margin-right: 265px;
                    width: 128px;

                    .caption--small {

                        margin-bottom: 10px;
                        text-align: center;

                    }

                }

                .gold-award-image {

                    margin-bottom: 20px;
                    width: 128px;

                    .caption--small {

                        margin-bottom: 10px;
                        text-align: center;

                    }

                }

                .award-experiences {

                    align-items: center;

                }

                .award-experience {

                    margin-bottom: 20px;
                    margin-right: 50px;
                    width: 87px;

                    img {

                        width: 87px;

                    }

                }

                .bronze-award-experience, .silver-award-experience {

                    margin-bottom: 20px;
                    margin-right: 265px;
                    width: 128px;

                    .caption--small {

                        margin-bottom: 10px;
                        text-align: center;

                    }

                    input {

                        text-align: center;

                    }

                }

                .gold-award-experience {

                    margin-bottom: 20px;
                    width: 128px;

                    .caption--small {

                        margin-bottom: 10px;
                        text-align: center;

                    }

                    input {

                        text-align: center;

                    }

                }

            }

            .card-div--08 {

                .col {

                    width: 100%;

                }

            }

        }

        .caption {

            color: var( --color-primary );
            font-size: 22px;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 15px;

        }

        .lessons {

            display: flex;
            flex-direction: column;
            width: 100%;

            .lesson {

                align-items: flex-end;
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                width: 100%;

                &__title {

                    color: var( --color-primary );
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 21px;

                    a {

                        color: inherit;
                        text-decoration: none;

                    }

                }

                &__info {

                    color: #59595B;
                    font-size: 12px;
                    line-height: 14px;

                }

            }

        }

        .cols {

            display: flex;
            width: 100%;

            .col {

                &:last-child {

                    margin-right: 0;

                }

                .react-datepicker-wrapper {

                    .react-datepicker__input-container {
                        height: 48px;

                        input {
                            cursor: pointer;
                            border-radius: 8px;
                            border: 1px solid var(--color-border);
                            height:calc(48px - 14px - 14px) !important;
                            min-width: 210px;
                            padding: 14px 16px ;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 16px;
                            color: var(--color-font);
                            font-family: var(--font-primary);
                        }

                    }
                }

            }

        }

        footer {

            padding: 20px 0;
            text-align: center;
            width: 100%;

            a {

                color: inherit;
                display: flex;
                max-width: 474px;
                margin: 0 auto;
                text-decoration: none;

            }

            .new-lesson {

                align-items: center;
                background-color: white;
                border-radius: 8px;
                box-shadow: 0 0 10px 0 rgba(0,0,0,0.08);
                display: flex;
                justify-content: center;
                padding: 7px 0 6px 0;
                width: 100%;

                span {

                    color: #363638;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19px;
                    margin-left: 15px;

                }

            }

        }

        .options {

            display: flex;

            .option {

                align-items: center;
                display: flex;
                margin-right: 28px;

                input {

                    height: 19.5px;
                    opacity: 0;
                    width: 19.5px;

                }

                label {

                    color: #363638;
                    font-size: 16px;
                    line-height: 19px;
                    position: relative;
                    margin-left: 8px;

                    &::before {

                        border: 1px solid var( --color-primary );
                        border-radius: 50%;
                        content: "";
                        height: 18px;
                        left: calc( 0px - 8px - 18px );

                        position: absolute;
                        top: 0;
                        width: 18px;

                    }

                }

                input:checked + label {

                    &::after {

                        background-color: var( --color-primary );
                        border-radius: 50%;
                        content: "";
                        height: 12px;
                        left: calc( 0px - 8px - 14px );
                        pointer-events: none;
                        position: absolute;
                        top: 4px;
                        width: 12px;

                    }

                }

            }

        }

    }

}