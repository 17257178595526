.select-box--container {
    height: 32px;
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
}

.select-box--container-full {
    height: 48px !important;
}

.select-box--box-full {
    height: calc(48px - 14px - 14px) !important;
    border-radius: 8px !important;
    text-align: left !important;
    padding: 14px 0px !important;
    margin-top: 15px;
    min-width: 300px !important;
}

.select-box--box-disabled {
    filter: grayscale(0.5);
    opacity: 0.5;
    pointer-events: none;
}

.select-box--box {
    width: 100%;
    height: 32px;
    border: 1px solid var(--color-border);
    border-radius: 4px;
    background-color: white;
}

.select-box--box:hover {
    box-shadow: 0 0 4px var(--color-border);
}

.select-box--selected-item {
    display: inline-block;
    height: calc(32px - 8px - 8px);
    width: calc(100% - 16px - 6px);

    padding: 8px 6px 8px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-font);
    font-family: var(--font-primary);
    margin-bottom: 0px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    text-align: left !important;
}

.select-box--selected-item-full {
    display: inline-block;
    height: calc(48px) !important;
    width: 100%;
    padding: 3px 16px !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-font);
    font-family: var(--font-primary);
    margin: 0px !important;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.select-box--items div {
    border: 1px solid transparent;
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.08);
    background-color: white;
    min-height: calc(32px - 8px - 8px);
    width: calc(100% - 16px - 6px);
    padding: 8px 6px 8px 16px;
    z-index: 9;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--color-font);
    font-family: var(--font-primary);
    text-align: left !important;
    cursor: pointer;
}

.select-box--items-full div {
    height: calc(48px - 14px - 14px) !important;
    padding: 14px 16px !important;
}

.select-box--items-full div {
    height: 48px;
}

.select-box--item-selected {
    font-weight: 700 !important;
}

.select-box--arrow {
    width: 30px;
    height: calc(33px - 4px - 4px);
    margin: 0;
    padding: 4px 3px;
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
}

.select-box--arrow-full {
    padding: 0px 3px;
}
