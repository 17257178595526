.tab-view {

    header {

        position: relative;

        .tab {

            cursor: pointer;
            min-width: 90px;
            position: relative;
            text-align: center;
            z-index: 1;

        }

        &::after {

            background-color: #F5F7FA;
            content: "";
            height: 1px;
            left: 0;
            position: absolute;
            top: calc(100% - 1px);
            width: 100%;
            z-index: 0;

        }

    }

}
