.page-floating-messages {
 
    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    
    @media (max-width: 576px) {

        width: 100%;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: 100%;

        }

    }

    .toolbar {

        margin-top: 28px;
        height: 36px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: left;
        margin-bottom: 0 !important;

        @media (max-width: 576px) {

            flex-wrap: wrap;
            height: auto !important;

            .field--search {

                width: calc( 100% - 22px - 16px - 16px - 2px - 11px );

            }

        }

    }

    .button {

        margin-left: 15px;
        height: 100%;

        @media (max-width: 576px) {

            height: 38px;
            margin-left: 11px;

        }

    }

    .footer {

        display: flex;
        width: 100%;
        margin-bottom: 28px;
        margin-top: 28px ;
        justify-content: right;

        @media (max-width: 576px) {

            flex-wrap: wrap;
            margin-bottom: 68px !important;

        }

        .save-button {

            @media (max-width: 576px) {

                min-width: unset;
                width: calc( 50% - 5.5px );

            }

        }

    }

    .title-text-top-padding {
        padding-top: 15px;
    }

    .scroll-menu {
        overflow: auto;
        white-space: nowrap;
        padding-top: 10px;
        padding-bottom: 10px ;

        &__icon {
            border-radius: 8px !important;
            box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.08);
            margin-right: 10px;

            height: 94px;
            width: 94px;
            padding: 6px;

            &:hover {
                background-color: var(--color-highlight);
            }
        }

        .active {
            background-color: var(--color-highlight); 
        }

    }

}

.floating-message-table-confirm-modal {

    text-align: center;

    .button {

        margin: 0 10px;

        &--warning {

            background-color: rgba( 195, 8, 112, 0.2 );
            border-color: #C30870;
            color: #C30870;

        }

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 40px;

    }

    .modal-description {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 28px;
        padding: 0 100px;

    }

}