.interest {

    display: flex;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
    padding-left: 28px;
    padding-right: 28px;

    width: calc(100% - 28px - 28px);

    &__tag {

        margin-right: auto;
        display: flex;
        flex-direction: row;
        justify-content: left;
        width: 100%;

        .name {

            font-weight: 700;
            width: 25%;

        }

        .percent {

            font-weight: 400;
            width: 5%;

        }

        .percent-div {
            height:20px;
            width: 70%;
            margin-right: 10px;
            display: flex;
            flex-direction: row;
            justify-content: left;
            background: lightgray;
            border-radius: 10px;

            .percent {
                width: 100%;
                border-radius: 10px;    
            }
        }

    }

    &:last-child {

        margin-bottom: 0;

    }

}
