.politicians-modal {

    .politician-phreses-list {

        &__politician-phrese-element {
            list-style: none;
            width: 100%;
            display:flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            background-color: white !important;
            border: 1px solid var(--color-light-gray);
            border-radius: 8px;
            margin-bottom: 8px;
        }
  
        .description{
            padding: 16px 16px;
        }

    }

}
