.modal-container {

    align-items: center;
    background-color: rgba(2, 103, 170, 0.3);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;

    @media (max-width: 576px) {

        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

    }

}

.modal {

    background-color: var(--color-white);
    border-radius: 10px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
    max-height: calc(100vh - 160px - 25px - 25px - 10px - 10px);
    overflow-y: scroll;
    padding: 25px;

    @media (max-width: 576px) {

        max-height: 100%;
        height: 100%;
        width: 100%;

    }

    &::-webkit-scrollbar {
        display: none;
    }

}
