.page--scheduled-quotes {
  align-items: center;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  padding: 30px;

  .main-div {
    display: flex;
    flex-direction: column;
    justify-content: left;
    position: relative;
    width: 100%;
  }

  .navigation-title {
    margin-bottom: 28px;
  }

  .toolbar {
    display: flex;
    margin-bottom: 33px;

    .button-icon {
      margin-right: 5px;
    }

    .button {
      margin-left: 10px;
    }
  }

  .card-div {
    flex-direction: column;
    margin-bottom: 15px;
    padding: 15px 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .caption {
    color: var(--color-primary);
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 5px;
  }

  .quote-cards {
    display: flex;
    justify-content: center;

    .quote-card {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
