
.page-create-news {

    .info-slot {
        display: flex;
        flex-direction: column;
         padding: 0px;

        &__info {
            padding-top: 10px;
            margin-left: 0px ;
            margin-right: 0px;
        }

    }
}