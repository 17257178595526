.page--lesson {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        align-items: flex-start;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: calc(100% - 0px);

        }

        .toolbar {

            display: flex;
            justify-content: space-between;
            margin: 30px 0;

            &--bottom {

                margin: 0;
                justify-content: flex-end;

            }

        }

        .card-div {

            border: none;
            margin-bottom: 20px;
            padding: 20px;
            width: calc(100% - 40px);

            &--01 {

                flex-direction: column;

                .lesson-experiences {

                    align-items: center;
                    margin-bottom: 20px;

                    input {

                        margin-bottom: 0;
                        text-align: center;

                    }

                }

                .col.lesson-experience {

                    margin-right: 50px;
                    width: 87px;

                    img {

                        width: 100%;

                    }

                }

                .col.lesson-onestar-experience, .col.lesson-twostar-experience {

                    margin-right: 265px;
                    text-align: center;
                    width: 128px;

                }

                .col.lesson-threestar-experience {

                    text-align: center;
                    width: 128px;

                }

            }

        }

        .info {

            color: #59595B;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 5px;
            min-height: 14px;
            text-align: right;

        }

        .grid {

            display: flex;
            flex-wrap: wrap;

        }

        .caption {

            color: var( --color-primary );
            font-size: 22px;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 15px;

            &--small {

                font-size: 18px;
                line-height: 21px;
                margin-bottom: 0;

            }

        }

        .cols {

            display: flex;
            width: 100%;

            .col {

                width: 100%;

                &:last-child {

                    margin-right: 0;

                }

            }

        }

        .tabs {

            border-bottom: 1px solid var( --color-primary );
            display: flex;
            justify-content: center;
            margin-top: 15px;
            width: 100%;

            .tab {

                cursor: pointer;
                padding: 0 10px 10px 10px;
                margin: 0 10px -2px 10px;

                &--active {

                    color: var( --color-primary );
                    border-bottom: 3px solid var( --color-primary );
                    font-weight: 700;

                }

            }

        }

        .lesson-pages, .quiz-questions {

            display: flex;
            flex-direction: column;
            width: 100%;

        }

        input, textarea, .area--description {

            margin-bottom: 20px;

        }

        footer {

            padding: 20px 0;
            text-align: center;
            width: 100%;

            .new-lesson-page {

                align-items: center;
                background-color: white;
                border-radius: 8px;
                box-shadow: 0 0 10px 0 rgba(0,0,0,0.08);
                cursor: pointer;
                display: flex;
                justify-content: center;
                max-width: 474px;
                margin: 0 auto;
                padding: 7px 0 6px 0;
                width: 100%;

                span {

                    color: #363638;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19px;
                    margin-left: 15px;

                }

            }

        }

        .area--text {

            margin-top: 0;

        }

    }

}