.page--mobile-app-users {

    .search {

        align-items: center;
        display: flex;

        .field--search {

            margin-right: 10px;

        }

    }

    .main-div {

        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

    }

    .card-div {

        border: none;
        flex-direction: column;
        padding: 20px;
        margin-bottom: 30px;
        // overflow: hidden;

        header {

            margin-bottom: 18px;

        }

        .caption {

            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            margin-bottom: 15px;

        }

    }

    h2 {

        color: var(--color-primary);
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        margin: 0;

    }

    .info-slots {

        display: flex;
        justify-content: space-between;

        .info-slot {

            width: calc(50% - 20px - 20px - 8px);

        }

    }

    .table {

        margin: -20px;

        .table__body__column {

            height: 16px;
            word-break: break-all;

        }

        label {

            margin: -3px 0 0 0;

        }

    }

}

.users-table-confirm-modal {

    text-align: center;

    .button {

        margin: 0 10px;

        &--warning {

            background-color: rgba( 195, 8, 112, 0.2 );
            border-color: #C30870;
            color: #C30870;

        }

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 40px;

    }

    .modal-description {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 28px;
        padding: 0 100px;

    }

}