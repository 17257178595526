.menu {

    height: 100%;
    overflow-y: scroll;
    padding: 0 65px 0 30px;
    scrollbar-width: 0;
    width: calc(340px - 30px - 65px);

    &::-webkit-scrollbar {

        display: none;

    }

    @media(max-width: 1640px) {

        width: 53px;
        padding: 0 70px 0 0;

        overflow-x: visible;
        overflow-y: auto;

    }

    @media (max-width: 576px) {

        width: 100%;
        padding: 0;

    }

}
