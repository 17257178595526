.page--tester {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        .navigation-title {

            margin-bottom: 30px;

        }

        .quest, .questGroup {

            align-items: center;
            display: flex;
            margin: 5px 0;
            padding: 0;

            &__type {

                margin-right: 10px;

            }

            &__name {

                margin-right: 10px;

            }

            &__exp {

                margin-right: 10px;

            }

            &__percentage {

                margin-right: 10px;

            }

            &__claimed {

                margin-right: 10px;

            }

            &__claim {

                margin-right: 10px;

            }

        }

    }

}