.image-editor-background {

    align-items: center;
    background-color: rgba(2, 103, 170, 0.3);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20000;

}

.image-editor {

    background-color: white;
    border-radius: 7px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    overflow: hidden;
    padding: 20px;
    position: relative;
    width: calc(1217px - 20px - 20px);

    @media (max-width: 576px) {

        height: 100%;
        overflow-y: scroll;
        width: 100%;

    }

    header {

        align-items: center;
        border-bottom: 1px solid #F5F7FA;
        display: flex;
        padding-bottom: 20px;

        @media (max-width: 576px) {

            flex-wrap: wrap;

        }

        button {

            @media (max-width: 576px) {

                margin-top: 11px;
                min-width: unset;
                width: calc( 50% - 5.5px );

            }

            &:last-child {

                margin-left: 15px;

                @media (max-width: 576px) {

                    margin-left: 11px;

                }

            }

        }

    }

    main {

        display: flex;
        margin-top: 20px;

        @media (max-width: 576px) {

            flex-direction: column-reverse;

        }

    }

    &__title {

        color: var(--color-primary);
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-right: auto;

    }

    &__tools {

        margin-right: 20px;
        width: 359px;

        @media (max-width: 576px) {

            margin-bottom: 15px;
            margin-right: 0;
            width: 100%;

        }

        .tool {

            border-radius: 8px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
            margin-bottom: 20px;
            padding: 20px;

            &__title {

                color: var(--color-primary);
                font-size: 22px;
                font-weight: 700;
                line-height: 24px;
                margin-bottom: 15px;

            }

            input {

                border: 1px solid #DDDDDD;
                border-radius: 8px;
                color: #59595B;
                font-family: var(--font-primary);
                font-size: 16px;
                letter-spacing: 0;
                line-height: 17px;
                padding: 14px 16px;
                width: calc(100% - 16px - 16px - 1px - 1px);

                &[disabled] {

                    border: 1px dashed #DDDDDD;
                    background-color: #DDDDDD;

                }

            }

            .resolution {

                align-items: center;
                display: flex;
                margin-bottom: 10px;

                input:first-child {

                    margin-right: 15px;

                }

                input:last-child {

                    margin-left: 15px;

                }

            }

            .aspect-ratio {

                align-items: center;
                display: flex;
                margin-bottom: 20px;

            }

            .button {

                width: 100%;

            }

            .actions {

                display: flex;
                justify-content: space-around;

                span:last-child {

                    transform: rotate(-90deg);

                }

                span.disabled {

                    opacity: 0.25;
                    pointer-events: none;

                }

            }

            &:last-child {

                margin-bottom: 0;

            }

        }

    }

    &__editor {

        align-items: center;
        display: flex;
        height: 502px;
        justify-content: center;
        position: relative;
        width: calc(100% - 20px - 359px);

        @media (max-width: 576px) {

            width: 100%;

        }

        img {

            margin-left: auto;
            margin-right: auto;

            max-width: 100%;
            max-height: 100%;

            &.disabled {

                opacity: 0;

            }

        }

        .icon {

            left: 50%;
            position: absolute;
            top: 50%;
            translate: -50% -50%;
            z-index: 10;

        }

    }

    .cutter {

        position: fixed;
        cursor: move;
        left: 60%;
        top: 50%;
        right: 30%;
        bottom: 30%;

        border: 2px solid #0267AA;

        &__top {

            cursor: ns-resize;
            position: absolute;

            top: -2px;
            left: 0px;
            height: 20px;
            width: calc(100% + 8px);

        }

        &__bottom {

            cursor: ns-resize;
            position: absolute;

            bottom: -2px;
            left: 0px;
            height: 20px;
            width: calc(100% + 8px);

        }

        &__left {

            cursor: ew-resize;
            position: absolute;

            bottom: 0px;
            left: -2px;
            height: calc(100% + 8px);
            width: 20px;

        }

        &__right {

            cursor: ew-resize;
            position: absolute;

            bottom: 0px;
            right: -2px;
            height: calc(100% + 8px);
            width: 20px;

        }

        &__top-left {

            border-top: 4px solid #0267AA;
            border-left: 4px solid #0267AA;
            cursor: nwse-resize;
            position: absolute;
            top: -4px;
            left: -4px;
            height: 20px;
            width: 20px;

        }

        &__top-right {

            border-top: 4px solid #0267AA;
            border-right: 4px solid #0267AA;
            cursor: nesw-resize;
            position: absolute;
            top: -4px;
            right: -4px;
            height: 20px;
            width: 20px;

        }

        &__bottom-right {

            border-bottom: 4px solid #0267AA;
            border-right: 4px solid #0267AA;
            cursor: nwse-resize;
            position: absolute;
            bottom: -4px;
            right: -4px;
            height: 20px;
            width: 20px;

        }

        &__bottom-left {

            border-bottom: 4px solid #0267AA;
            border-left: 4px solid #0267AA;
            cursor: nesw-resize;
            position: absolute;
            bottom: -4px;
            left: -4px;
            height: 20px;
            width: 20px;

        }

        &__fade-top,
        &__fade-right,
        &__fade-bottom,
        &__fade-left {

            background-color: rgba(0, 0, 0, 0.3);
            position: fixed;

        }

        &--invisible {

            opacity: 0;

        }

    }

    &__busy {

        align-items: center;
        backdrop-filter: blur(5px);
        background-color: rgba(255, 255, 255, 0.95);
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: opacity 250ms;
        width: 100%;
        -webkit-backdrop-filter: blur(5px);

        span {

            color: var(--color-primary);
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            margin-top: 20px;

        }

        &--show {

            opacity: 1.0;
            pointer-events: all;

        }

    }

}

body.image-editor-no-scroll {

    .image-editor {

        overflow: hidden !important;

    }

}
