.politician-title-add-modal {

    width: 458px;
    height: 462px;

    &__header {
        margin-top: 5px;
        margin-bottom: 29px;

        display: flex;
        justify-content: space-between;
    }

    &__title {
        color: var(--color-primary);
        font-size: 26px;
        font-weight: 900;
        line-height: 30px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .close-button {
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 13px;
        color: var(--color-border);
        font-family: var(--font-primary);
        font-size: 18px;
        font-weight: 300;
        line-height: 18px;
        cursor: pointer;
        width: 26px;
        height: 26px;
        transition: background-color var(--transition-speed);

        &:hover {
            background-color: var(--color-highlight);
        }
    }

    .label-text {
        line-height: 19px;
        font-size: 22px !important;

    }

    .politician-title-list {
        padding: 0px 8px 0px 0px;
        margin: 0;
        width: 440px;
        height: 340px;
        overflow-y:auto;
        overflow-x:hidden;

        .name{
            width: 95% !important;
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .empty-text {
        width: 100%;
        height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

}
