.page-create-questionnaire {

    .questionnaire-push {

        flex-direction: column;
        margin-top: 30px;
        position: relative;

        .caption {

            color: var( --color-primary );
            font-size: 22px;
            font-weight: 700;
            line-height: 26px;
            margin-top: 4.5px;
            margin-bottom: 53.5px;

        }

        > .button {

            position: absolute;
            right: 20px;
            top: 14px;

        }

        .questionnaire__messages {

            display: flex;
            gap: 68px;

            .questionnaire-message {

                display: flex;
                flex-direction: column;
                width: calc( ( 100% - 136px - ( 42px * 3 ) ) / 3 );

                &__body {

                    color: #363638;
                    font-size: 16px;
                    height: calc( 19px * 4 );
                    line-height: 19px;
                    margin-bottom: 8px;
                    overflow: hidden;

                }

                &__footer {

                    align-items: center;
                    display: flex;
                    justify-content: space-between;

                }

                &__date {

                    color: #59595B;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;

                }

                &__delete {

                    align-items: center;
                    color: #C30870;
                    cursor: pointer;
                    display: flex;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 14px;

                    span {

                        font-size: 14px;
                        margin-right: 5px;

                    }

                }

            }

        }

    }

    .toolbar {

        display: flex;
        margin-bottom: 0 !important;
        margin-top: 28px;

        @media (max-width: 576px) {

            flex-wrap: wrap;

        }

        button {

            margin-left: 15px;

            &:first-child {

                margin-left: 0;
                margin-right: auto;

            }

            @media (max-width: 576px) {

                margin-left: 11px;

            }

        }

        .back-button {

            @media (max-width: 576px) {

                margin-left: 0;
                min-width: unset;
                width: calc( 50% - 5.5px );

            }

        }

    

        .save-button {

            @media (max-width: 576px) {

                margin-left: 0;
                margin-top: 11px;
                min-width: unset;
                width: calc( 50% - 5.5px );

            }

        }

    }
    .card-div-right-margin {
        margin-right: 24px;

        @media (max-width: 576px) {

            margin-bottom: 15px;

        }

    }

    .checkbox-label {
        height: 20px !important;
        text-align: center;
    }

    .card-div-bottom-padding {
        padding-bottom: 24px;

        @media (max-width: 576px) {

            width: 100%;

        }
    }

    .single-button-margin {
        margin: 20px;
    }

    .card-sub-div-right-margin {
        margin-right: 30px;

        @media (max-width: 576px) {

            margin-bottom: 15px;

        }

    }

    .container-top-margin {
        margin-top: 20px;
    }

    .sub-title-text-center {
        text-align: center;
        width: 100%;
    }

    .react-datepicker-wrapper {
        margin-top: 15px;

        .react-datepicker__input-container {
            height: 48px;

            input {
                cursor: pointer;
                border-radius: 8px;
                border: 1px solid var(--color-border);
                height:calc(48px - 14px - 14px) !important;
                min-width: 200px;
                padding: 14px 16px ;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: var(--color-font);
                font-family: var(--font-primary);
            }

        }
    }

    .category-select-background {
        min-width: 280px;
    }

    .check-box-group {
        min-width: 200px;

        @media (max-width: 576px) {

            height: unset;

        }

    }     
    
    .image-div-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
    }

    .image-section-container{

        .image-container{
            width: calc(255px - 14px - 14px);
            height: calc(120px - 14px - 14px);
            border-radius: 5px;
            text-align: center;
            border: 1px solid var(--color-primary);
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 14px 22px;
            margin-top: 20px;
        
            .image-container-image{
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: var(--color-primary); 
                display:flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 10px;
            }
        }

        .image{
            width: 255px ;
         //   height: 120px ;
            // max-height:  120px;
            height: auto;
            margin-top: 20px;   
            border-radius: 5px;
            border: 1px solid var(--color-primary);
        }
    }

    .text-section-container{
        width: 100% !important;
    }

    .info-icon {
        margin-left: 24px;
        font-size: 18px;
        font-weight: 400;
    }

    h3.label-text {

        @media (max-width: 576px) {

            display: none;

        }

    }

    .label-text-primary {
        color: var(--color-primary) ; 
        font-size: 12px;
        font-weight: 700;
        line-height: 13px;
    }

    .info {
        color: var(--color-text) !important; 
        font-weight: 400 !important;
        text-align: right !important;
        margin-top: 18px;
        margin-right: 4px;
    }
  
    .input-container-header {
        text-align: bottom !important;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .header-text-center {
        text-align: center ;
        display:flex;
        align-items: center;
    }

    .icon-white {
        color: white !important;        
    }
    
    .title-text-bottom-padding {
        padding-bottom: 15px;
    }

    .description-edititor-container {
        height: 24px;
        border-radius: 3px;
        text-align: center;
        border: 1px solid var(--color-primary);
        padding: 1px;
        width: 100px;
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;

        .separator {
            background-color: var(--color-primary);
            height: 24px;
            width: 1px;
            margin: 0;
            padding: 0;
        }

        .editor-button {
            height: 24px;
            width: 24px;
            background-color: white;
            border: 1px solid transparent;
            cursor: pointer;
            transition: background-color var(--transition-speed);
        
            &:hover {
                background-color: var(--color-highlight);
            }
        }

        .bold {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
        }

        .italic {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 400 ;
            line-height: 16px;
            font-style: italic;
        }

        .underline {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 400 ;
            line-height: 16px;
            text-decoration: underline;
        }

        .link {
            color: var(--color-primary);
            font-size: 14px;
            font-weight: 200;
            line-height: 18px;
            margin: 0;
            padding: 0;
        }
    }
   
    .questionnaire-tags {

        @media (max-width: 576px) {

            width: 100%;

        }

        header {

            display: flex;
            justify-content: space-between;
            margin-bottom: 23px;

        }

        .caption {

            color: var(--color-primary);
            font-size: 22px;
            font-weight: 700;
            line-height: 24px;
            margin-right: 100px;

            @media (max-width: 576px) {

                margin-right: auto;

            }

        }

        &__tags {

            display: flex;
            flex-wrap: wrap;
            gap: 10px;

        }

        &__empty {

            font-size: 14px;
            padding: 20px;
            text-align: center;

        }

        .questionnaire-tag {

            align-items: center;
            border: 1px solid var(--color-gray);
            border-radius: 10px;
            color: var(--color-gray);
            display: flex;
            font-size: 12px;
            line-height: 13px;
            padding: 3px 12px;

            .material-symbols-outlined {

                cursor: pointer;
                font-size: 13px;
                margin-left: 5px;

            }

        }

    }

    .tab-view {

        header {
            display: flex;
            margin-bottom: 27px;
            margin-top: 35px;
            padding-top: 32px;
            background-color: white;
            width: 100% ;
            padding-bottom: 16px;
            display:flex;
            flex-direction: row;
            justify-content: center;
            border: 1px solid transparent;
            border-radius: 8px;
            box-shadow: 0 0px 14px 0 rgba(0, 0, 0, 0.08);

            .tab {

                border-bottom: 2px solid transparent;
                color: var(--color-primary);
                font-size: 18px;
                font-weight: 700;
                line-height: 20px;
                margin-right: 32px;
                padding-bottom: 6px;

                &--active {

                    border-color: var(--color-primary);

                }

            }

        }

    }

    .main-container {

        @media (max-width: 576px) {

            flex-direction: column;

        }

    }

    footer {

        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 31px;
        margin-top: 28px;

        @media (max-width: 576px) {

            flex-wrap: wrap;
            margin-bottom: 68px !important;

        }

        button {

            margin-left: 15px;

            @media (max-width: 576px) {

                margin-left: 11px;

            }

        }

        .save-button {

            @media (max-width: 576px) {

                min-width: unset;
                width: calc( 50% - 5.5px );

            }

        }

    }

    .xp-editor {

        display: flex;

        img {

            height: 87px;
            margin-right: 50px;
            object-fit: contain;
            object-position: center center;
            width: 87px;

        }

        .field {

            margin-right: 50px;
            width: 464px;

            label {
                color: var(--color-primary);
                display: flex;
                font-size: 18px;
                min-height: 20px;
                line-height: 20px;
                font-weight: 700;
                margin-bottom: 13px;
            }

            input {

                color: #363638 !important;
                font-size: 16px !important;
                font-weight: 700 !important;
                line-height: 17px !important;
                padding: 14px 20px !important;
                width: calc(100% - 40px) !important;

            }

        }

    }

    .confirm-delete-message-modal {

        .modal-title {

            color: var( --color-primary );
            font-size: 22px;
            font-weight: 900;
            line-height: 24px;
            margin-bottom: 30px;
            text-align: center;

        }

        .modal-description {

            text-align: center;
            margin-bottom: 30px;

        }

        footer {

            margin: 0 !important;

        }

    }

    .card-div--push {

        display: flex;
        flex-direction: column;
        margin-top: 30px;
        max-width: calc( 1000px - 40px );
        padding: 20px;

        > div {

            display: flex;

            .button {

                margin-top: 20px;
                margin-left: 20px;
                min-width: 285px;

            }

        }

    }

}
