.league-card {

    align-items: center;
    display: flex;
    flex-wrap: wrap;
    padding: 20px !important;

    .league-icon {

        width: calc( 44px );
        margin-right: 38px;

        img {

            height: 44px;
            object-fit: contain;
            object-position: center center;
            width: 44px;

        }

    }

    .league-info {

        display: flex;
        flex-direction: column;
        width: calc( 100% - 44px - 38px );

    }

    .league-name {

        color: var( --color-primary );
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 10px;

        a {

            color: inherit;
            text-decoration: none;

        }

    }

    .league-details {

        color: #59595B;
        font-size: 12px;
        line-height: 13px;

    }

    .league-actions {

        align-items: center;
        display: flex;
        border-top: 1px solid #F0F0F0;
        justify-content: center;
        margin-top: 15px;
        padding-top: 15px;
        width: 100%;

        a {

            &:first-child {

                img {

                    margin-left: 0;

                }

            }

            &:last-child {

                img {

                    margin-right: 0;

                }

            }

        }

        img {

            height: 14px;
            margin: 0 19px;
            object-fit: contain;
            object-position: center center;
            width: 14px;

        }

    }

    &--wide {

        flex-wrap: nowrap;
        padding: 40px 20px !important;

        .league-info {

            width: unset;
            margin-right: 76px;

        }

        .league-actions {

            border-top: unset;
            margin-top: unset;
            padding-top: unset;
            width: unset;

        }

    }

}