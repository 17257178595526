
.questionnaire-answer-answer-table {

    &__questionnaire-answer-answer-element {
        list-style: none;
        width: 100% ;
        margin-top: 20px;            
        display:flex;
        flex-direction: row;
        justify-content: left;
        align-items: flex-start;

        .text-section-container {
            display:flex;
            width: 100% ;
            flex-direction: column ;
            align-items: flex-start !important;
        }

    }

}



