.page--quote {
  align-items: center;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  padding: 30px;

  .main-div {
    display: flex;
    flex-direction: column;
    justify-content: left;
    position: relative;
    width: 100%;
  }

  .navigation-title {
    margin-bottom: 28px;
  }

  .toolbar {
    display: flex;
    margin-bottom: 33px;

    .button-icon {
      margin-right: 5px;
    }

    .button {
      margin-left: 10px;
    }
  }

    .toolbar-bottom {

        display: flex;
        justify-content: flex-end;
        margin-top: 30px;

    }

  .columns {
    display: flex;
    .separator {
      background-color: #dddddd;
      height: 100%;
      margin: 0 20px;
      width: 1px;
    }
  }

  .card-div {
    flex-direction: column;
  }

  .tabs {
    margin-top: 25px;

    &__tab-bar {
      border-bottom: 1px solid #dddddd;
      display: flex;
      margin-bottom: 15px;

      &__tab {
        border-bottom: 1px solid #dddddd;
        cursor: pointer;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: -1px;
        padding: 6px 25px;

        &--active {
          border-bottom-color: var(--color-primary);
          color: var(--color-primary);
          font-size: 18px;
          font-weight: 700;
        }
      }
    }

    header {
      align-items: center;
      display: flex;
      margin-bottom: 30px;
    }

    .caption {
      color: var(--color-primary);
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      margin-right: 30px;
      white-space: nowrap;
    }

    .quote-politician {
      border: 1px solid var(--color-border);
      border-radius: 8px;
      margin-bottom: 8.5px;
      padding: 10.5px 16px;

      &--selected {
        border: 2px solid var(--color-primary);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  label {
    display: inline-flex;
    margin-bottom: 15px;

    &.caption {
      color: var(--color-primary);
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
    }
  }

  .input-field {
    margin-bottom: 15px;
    position: relative;

    input {
      color: #363638 !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      height: 17px !important;
      line-height: 17px !important;
      padding: 14px 20px !important;
    }

    .react-datepicker__input-container {
      input {
        border: 1px solid var(--color-border);
        border-radius: 8px;
        outline: none;
        width: calc(100% - 40px);
      }
    }

    .info {
      color: #59595b;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      position: absolute;
      top: 6px;
      right: 0;
    }

    &--strong {
      input {
        border-width: 2px !important;
        font-weight: 700 !important;
      }
    }
  }

    .tag-selector-div {

        margin-bottom: 15px;

    }

  .image-selector {
    aspect-ratio: 2 / 3;
    border: 1px solid var(--color-primary);
    border-radius: 15px;
    height: 174px;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;

    &__icon {
      align-items: center;
      background-color: var(--color-primary);
      border-radius: 50%;
      display: flex;
      height: 29px;
      justify-content: center;
      left: 50%;
      position: absolute;
      top: 50%;
      translate: -50% -50%;
      width: 29px;

      img {
        height: 10px;
        width: 10px;
      }
    }

    &__caption {
      bottom: 13px;
      color: var(--color-primary);
      font-size: 12px;
      font-weight: 700;
      left: 0;
      line-height: 13px;
      position: absolute;
      text-align: center;
      width: 100%;
    }

    &__image {
      height: 100%;
      object-fit: cover;
      object-position: center center;
      width: 100%;
    }
  }

  .quote-parties {
    .caption {
      margin-bottom: 14px;
    }

    .quote-party {
      border: 1px solid #dddddd;
      border-radius: 8px;
      font-size: 16px;
      line-height: 17px;
      margin-bottom: 8.5px;
      padding: 11px 17px;

      &:last-child {
        margin-bottom: 0;
      }

      &--selected {
        border: 2px solid var(--color-primary);
      }
    }
  }

  .select-box--box {
    margin: 0;
  }

  .button--compact {
    font-size: 12px;
    line-height: 13px;
    padding: 6px 34px;
    position: absolute;
    right: 14px;
    top: 46px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 14px;

    .tag {
      border: 1px solid var(--color-border);
      border-radius: 10px;
      color: var(--color-dark);
      font-size: 12px;
      font-weight: unset;
      height: unset;
      line-height: 14px;
      padding: 2px 28px 2px 20px;
      position: relative;

      img {
        height: 8px;
        position: absolute;
        right: 12px;
        top: calc(50% - 4px);
        width: 8px;
      }
    }
  }

    .reactions-container {

        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

    }

}
.lineBreak {
    display: inline-block;
  }
  @media (max-width: 576px) {
    .lineBreak {
      word-wrap: break-word;
      white-space: pre-wrap;
      margin-bottom: 5px;
    }
  }