input[type="checkbox"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.checkbox {
    display: inline-block; 
    height: 20px;
    width: 20px;
    margin: 10px 10px;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 4px;
    cursor: pointer;

    .checked {
        font-size: 20px;
        line-height: 20px;
    }

    &:hover {
        background-color: var(--color-highlight);
        border: 1px solid white;

    }
}

.checkbox--hidden {
    display: none;
}

.checkbox--active {
    display: block;
}
