.page-create-news {

    .toolbar {

        display: flex;
        margin-bottom: 31px;
        margin-top: 28px;

        @media (max-width: 576px) {

            flex-wrap: wrap;
            margin-bottom: 68px !important;

        }

        button {

            margin-left: 15px;

            &:first-child {

                margin-left: 0;
                margin-right: auto;

            }

            @media (max-width: 576px) {

                margin-left: 11px;

            }

        }

        .back-button {

            @media (max-width: 576px) {

                margin-left: 0;
                min-width: unset;
                width: calc( 50% - 5.5px );

            }

        }

        .preview-button {

            @media (max-width: 576px) {

                min-width: unset;
                width: calc( 50% - 5.5px );

            }

        }

        .save-button {

            @media (max-width: 576px) {

                margin-left: 0;
                margin-top: 11px;
                min-width: unset;
                width: calc( 50% - 5.5px );

            }

        }

    }

    .tab-view {

        header {
            display: flex;
            margin-bottom: 27px;
            margin-top: 35px;
            padding-top: 32px;
            background-color: white;
            width: 100% ;
            padding-bottom: 16px;
            display:flex;
            flex-direction: row;
            justify-content: center;
            border: 1px solid transparent;
            border-radius: 8px;
            box-shadow: 0 0px 14px 0 rgba(0, 0, 0, 0.08);

            .tab {

                border-bottom: 2px solid transparent;
                color: var(--color-primary);
                font-size: 18px;
                font-weight: 700;
                line-height: 20px;
                margin-right: 32px;
                padding-bottom: 6px;

                &--active {

                    border-color: var(--color-primary);

                }

            }

        }

    }

    .card-div-right-margin {

        margin-right: 24px;

        @media (max-width: 576px) {

            margin-right: 0;

        }

    }

    .input-group-div {
        // display:flex;
        // flex-direction: row;
        // justify-content: center;
        // align-items: center;

    }

    .checkbox-label {
        height: 20px !important;
        text-align: center;
    }

    .card-div-bottom-padding {
        padding-bottom: 24px;
    }

    .single-button-margin {
        margin: 20px;
    }

    .card-sub-div-right-margin {
        margin-right: 30px;
    }

    .container-top-margin {
        margin-top: 20px;
    }

    .sub-title-text-center {
        text-align: center;
        width: 100%;
    }

    .react-datepicker-wrapper {
        margin-top: 15px;

        .react-datepicker__input-container {
            height: 48px;

            input {
                cursor: pointer;
                border-radius: 8px;
                border: 1px solid var(--color-border);
                height:calc(48px - 14px - 14px) !important;
                min-width: 210px;
                padding: 14px 16px ;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: var(--color-font);
                font-family: var(--font-primary);
            }

        }
    }

    .category-select-background {
        min-width: 280px;
    }

    .check-box-group {

        min-width: 200px;

        @media (max-width: 576px) {

            height: unset;

            input {

                width: 20px;

            }

            label {

                display: inline-block;
                margin-right: 0;
                width: calc( 100% - 33px );

            }

        }

    }

    .image-section-container{
        margin-right: 36px;

        @media (max-width: 576px) {

            margin-bottom: 30px;

        }

    }

    .text-section-container{
        width: 100% !important;
    }

    .info-icon {
        margin-left: 24px;
        font-size: 18px;
        font-weight: 400;
    }

    .label-text-primary {
        color: var(--color-primary) ;
        font-size: 12px;
        font-weight: 700;
        line-height: 13px;
    }

    .info {
        color: var(--color-text) !important;
        font-weight: 400 !important;
        text-align: right !important;
        margin-top: 18px;
        margin-right: 4px;

    }

    .input-container-header {
        text-align: bottom !important;
        display:flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: 576px) {

            flex-direction: column;

            span {

                margin-top: 0;
                margin-bottom: 5px;

            }

        }

    }

    .header-text-center {
        text-align: center ;
        display:flex;
        align-items: center;
    }

    .icon-white {
        color: white !important;
    }

    .title-text-bottom-padding {
        padding-bottom: 15px;
    }

    .politician-section-container{
        margin-right: 36px;
        display:flex;
        flex-direction: row;
        justify-content: top;
        margin-top: 15px;
        align-items: top;

        @media (max-width: 576px) {

            align-items: center;
            flex-direction: column;
            margin-right: 0;

            .politician-card {

                margin-right: 0;

            }

        }


        .politician-container {
            height: 273px;
            width: 226px;
            border-radius: 10px;
            text-align: center;
            border: 1px solid var(--color-primary);
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 17px;
            margin-top: 2px;

            cursor: pointer;
            transition: background-color var(--transition-speed);

            @media (max-width: 576px) {

                margin-bottom: 15px;
                margin-right: 0;

            }

            &:hover {
                background-color: var(--color-highlight);
            }

            .image-section-container-image{
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: var(--color-primary);
                display:flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 10px;
            }
        }
    }

    .correct-answer-button {
        background-color: #27b06c;
        border: 1px solid transparent;
        border-radius: 21.5px;
        color: var(--color-white);
        cursor: pointer;
        font-family: var(--font-primary);
        font-size: 16px;
        font-weight: 700;
        line-height: 17px;
        min-width: 186px;
        padding: 8px 24px;
        margin-left: 40px;
        transition: background-color var(--transition-speed);

        @media (max-width: 576px) {

            margin-top: 10px;

        }

        &--ghost {
            background-color: white;
            border: 1px solid var(--color-border);
            color: var(--color-border);
            transition: border-color var(--transition-speed), color var(--transition-speed);

            &:hover {
                background-color: #27b06c;
                color: white;
            }
        }
    }

    .politician-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

    }

    ::-webkit-datetime-edit {
        // cursor: pointer;
    }

    ::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }

    .create-news-form {

        @media (max-width: 576px) {

            width: calc( 100vw - 30px );

        }

        .container {

            @media (max-width: 576px) {

                flex-direction: column;

                .card-div {

                    flex-direction: column;
                    margin-bottom: 15px;

                    &:last-child {

                        margin-bottom: 0;

                    }

                    >div {

                        margin-bottom: 15px;

                        &:last-child {

                            margin-bottom: 0;

                        }

                    }

                }

            }

        }

    }

    footer {

        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 31px;
        margin-top: 28px;

        @media (max-width: 576px) {

            flex-wrap: wrap;
            margin-bottom: 68px !important;

        }

        button {

            margin-left: 15px;

            @media (max-width: 576px) {

                margin-left: 11px;

            }

        }

        .preview-button {

            @media (max-width: 576px) {

                margin-left: 0;
                min-width: unset;
                width: calc( 50% - 5.5px );

            }

        }

        .save-button {

            @media (max-width: 576px) {

                min-width: unset;
                width: calc( 50% - 5.5px );

            }

        }

    }

    .xp-editor {

        display: flex;

        img {

            height: 87px;
            margin-right: 50px;
            object-fit: contain;
            object-position: center center;
            width: 87px;

        }

        .field {

            margin-right: 50px;
            width: 464px;

            label {
                color: var(--color-primary);
                display: flex;
                font-size: 18px;
                min-height: 20px;
                line-height: 20px;
                font-weight: 700;
                margin-bottom: 13px;
            }

            input {

                color: #363638 !important;
                font-size: 16px !important;
                font-weight: 700 !important;
                line-height: 17px !important;
                padding: 14px 20px !important;
                width: calc(100% - 40px) !important;

            }

        }

    }

}
