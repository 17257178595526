.file {

    border: 1px solid transparent;
    border-radius: 6px;
    padding: 8px 7px 5px 7px;

    &__icon {

        aspect-ratio: 161 / 107;
        margin-bottom: 9px;
        position: relative;
        width: 100%;

        img {

            height: 100%;
            object-fit: contain;
            object-position: center center;
            width: 100%;

            &.no-thumbnail {

                background-color: var(--color-primary);

            }

        }

    }

    &__name {

        color: #363638;
        font-size: 14px;
        line-height: 16px;
        max-height: 32px;
        overflow: hidden;
        text-align: center;

    }

    &.selected {

        border-color: var(--color-primary);

    }

}
