.questionnaire-question-answer-table-container {

    .questionnaire-question-answer-table {

        &__questionnaire-question-answer-element {
            list-style: none;
            width: 100% ;
            margin-top: 20px;            
            display:flex;
            flex-direction: column;
            justify-content: left;

            .delete-button {
                display:flex;
                flex-direction: row;
                align-items: center;
                justify-content: left;
                align-items: center;
                background-color: transparent ;
                border: 1px solid transparent;
                border-radius: 8px;
                padding: 5px 5px;
                cursor: pointer;
           
                &:hover {
                    background-color: var(--color-highlight);
                }
             
                .delete-icon {
                    margin-right: 10px;
                }

                .field {
                    width: 100%;
                }

                &.disabled {

                    opacity: 0.25;
                    pointer-events: none;

                }
            }
            
        }

    }

    .field {
        width: 100%;
    }

    .text-section-container {

        .tag-selector {

            margin-top: 15px;
            margin-bottom: 15px;

        }

    }

}
