.questionnaire-question-answer-image-table-container {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    margin-top: 20px;

    .questionnaire-question-answer-image-div-container {
        padding: 0 0 0 0px;
        margin: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: left;
        justify-content: left;
    }

    .image-section-container{
        width: 50%;

        .image-container{
            width: calc(240px - 14px - 14px) !important;
            height: calc(143px - 14px  - 14px) !important;
            border-radius: 5px;
            text-align: center;
            border: 1px solid var(--color-primary);
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 14px 22px;
            margin-top: 20px;
            margin-right: 10px;

            .image-container-image{
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: var(--color-primary); 
                display:flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 10px;
            }

        }

        .image-text-field-container{
            text-align: center;
            display:flex;
            flex-direction: row;
            justify-content: left;
            align-items: left;

            .title-text-h3{
                font-size: 12px;
                font-weight: 700;
                line-height: 13px;
                margin-bottom: 10px;
            }
        }

    
        .image{
            width: calc(250px - 14px - 14px);
            height: calc(140px - 14px - 14px);
            height: auto;
            margin-top: 20px;   
            border-radius: 5px;
            margin-right: 10px;
            border: 1px solid var(--color-primary);
        }
    }
}

