.tag {
    border: 1px solid transparent;
    border-radius: 18px;
    color: white;
    font-family: var(--font-primary);
    font-size: 12px;
    font-weight: 700;
    height: calc( 22px - 2px - 2px);
    line-height: 18px;
    padding: 2px 18px;
}