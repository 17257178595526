.page--events-calendar {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        padding: 15px;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: 100%;

        }

        .toolbar {

            margin-bottom: 22px;
            margin-top: 31px;
            width: 100%;
            display: flex;

            @media (max-width: 576px) {

                flex-wrap: wrap;

                .field--search {

                    width: calc( 100% - 11px - 24px - 16px - 16px - 2px );

                }

            }

            button {

                margin-left: 11px;

                @media (max-width: 576px) {

                    height: 38px;

                }

            }

            .calendar-button {

                padding: 6px 16px;
                min-width: 56px;
                width: 56px;

                @media (max-width: 576px) {

                    margin-top: 11px;
                    margin-left: 0;

                }

            }

            .new-button {

                @media (max-width: 576px) {

                    margin-top: 11px;

                }

            }

        }

    }

}
