.tag-selector {

    display: flex !important;
    flex-direction: column !important;
    position: relative;
    width: 100%;

    header {

        align-items: center !important;
        box-shadow: none !important;
        display: flex;
        justify-content: space-between !important;
        padding: 0 !important;
        margin: 0 0 30px 0 !important;

        &::after {

            display: none !important;

        }

    }

    > main {

        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        .tag {

            align-items: center;
            border: none;
            border-radius: 8px;
            display: flex;
            color: white;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 0;
            padding: 2px 12px 2px 12px;

            img {

                cursor: pointer;
                height: 8px;
                margin-left: 5px;
                object-fit: contain;
                object-position: center center;
                width: 8px;

            }

        }

    }

    &__title {

        color: var( --color-primary );
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;

    }

    .modal {

        position: relative;

        &-close {

            cursor: pointer;
            position: absolute;
            right: 40px;
            top: 40px;

        }

        .modal-title {

            color: var( --color-primary );
            font-size: 26px;
            font-weight: 900;
            line-height: 30px;
            margin-bottom: 30px;
            text-align: center;

        }

        main {

            display: flex;
            flex-direction: column;
            width: 1128px;

        }

    }

    &--compact {

        header {

            background-color: transparent !important;
            position: absolute;
            right: 0;
            top: 0;
            width: 190px !important;

            button {

                font-size: 12px !important;
                line-height: 14px !important;
                padding: 6px 34px !important;

            }

        }

        > main {

            align-items: center !important;
            height: 30px;

        }

    }

}

.modal--tag-selector {

    .tag-selector-modal-header {

        display: flex;
        margin-bottom: 20px;

        button {

            height: 38px;
            margin-left: 15px;

        }

    }

}