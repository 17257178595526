.lesson-page {

    position: relative;

    &::before {

        background-color: var( --color-primary );
        border-radius: 5px 0 0 5px;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 10px;

    }


    .type-select {

        display: flex;
        justify-content: space-between;
        padding: 15px 65px;
        width: 100%;

        .type {

            align-items: center;
            border: 1px solid var( --color-primary );
            border-radius: 8px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            height: 123px;
            padding: 10px;
            width: 123px;

            img {

                height: 30px;
                margin-bottom: 14px;
                object-fit: contain;
                object-position: center center;
                width: 30px;

            }

            span {

                color: var( --color-primary );
                font-size: 18px;
                font-weight: 700;
                line-height: 21px;

            }

        }

    }

    .form {

        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 100%;

    }

    .image-container {

        display: flex;
        justify-content: center;
        margin: 20px 0;
        width: 100%;

    }

    .form-footer {

        border-top: 1px dashed #999999;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        padding-top: 20px;

        .button {

            &--warning {

                background-color: rgba( 195, 8, 112, 0.2 );
                border-color: #C30870;
                color: #C30870;

            }

        }

    }

    .button--expand {

        align-items: center;
        background-color: transparent;
        border-color: var( --color-border );
        display: flex;
        height: 35px;
        justify-content: center;
        margin-left: 20px;
        min-width: 35px !important;
        padding: 0 !important;

        img {

            height: 7px;
            object-fit: contain;
            object-position: center center;
            rotate: 180deg;
            width: 7px;

        }

    }

    &__header {

        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

    }

    &--open {

        .button--expand {

            img {

                rotate: 270deg;

            }

        }

    }

}