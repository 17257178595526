.menu {

    &__menu-element {

        list-style: none;
        font-size: 16px;
        font-weight: 700;
        line-height: 17px;
        position: relative;
        display: inline-block;

        a {
            border: 1px solid transparent;
            border-radius: 6px;
            color: inherit;
            display: flex;
            justify-content: center;
            margin-bottom: 9px;
            padding: 10px 16px;
            text-decoration: none;
            width: calc(100% - 16px - 16px);
            cursor: pointer;
            transition: background-color var(--transition-speed);

            @media (max-width: 576px) {

                text-align: center;

            }

            &:hover {
                background-color: var(--color-highlight) !important;
                color: var(--color-dark) !important;

                img {
                    filter: invert(0) !important;
                }

                @media(max-width: 1640px) {

                    div {
                        display: inline-block;
                        position: absolute;
                        left: 80px;
                        bottom: 16px;
                        background-color: #f9f9f9;
                        box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.08);
                        z-index: 1;
                        padding: 4px 8px;
                        border-radius: 6px;
                    }

                }

                @media (max-width: 576px) {

                    background-color: transparent !important;

                    div {

                        display: none;

                    }

                }

            }

            @media(max-width: 1640px) {

                margin-left: 10px !important;
                margin-right: 12px !important;
                width: calc(100% - 16px - 16px - 22px) !important;
  
            }
            
            &.active {

                border-color: var(--color-primary);
                background-color: var(--color-primary);
                color: var(--color-white);

                img {

                    filter: invert(1);

                }

                @media (max-width: 576px) {

                    background-color: transparent;
                    border: none;
                    color: inherit;

                }

            }

            &.highlighted {

                border-color: var(--color-primary);

                @media (max-width: 576px) {

                    border-color: transparent;

                }
             
                img {
                    @media(max-width: 1640px) {
                        color: invert(1);
                    }
                }

            }

            &.open {

                span {

                    &.material-symbols-outlined {

                        transform: rotate(90deg);

                    }

                }

            }

            span {

                &:first-child {

                    margin-right: auto;

                    @media (max-width: 576px) {

                        margin-right: unset;

                    }

                }

                &.material-symbols-outlined {

                    position: absolute;
                    right: 8px;
                    top: 8px;

                    transition: transform var(--transition-speed);
                    transform-origin: center center;

                    @media (max-width: 576px) {

                        display: none;

                    }

                }

                @media(max-width: 1640px) {

                    display: none;
            
                }

                @media (max-width: 576px) {

                    display: block;
                    text-align: center;

                }

            }

            img {

                @media(min-width: 1640px) {

                    display: none;

                }

                @media (max-width: 576px) {

                    display: none;

                }
                
            }

            div {

                display: none;

            }
           
        }

        >.menu__menu-group {
            >.menu__menu-element {
                width: 100%;

                a {
                    border-color: var(--color-light-gray);

                    @media (max-width: 576px) {

                        border-color: transparent;

                    }

                    &.active {

                        border-color: var(--color-primary);

                        @media (max-width: 576px) {

                            border-color: transparent;

                        }

                    }
                }
            }
        }

    }

}
