.page--courses {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        align-items: flex-start;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;


        @media (max-width: 576px) {

            width: calc(100% - 0px);

        }

        .toolbar {

            display: flex;
            margin: 30px 0;

            button {

                margin-left: 15px;

            }

        }

        .card-div {

            border: none;
            margin-bottom: 30px;
            padding: 20px;
            width: calc( 100% - 40px );

        }

        .list-button-card-div {

            padding: 0;
            width: 100%;

            a {

                color: inherit;
                text-decoration: none;
                width: 100%;

            }

        }

        .list-button {

            align-items: center;
            display: flex;
            padding: 30px 20px;

            &__image {

                margin-right: 20px;
                width: 80px;

            }

            &__title {

                color: var( --color-primary );
                font-size: 22px;
                font-weight: 700;
                line-height: 26px;
                margin-bottom: 10px;

            }

            &__info {

                color: #59595B;
                font-size: 12px;
                line-height: 14px;

            }

        }

        .columns {

            display: flex;
            width: 100%;

        }

        .column {

            width: calc( ( 100% - 64px ) / 3 );

            a {

                color: inherit;
                text-decoration: none;

            }

        }

        .separator {

            background-color: #F0F0F0;
            margin: 0 30px;
            width: 1px;

        }

    }

}