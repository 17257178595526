.test-result {

    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    display: flex;
    height: unset;
    margin-bottom: 10px;
    padding: 7.5px 15px;

    &__name {

        color: #59595B;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        margin-right: auto;

    }

    &:last-child {

        margin-bottom: 0;

    }

    &--selected {

        border-color: var( --color-primary );

    }

}