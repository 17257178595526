.media-library-background {

    align-items: center;
    background-color: rgba(2, 103, 170, 0.3);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20000;

}

.media-library {

    background-color: white;
    border-radius: 7px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    overflow: hidden;
    position: relative;

    @media (max-width: 576px) {

        border-radius: 0;
        overflow-y: scroll;
        height: 100%;

    }

    &__busy {

        align-items: center;
        backdrop-filter: blur(5px);
        background-color: rgba(255, 255, 255, 0.95);
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        transition: opacity 250ms;
        width: 100%;
        -webkit-backdrop-filter: blur(5px);

        span {

            color: var(--color-primary);
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            margin-top: 20px;

        }

        &--show {

            opacity: 1.0;
            pointer-events: all;

        }

    }

    header {

        margin-bottom: 9px;
        margin-top: 42px;
        margin-left: 35px;
        position: relative;

        @media (max-width: 576px) {

            margin-left: 15px;
            width: calc( 100% - 15px );

        }

        span {

            cursor: pointer;
            position: absolute;
            right: 22px;
            top: 0;

        }

    }

    main {

        display: flex;

        @media (max-width: 576px) {

            flex-direction: column;

        }

    }

    &__title {

        color: var(--color-primary);
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;

    }

    &__browser {

        border-right: 1px solid var(--color-primary);
        margin-left: 35px;
        padding-top: 5px;
        padding-right: 13px;

        @media (max-width: 576px) {

            border-right: none;
            margin-left: 15px;
            margin-right: 15px;
            padding-right: 0;
            width: calc( 100% - 30px );

        }

    }

    footer {

        display: flex;
        justify-content: flex-end;
        margin: 26.5px 0;

        .button {

            position: relative;

            input {

                height: 100%;
                left: 0;
                opacity: 0;
                position: absolute;
                top: 0;
                width: 100%;

            }

        }

    }

    &__search {

        margin-bottom: 14.5px;
        position: relative;

        input {

            border: 1px solid #DDDDDD;
            border-radius: 4px;
            background-color: #F4F4F4;
            box-shadow: inset 0 0 0 0 #DBE5EE;
            color: #59595B;
            font-family: "DK Raleway";
            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            padding: 10.5px 31.5px;
            width: calc(100% - (2 * 31.5px) - (2 * 1px));

        }

        span {

            color: var(--color-primary);
            font-size: 16px;
            position: absolute;
            left: 12px;
            top: calc(50% - 8px);

        }

    }

    &__toolbar {

        align-items: center;
        display: flex;
        margin-bottom: 20.5px;

        @media (max-width: 576px) {

            flex-wrap: wrap;

        }

        .folder-name {

            color: var(--color-primary);
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            margin-right: 50px;
            overflow: hidden;
            width: 150px;

            @media (max-width: 576px) {

                margin-bottom: 7.5px;
                width: 100%;

            }

        }

        .up {

            cursor: pointer;
            margin-right: 10px;
            opacity: 0.33;
            pointer-events: none;

            &--active {

                opacity: 1.0;
                pointer-events: all;

            }

        }

        .create-folder {

            cursor: pointer;
            margin-right: 10px;

        }

        .filter {

            cursor: pointer;
            margin-right: 10px;

        }

        .move {

            cursor: pointer;
            margin-right: 10px;
            opacity: 0.33;
            pointer-events: none;

            img {

                height: 16px;

            }

            &--active {

                opacity: 1.0;
                pointer-events: all;

            }

        }

        .button {

            position: relative;

            @media (max-width: 576px) {

                display: none;

            }

            input {

                height: 100%;
                left: 0;
                opacity: 0;
                position: absolute;
                top: 0;
                width: 100%;

            }

        }

    }

    &__panel {

        color: #363638;
        font-size: 14px;
        line-height: 16px;
        padding-top: 7px;
        padding-left: 9px;
        padding-right: 12px;
        width: calc(193px + 9px + 12px);

        @media (max-width: 576px) {

            margin-bottom: 15px;
            width: calc( 100% - 9px - 12px );

        }

        &__preview {

            aspect-ratio: 161 / 107;
            margin-bottom: 6px;
            overflow: hidden;
            width: 100%;

            img {

                height: 100%;
                object-fit: cover;
                object-position: center center;
                width: 100%;

            }

            &.no-thumbnail {

                background-color: var(--color-primary);

            }

        }

        &__name {

            height: 32px;
            margin-bottom: 16px;
            overflow: hidden;
            text-align: center;

        }

        &__information {

            margin-bottom: 21px;

            .caption {

                font-weight: 700;
                margin-bottom: 14px;

            }

            .information {

                display: flex;
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 7px;

                &__name {

                    width: 50%;

                }

                &__value {

                    width: 50%;

                }

            }

        }

        .button {

            width: 100%;

        }

        .buttons {

            display: flex;
            margin-top: 5px;

            button {

                min-width: unset;
                padding: 8px 16px;

            }

            button:first-child {

                margin-right: 5px;

            }

            .button--warning {

                border-color: var(--color-warning);
                color: var(--color-warning);

            }

        }

    }

    .file-browser {

        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        height: 300px;
        overflow-y: auto;
        position: relative;
        width: calc(5 * 175px + 17px);

        @media (max-width: 576px) {

            width: 100%;

        }

        >div {

            width: 159px;

            @media (max-width: 576px) {

                width: calc( 50% - 8px - 8px );

            }

        }

        &__move-panel {

            align-items: center;
            background-color: rgba(255, 242, 184, 0.95);
            border-radius: 8px;
            display: flex;
            left: 10%;
            padding: 8px 16px;
            position: absolute;
            top: 20px;
            width: calc(80% - 16px) !important;
            z-index: 10;

            .button {

                font-size: 12px;
                line-height: 12px;
                padding: 6px 12px;
                margin-left: auto;
                min-width: 150px;

            }

            img {

                cursor: pointer;
                margin-left: 16px;

            }

        }

    }

    &__filters {

        background-color: white;
        border: 1px solid var(--color-border);
        border-radius: 8px;
        min-width: 350px;
        position: absolute;
        top: 170.5px;
        left: 35px;
        z-index: 10;

    }

    &__filter {

        border-bottom: 1px solid var(--color-border);
        cursor: pointer;
        padding: 12px 16px;

        &:last-child {

            border-bottom: none;

        }

    }

    &--moving {

        .file-browser {

            height: calc( 300px - 82px );
            padding-top: 82px;

        }

    }

}
