.questionnaire-question-table-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    .questionnaire-question-table {
        border-bottom: 1px solid var(--color-light-gray);
        padding: 0 0 0 0px;
        margin: 0;
        width: 100%;
    }

    .add-questionnaire-button {
        height: 47px;
        width: 474px;
        margin-top: 30px;
        background-color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        border-radius: 8px;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
        cursor: pointer;

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        @media (max-width: 576px) {
            width: 100%;
        }

        &:hover {
            background-color: var(--color-highlight);
        }

        &--icon {
            height: 36px;
            width: 36px;
            margin-right: 6px;
        }

        &--text {
            color: var(--color-dark);
            font-family: var(--font-primary);
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
        }
    }

    .dotted-label {
        width: 100%;
    }

    .dotted-line::after {
        content: '........................';
        letter-spacing: 6px;
        font-size: 30px;
        color: #9cbfdb;
        display: inline-block;
        vertical-align: 3px;
        padding-left: 10px;
    }
}
