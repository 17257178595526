.quest {

    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;

    .columns {

        display: flex;
        width: 100%;

        >.field:nth-child( 1 ) {

            margin-right: 20px;
            width: calc( 100% - 300px - 20px );

        }

        >.field:nth-child( 2 ) {

            width: 300px;

        }

    }

    .field {

        margin-bottom: 15px;
        width: 100%;

        label {

            color: var( --color-primary );
            display: flex;
            font-size: 18px;
            min-height: 20px;
            line-height: 20px;
            font-weight: 700;
            margin-bottom: 13px;

        }

        input, textarea {

            color: #363638 !important;
            font-size: 16px !important;
            font-weight: 700 !important;
            line-height: 17px !important;
            padding: 14px 20px !important;
            width: calc( 100% - 40px ) !important;

        }

        textarea {

            color: var( --color-primary ) !important;
            font-weight: 700 !important;
            height: calc( 3 * 17px ) !important;

        }

        .description-editor-container {

            margin-left: auto;

        }

        .area--description {

            min-height: calc( 3 * 19px ) !important;

        }

        .select-box--box, .select-box--container {

            border-radius: 8px;
            height: unset !important;

        }

        .select-box--selected-item {

            font-size: 16px !important;
            height: 21px;
            line-height: 21px !important;
            padding: 14px 20px !important;

        }

        .select-box--arrow {

            padding-top: 12px;

        }

    }

    .experience-div {

        margin-right: 20px;
        width: calc( 40% - 20px - 40px - 2px );

    }

    .tags-div {

        width: calc( 60% - 40px - 2px );

    }

    footer {

        border-bottom: 1px dashed var( --color-border );
        display: flex;
        justify-content: flex-end;
        padding: 20px 0;
        width: 100%;

        .button {

            margin-left: 20px;

            &--warning {

                background-color: rgba( 195, 8, 112, 0.2 );
                border-color: #C30870;
                color: #C30870;

            }

        }

    }

    .league-selector {

        margin-top: 20px;
        width: 100%;

        label {

            margin-bottom: 20px;

        }

        .league {

            align-items: center;
            border: 1px solid #DDDDDD;
            border-radius: 8px;
            background-color: #FFFFFF;
            box-shadow: 0 0 10px 0 rgba(2,103,170,0.4);
            display: flex;
            flex-direction: column;
            padding: 20px 20px 13px 20px;
            width: calc( ( 100% - 60px ) / 4 - 40px - 4px );

            &--selected {

                border: 2px solid #0267AA;

            }

            &__icon {

                img {

                    height: 44px;
                    margin-bottom: 20px;
                    object-fit: contain;
                    object-position: center center;
                    width: 44px;

                }

            }

        }

    }

    .tag-selector {

        header {

            border: none !important;

        }

    }

}