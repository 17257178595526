.quiz-topics-modal {

    padding: 30px 20px 30px 10px;
    position: relative;
    width: calc( 1156px - 30px );

    .modal-close {

        cursor: pointer;
        height: 15px;
        position: absolute;
        right: 21.35px;
        top: 25.5px;
        width: 15px;

    }

    .modal-header {

        margin-bottom: 30px;
        width: 100%;

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 30px;
        text-align: center;

    }

    .modal-body {

        display: flex;

    }

    .topics-list {

        margin-right: 30px;
        width: 429px;

        .button {

            border-radius: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;

        }

        .topic {

            border: 1px solid #DDDDDD;
            border-radius: 8px;
            color: #59595B;
            cursor: pointer;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            margin-bottom: 9px;
            padding: 11px 16px;
            position: relative;

            img {

                height: 13px;
                object-fit: contain;
                object-position: center center;
                position: absolute;
                right: 30.5px;
                top: 14.5px;
                width: 13px;

            }

        }

    }

    .separator {

        background-color: #DDDDDD;
        margin-right: 30px;
        width: 1px;

    }

    .topic-detail {

        display: flex;
        flex-direction: column;
        padding: 40px 30px 20px 30px;
        width: calc( 100% - 429px - 30px - 30px - 1px - 60px );

        &--title {

            color: var( --color-primary );
            font-size: 22px;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 25px;
            text-align: center;
            width: 100%;

        }

        &--field {

            display: flex;
            margin-bottom: 10px;
            width: 100%;

            &.last {

                margin-bottom: 25px;

            }

            .field {

                margin: 0;
                width: calc( 100% - 151px - 24px ) !important;

            }

            .tags {

                width: calc( 100% - 151px - 24px );

                .tag-selector {

                    header {

                        right: unset;

                    }

                    main {

                        margin-top: 33px;

                    }

                }

            }

            label {

                color: var( --color-primary );
                font-size: 18px;
                font-weight: 700;
                line-height: 21px;
                margin-top: 8.2px;
                margin-right: 24px;
                width: 151px;

                &.no-margin {

                    margin-top: 0;

                }

            }

            input, textarea {

                color: #363638;
                font-family: 'DK Raleway', sans-serif;
                font-size: 16px !important;
                font-weight: 400;
                line-height: 19px !important;
                padding: 9px 16px 8px 16px !important;

            }

            textarea {

                height: calc( 95px ) !important;
                outline: none;

            }

        }

        &--footer {

            display: flex;
            justify-content: center;
            width: 100%;

            .button {

                min-width: 293px;

                &--warning {

                    background-color: rgba( 195, 8, 112, 0.2 );
                    border-color: #C30870;
                    color: #C30870;
                    min-width: 150px;
                    margin-right: 20px;

                }

            }

        }

    }

    .color-picker {

        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        width: calc( 100% - 151px - 24px );

        &__selected {

            border-radius: 13px;
            height: 26px;
            margin-top: 5px;
            margin-bottom: 8px;
            width: 105px;

        }

        &__selector {

            display: grid;
            grid-template-columns: repeat( 6, 26px );
            grid-template-rows: repeat( 2, 26px );
            gap: 10px;

            .color {

                border-radius: 50%;
                cursor: pointer;
                height: 26px;
                width: 26px;

            }

        }

    }

}