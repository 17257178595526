.questionnaire-card {
    background-color: white;
    width: 100% ;
    padding-bottom: 16px;
    display:flex;
    flex-direction: column;
    justify-content: left;
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: 0 0px 14px 0 rgba(0, 0, 0, 0.08);

    .questionnaire-image {
        width:100%;
        min-height: 200px;
        border: 1px solid transparent;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px; 
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
    }

    .image-loading {
        display: none;
    }

    .title {
        padding: 8px 10px;
    }

    .title-text-loading {
        height: 60px;
        background-color: var(--color-border);
        margin: 8px 10px;
        border-radius: 6px;
        border: 1px solid var(--color-border);
    }

    .text-loading {
        height: 20px;
        background-color: var(--color-border);
        margin: 0 10px;
        width: 100px;
        border-radius: 6px;
        border: 1px solid var(--color-border);
    }

    .bottom-div {
        width: 100% ;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin: 10px 0px;
        align-items: center;

        .date {
            padding: 0 10px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            color: var(--color-dark);
            font-family: var(--font-primary);
        }

        .question {
            color: var(--color-dark);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            padding: 1px 18px;
            margin: 0 10px;
            text-align: right;
        }
        
    }

}