.tag-group {

    height: 41px;
    margin-bottom: 10px;
    overflow: hidden;

    &__header {

        align-items: center;
        background-color: #F0F0F0;
        border: 1px solid #DDDDDD;
        border-radius: 8px;
        display: flex;
        margin-bottom: 10px;
        padding: 10px 15px;

        &__name {

            color: #59595B;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            margin-right: auto;

        }

        &__edit {

            cursor: pointer;
            height: 14px;
            margin-right: 15px;
            object-fit: contain;
            object-position: center center;
            width: 14px;

        }

        &__open {

            cursor: pointer;
            height: 12px;
            object-fit: contain;
            object-position: center center;
            rotate: 180deg;
            transition: rotate 400ms;
            width: 12px;

        }

    }

    &__tags {

        padding-left: 30px;

    }

    &--open {

        height: unset;

        .tag-group__header {

            &__open {

                rotate: 270deg;

            }

        }

    }

}