.achievement-form {

    .select-box--box {

        margin-top: 0;

    }

    &__type {

        display: flex;
        margin-bottom: 30px;

        .select-box--box {

            margin-left: auto;
            max-width: 256px;

        }

    }

    &__header {

        display: flex;
        gap: 30px;
        margin-bottom: 30px;

    }

    &__name, &__description {

        margin-bottom: 30px;

        label {

            margin-bottom: 0;

        }

        .info-text {

            color: #59595B;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 2px;
            text-align: right;

        }

        .area--text {

            margin-top: 0 !important;

            textarea {

                height: 60px !important;

            }

        }

        input {

            width: calc( 100% - 18px ) !important;

        }

    }

    &__image, &__exp {

        align-items: center;
        display: flex;

        > div {

            align-items: center;
            display: flex;
            flex-direction: column;
            width: calc( ( 100% - 87px ) / 3 );

            .field {

                width: 96px;

                input {

                    text-align: center;
                    width: calc( 100% - 18px ) !important;

                }

            }

            &:first-child {

                width: 87px;

                img {

                    height: 87px;
                    object-fit: contain;
                    object-position: center center;
                    width: 87px;

                }

            }

        }

    }

    &__activity {

        width: calc( 100% - 30px - 30px - 30px - 218px - 218px - 218px );

        &.one {

            width: calc( 100% - 30px - 218px );

        }

    }

    &__footer {

        display: flex;
        justify-content: flex-end;
        margin-top: 30px;

        .button {

            &--warning {

                background-color: rgba( 195, 8, 112, 0.2 );
                border-color: #C30870;
                color: #C30870;
                min-width: 150px;
                margin-right: 20px;

            }

        }

    }

    &__value {

        width: 218px;

        .field {

            width: 100%;

            input {

                width: calc( 100% - 18px ) !important;

            }

        }

    }

    label {

        color: var( --color-primary );
        display: flex;
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        margin-bottom: 8px;

    }

    input {

        padding: 14px 8px !important;

    }

}

.achievement-form-confirm-modal {

    text-align: center;

    .button {

        margin: 0 10px;

        &--warning {

            background-color: rgba( 195, 8, 112, 0.2 );
            border-color: #C30870;
            color: #C30870;

        }

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 40px;

    }

    .modal-description {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 28px;
        padding: 0 100px;

    }

}