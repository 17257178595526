.field--search {
    height: 36px;
    width: 100%;

    .input {
        background-color: white;
        border: 1px solid var(--color-border);
        border-radius: 18px;
        color: var(--color-gray);
        font-family: var(--font-primary);
        font-size: 14px;
        font-weight: 400;
        height: 100%;
        line-height: 16px;
        outline: none;
        transition: border-color var(--transition-speed);
        width: 100%;

        &:focus {
            border-color: var(--color-primary);
        }

        &::placeholder {
            color: var(--color-placeholder);
        }
    }

    &--error {
        input {
            background-color: rgba(195, 8, 112, 0.1);
            border-color: var(--color-warning);
        }
    }
}