.page--events-detail {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        padding: 15px;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: 100%;

        }

        .navigation-title {

            margin-bottom: 30px;

        }

    }

    .page-select {

        display: flex;
        justify-content: center;
        padding: 20px 0;
        width: 100%;

        a {

            border-bottom: 2px solid transparent;
            color: var( --color-primary );
            font-size: 18px;
            font-weight: 700;
            line-height: 1.5;
            margin: 0 20px;
            text-decoration: none;

            &.selected {

                border-color: var( --color-primary );

            }

        }

    }

}