.politician-title-modal {

    
    .politician-title-list {

        &__politician-title-element {
            list-style: none;
            width: calc(100% - 16px - 16px) ;
            padding: 0px 16px;
            height: 41px;
            display:flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            background-color: white !important;
            border: 1px solid var(--color-light-gray);
            border-radius: 8px;
            margin-bottom: 8px;
            margin-left: 2px;

            &:hover {
                border: 1px solid var(--color-primary);
                background-color: var(--color-highlight) !important;
            }
    
        }

        .active {
            border: 1px solid var(--color-primary);
        }
  
        .name{
            width: 95% !important;
            font-weight: 700;
            font-size: 16px ;
            line-height: 26px ;
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .delete {
            width: 5% ;
            height: 20px;
            text-align: right;
            cursor: pointer;
        }


    }

    
}
