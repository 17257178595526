.page--achievements {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

    }

    .navigation-title {

        margin-bottom: 30px;

    }

    .toolbar {

        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;

        .button {

            min-width: 208px;

        }

    }

    .card-div--achievements {

        display: flex;
        flex-direction: column;
        position: relative;
        height: 20px;
        margin-bottom: 30px;
        overflow: hidden;

        .caption {

            color: var( --color-primary );
            font-size: 18px;
            font-weight: 900;
            line-height: 21px;
            margin-bottom: 30px;

        }

        .list {

            display: flex;
            flex-wrap: wrap;
            gap: 30px;

            > div {

                width: calc( 578px - 40px - 2px );

            }

        }

        .button--expand {

            align-items: center;
            background-color: transparent;
            border-color: var( --color-border );
            display: flex;
            height: 26px;
            justify-content: center;
            min-width: 26px !important;
            padding: 0 !important;
            position: absolute;
            top: 10px;
            right: 30px;

            img {

                height: 7px;
                object-fit: contain;
                object-position: center center;
                rotate: 180deg;
                width: 7px;

            }

        }

        &.open {

            height: unset;

            .button--expand img {

                rotate: 270deg;

            }

        }

    }

}