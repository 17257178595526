@font-face {
    font-family: 'DK Display';
    font-style: normal;
    font-weight: 600;
    src: url('../font/dk-display-semibold.ttf') format('truetype');
}

@font-face {
    font-family: 'DK Display';
    font-style: normal;
    font-weight: 700;
    src: url('../font/dk-display-bold.ttf') format('truetype');
}

@font-face {
    font-family: 'DK Display';
    font-style: normal;
    font-weight: 900;
    src: url('../font/dk-display-black.ttf') format('truetype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: normal;
    font-weight: 100;
    src: url('../font/dk-raleway-thin.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: italic;
    font-weight: 100;
    src: url('../font/dk-raleway-thin-italic.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: normal;
    font-weight: 200;
    src: url('../font/dk-raleway-extralight.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: italic;
    font-weight: 200;
    src: url('../font/dk-raleway-extralight-italic.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: normal;
    font-weight: 300;
    src: url('../font/dk-raleway-light.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: italic;
    font-weight: 300;
    src: url('../font/dk-raleway-light-italic.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: normal;
    font-weight: 400;
    src: url('../font/dk-raleway-regular.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: italic;
    font-weight: 400;
    src: url('../font/dk-raleway-regular-italic.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: normal;
    font-weight: 500;
    src: url('../font/dk-raleway-medium.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: italic;
    font-weight: 500;
    src: url('../font/dk-raleway-medium-italic.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: normal;
    font-weight: 600;
    src: url('../font/dk-raleway-semibold.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: italic;
    font-weight: 600;
    src: url('../font/dk-raleway-semibold-italic.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: normal;
    font-weight: 700;
    src: url('../font/dk-raleway-bold.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: italic;
    font-weight: 700;
    src: url('../font/dk-raleway-bold-italic.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: normal;
    font-weight: 800;
    src: url('../font/dk-raleway-extrabold.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: italic;
    font-weight: 800;
    src: url('../font/dk-raleway-extrabold-italic.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: normal;
    font-weight: 900;
    src: url('../font/dk-raleway-black.otf') format('opentype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: italic;
    font-weight: 900;
    src: url('../font/dk-raleway-black-italic.otf') format('opentype');
}
