.quest-group-card {

    margin-bottom: 20px;
    padding: 30px !important;
    position: relative;
    overflow: hidden;

    &::before {

        background-color: var( --color-primary );
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 10px;

    }

    .tab-view {

        width: 100%;

        header {

            border-bottom: 1px solid var( --color-primary );
            display: flex;
            justify-content: center;

            .tab {

                font-size: 18px;
                line-height: 20px;
                margin: 0 7.5px;
                padding-bottom: 10.5px;

                &--active {

                    border-bottom: 1px solid var( --color-primary );
                    color: var( --color-primary );
                    font-weight: 700;

                }

            }

        }

        .tab-view-page {

            padding-top: 35px;

            footer {

                display: flex;
                justify-content: flex-end;
                padding-top: 20px;

                .button {

                    margin-left: 20px;

                    &--warning {

                        background-color: rgba( 195, 8, 112, 0.2 );
                        border-color: #C30870;
                        color: #C30870;

                    }

                    &--expand {

                        align-items: center;
                        background-color: transparent;
                        border-color: var( --color-border );
                        display: flex;
                        justify-content: center;
                        min-width: 35px !important;
                        padding: 0 !important;

                        img {

                            height: 7px;
                            object-fit: contain;
                            object-position: center center;
                            rotate: 180deg;
                            width: 7px;

                        }

                    }

                }

            }

        }

    }

    .stat-cards {

        color: var( --color-primary );
        font-weight: 700;

        display: grid;
        grid-gap: 15px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;

        margin-bottom: 30px;

        .card-div {

            align-items: center;
            display: flex;
            flex-direction: column;
            padding: 20px 15px;
            text-align: center;

        }

        .label {

            font-size: 18px;
            line-height: 20px;
            margin-bottom: 17px;

        }

        .value {

            font-size: 26px;
            font-weight: 900;
            line-height: 29px;

        }

    }

    .users-completed {

        .caption {

            color: var( --color-primary );
            font-size: 22px;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 20px;

        }

    }

    .push-form {

        label {

            color: var( --color-primary );
            display: flex;
            font-size: 18px;
            line-height: 20px;
            font-weight: 700;
            margin-bottom: 13px;

        }

        input, textarea {

            color: #363638 !important;
            font-size: 16px !important;
            font-weight: 700 !important;
            line-height: 17px !important;
            padding: 14px 20px !important;
            width: calc( 100% - 40px ) !important;

        }

        textarea {

            color: var( --color-primary ) !important;
            font-weight: 700 !important;
            height: calc( 3 * 17px ) !important;

        }

        footer {

            display: flex;
            justify-content: flex-end;

        }

    }

    .quest-group-quest-delete-confirm-modal {

        text-align: center;

        .button {

            margin: 0 10px;

            &--warning {

                background-color: rgba( 195, 8, 112, 0.2 );
                border-color: #C30870;
                color: #C30870;

            }

        }

        .modal-title {

            color: var( --color-primary );
            font-size: 26px;
            font-weight: 900;
            line-height: 29px;
            margin-bottom: 40px;

        }

        .modal-description {

            color: #59595B;
            font-size: 16px;
            line-height: 17px;
            margin-bottom: 28px;
            padding: 0 100px;

        }

    }

    &-header {

        align-items: center;
        display: flex;
        width: 100%;

        &__title {

            color: var( --color-primary );
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
            margin-right: auto;

        }

        &__leagues {

            display: flex;
            margin-right: 25px;

            .league {

                align-items: center;
                border: 1px solid #DDDDDD;
                border-radius: 8px;
                background-color: #FFFFFF;
                box-shadow: 0 0 10px 0 rgba(2,103,170,0.4);
                display: flex;
                flex-direction: column;
                margin-right: 10px;
                padding: 16px 36px;
                width: calc( ( 100% - 60px ) / 4 - 40px - 4px );

                &--selected {

                    border: 2px solid #0267AA;

                }

                &__icon {

                    img {

                        height: 23px;
                        object-fit: contain;
                        object-position: center center;
                        width: 23px;

                    }

                }

            }

        }

        .button {

            &--expand {

                align-items: center;
                background-color: transparent;
                border-color: var( --color-border );
                display: flex;
                justify-content: center;
                height: 35px;
                min-width: 35px !important;
                padding: 0 !important;

                img {

                    height: 7px;
                    object-fit: contain;
                    object-position: center center;
                    rotate: 180deg;
                    width: 7px;

                }

            }

        }

    }

}