.push-page {

    display: flex;
    flex-direction: column !important;
    padding: 30px !important;
    width: calc( 100% - 60px ) !important;

    .field {

        margin-bottom: 20px;

    }

    label {

        color: var( --color-primary );
        display: flex;
        font-size: 18px;
        line-height: 20px;
        font-weight: 700;
        margin-bottom: 13px;

    }

    input, textarea {

        color: #363638 !important;
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 17px !important;
        padding: 14px 20px !important;
        width: calc( 100% - 40px ) !important;

    }

    textarea {

        color: var( --color-primary ) !important;
        font-weight: 700 !important;
        height: calc( 3 * 17px ) !important;

    }

    footer {

        display: flex;
        justify-content: flex-end;

    }

}