.page--experience-points {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        .navigation-title {

            margin-bottom: 30px;

        }

    }

    .card-div {

        flex-direction: column;
        margin-bottom: 30px;

    }

    .caption {

        color: var( --color-primary );
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 15px;

    }

    .row-field {

        align-items: center;
        display: flex;
        margin-bottom: 15px;

        img {

            margin-left: 15px;

        }

        label {

            color: var( --color-primary );
            display: flex;
            font-size: 18px;
            line-height: 20px;
            font-weight: 700;

            margin-right: 35px;
            width: 294px;

            &.percentage {

                margin-left: 26px;

                & + div.field {

                    width: 100px;

                }

            }

        }

        .field {

            width: 356px;

        }

        input, textarea {

            color: #363638 !important;
            font-size: 16px !important;
            font-weight: 700 !important;
            line-height: 17px !important;
            padding: 14px 20px !important;
            width: calc( 100% - 40px ) !important;

        }

        textarea {

            color: var( --color-primary ) !important;
            font-weight: 700 !important;
            height: calc( 3 * 17px ) !important;

        }

        .description-editor-container {

            margin-left: auto;

        }

        .area--description {

            min-height: calc( 3 * 19px ) !important;

        }

        .option {

            align-items: center;
            display: flex;
            margin-right: 32px;

            input {

                margin-right: 8px;
                width: unset !important;

            }

        }

    }

}