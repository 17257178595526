.page--events-list {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        padding: 15px;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: 100%;

        }

        .toolbar {

            margin-bottom: 10px;
            margin-top: 31px;
            width: 100%;
            display: flex;

            @media (max-width: 576px) {

                flex-wrap: wrap;

                .field--search {

                    width: calc( 100% - 22px - 16px - 16px - 2px - 11px );

                }

                .button {

                    height: 38px;
                   
                }

            }   

            button {

                margin-left: 11px;

            }

            .calendar-button {

                padding: 6px 16px;
                min-width: 56px;
                width: 56px;

                @media (max-width: 576px) {

                    margin-left: 0;
                    margin-top: 11px;

                }

            }

            .new-button {

                @media (max-width: 576px) {

                    margin-top: 11px;
                    width: calc( 100% - 56px - 11px );

                }

                @media (max-width: 252px) {

                    margin-left: 0px;

                }

            }

        }

        .actions {

            margin-bottom: 22px;

            button {

                min-width: 264px;

                @media (max-width: 576px) {

                    width: 100%;

                }

            }

        }

    }

    .filters {

        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;

        .select-box--box {

            margin-top: 0;

        }

        .politician-filter {

            width: 300px;

        }

    }

}
