@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0");

// @font-face declarations moved to -> src/asset/scss/font.scss
@import "./font.scss";

:root {
  --font-raleway: "DK Raleway", sans-serif;
  --font-display: "DK Display", sans-serif;
  --font-primary: var(--font-raleway);
  --font-seconary: var(--font-display);
  --color-blue: #0267aa;
  --color-dark: #363638;
  --color-gray: #59595b;
  --color-green: #27b06c;
  --color-light-gray: #f0f0f0;
  --color-pink: #c30870;
  --color-yellow: #ffd201;
  --color-white: #ffffff;
  --color-primary: var(--color-blue);
  --color-font: var(--color-dark);
  --color-placeholder: var(--color-gray);
  --color-highlight: var(--color-yellow);
  --color-border: #d6d6d6;
  --color-warning: var(--color-pink);
  --color-done: var(--color-green);
  --color-not-done: var(--color-pink);
  --transition-speed: 333ms;
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  color: var(--color-font);
  font-family: var(--font-primary);
  font-size: 1rem;
  letter-spacing: normal;
  line-height: 1;
  margin: 0;
  overflow-y: scroll;

  @media (max-width: 576px) {
    &.image-editor-no-scroll {
      overflow: hidden !important;

      .page {
        overflow: hidden !important;
      }
    }
  }
}

.field {
  input {
    background-color: transparent;
    border: 1px solid #dddddd;
    border-radius: 10px;
    color: var(--color-font);
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 700;
    height: 17px;
    line-height: 17px;
    outline: none;
    padding: 18px 15px;
    transition: border-color var(--transition-speed);
    width: calc(100% - 15px - 15px);

    &:focus {
      border-color: var(--color-primary);
    }

    &::placeholder {
      color: var(--color-placeholder);
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  &--small {
    input {
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;
      height: 19px;
      line-height: 19px;
      padding: 11px 16px;
      width: calc(100% - 16px - 16px);
    }

    &.field--text {
      input {
        // ToDo: remove all !important

        border-radius: 8px !important;
        font-size: 16px !important;
        height: 17px !important;
        line-height: 19px !important;
        padding: 11px 16px !important;
        width: calc(100% - 16px - 16px) !important;
      }
    }
  }

  &--error {
    input {
      background-color: rgba(195, 8, 112, 0.1);
      border-color: var(--color-warning) !important;
    }
  }
}

.button {
  background-color: var(--color-primary);
  border: 1px solid transparent;
  border-radius: 21.5px;
  color: var(--color-white);
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  min-width: 186px;
  padding: 8px 24px;
  transition: background-color var(--transition-speed);

  &:hover {
    background-color: var(--color-highlight);
  }

  &[disabled] {
    // background-color: var(--color-gray);
    opacity: 0.25;
    pointer-events: none;
    cursor: not-allowed;
  }

  &--ghost {
    background-color: white;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    transition: border-color var(--transition-speed),
      color var(--transition-speed);

    &:hover {
      background-color: var(--color-white);
      border-color: var(--color-highlight);
      color: var(--color-highlight);
    }

    &[disabled] {
      background-color: var(--color-white);
      border-color: var(--color-gray);
      color: var(--color-gray);
      opacity: 0.25;
    }
  }

  &--smaller {
    font-size: 12px;
    line-height: 13px;
    padding: 6px 24px;
  }
}

.button-white {
  background-color: white;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.button-red {
  background-color: rgba(195, 8, 112, 0.2);
  border: 1px solid var(--color-pink);
  color: var(--color-pink);
}

.button-icon {
  min-width: 56px;
  padding: 6px 16px;
  height: 100%;

  .icon-white {
    color: white !important;
  }
}

.title-text-h1 {
  text-align: left;
  font-size: 26px;
  font-weight: 900;
  line-height: 29px;
  margin: 0;
  color: var(--color-primary);
  font-family: var(--font-primary);
}

.title-text-h2 {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  color: var(--color-primary);
  font-family: var(--font-primary);
}

.title-text-h3 {
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;
  color: var(--color-primary);
  font-family: var(--font-primary);
}

.label-text {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-font);
  font-family: var(--font-primary);
}

.header-text {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  color: var(--color-dark);
  font-family: var(--font-primary);
}

.navigation-title {
  align-items: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  position: relative;

  @media (max-width: 576px) {
    flex-direction: column;
  }

  .title-text-h1 {
    margin-right: 6px;
  }

  a {
    text-decoration: none;
  }
}

.card-div {
  background-color: white;
  width: calc(100% - 20px - 20px);
  padding: 14px 20px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);

  .column {
    flex-direction: column;
  }
  @media (max-width: 576px) {
    width: calc(100% - 35px - 35px);
  }
}

.card-div-wrap-content {
  background-color: white;
  padding: 14px 20px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  border: 1px solid transparent;
  border-radius: 8px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);

  .column {
    flex-direction: column;
  }
}

.check-box-group {
  height: calc(48px - 20px);
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: 20px;

  label {
    margin-right: 26px;
  }

  input {
    appearance: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-primary);
    transition: 0.2s all linear;
    margin-right: 8px;
    position: relative;
    top: 4px;
  }

  input:checked {
    background: var(--color-primary);
    border: 1px solid var(--color-primary);
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  position: relative;
  margin-top: 30px;

  .column {
    flex-direction: column;
    margin-top: 0px;
  }
}

.container-wrap-conent {
  align-items: left;
  display: flex;
  flex-direction: row;
  justify-content: left;
  position: relative;
  margin-top: 30px;
}

.sub-container {
  align-items: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.page {
  width: 100%;

  .main-div > header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    > h1 {
      color: var(--color-primary);
      font-size: 26px;
      font-weight: 900;
      line-height: 30px;
      margin: 0;
    }

    .stat {
      color: var(--color-gray);
      font-size: 16px;
      font-weight: 700;
      line-height: 17px;
      text-align: right;
    }
  }

  .toolbar {
    margin-bottom: 30px;
  }

  @media (max-width: 1640px) {
    margin-left: 53px !important;
  }
}

.table-pager-text {
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: var(--color-font);
  font-family: var(--font-primary);
}

.table-pager-button {
  background-color: white;
  border: 1px solid transparent;
  border-radius: 20px;
  color: var(--color-border);
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  width: 26x;
  transition: background-color var(--transition-speed);
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.08);

  &:hover {
    background-color: var(--color-highlight);
    color: black;
  }

  &[disabled] {
    background-color: var(--color-border);
    color: white;
    cursor: not-allowed;
  }
}

@keyframes loading {
  0% {
    border-color: rgb(40, 168, 223) transparent transparent transparent;
    transform: rotate(0deg);
  }

  12.5% {
    border-color: rgb(40, 168, 223) transparent transparent transparent;
  }

  25% {
    border-color: rgb(1, 103, 170) transparent transparent transparent;
    transform: rotate(360deg);
  }

  37.5% {
    border-color: rgb(1, 103, 170) transparent transparent transparent;
  }

  50% {
    border-color: rgb(195, 8, 112) transparent transparent transparent;
    transform: rotate(720deg);
  }

  62.5% {
    border-color: rgb(195, 8, 112) transparent transparent transparent;
  }

  75% {
    border-color: rgb(255, 210, 0) transparent transparent transparent;
    transform: rotate(1080deg);
  }

  87.5% {
    border-color: rgb(255, 210, 0) transparent transparent transparent;
  }

  100% {
    border-color: rgb(40, 168, 223) transparent transparent transparent;
    transform: rotate(1440deg);
  }
}

.loading {
  animation: loading 4s linear infinite;
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  height: 44px;
  width: 44px;
}
