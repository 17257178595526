.page--admin-user {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    .activities {

        .tabs {

            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;

            a {

                border-bottom: 2px solid transparent;
                cursor: pointer;
                font-size: 18px;
                line-height: 20px;
                margin-right: 20px;
                margin-bottom: 9px;
                padding-bottom: 6px;

                &.selected {

                    color: var( --color-primary );
                    border-color: var( --color-primary );
                    font-weight: 700;

                }

            }

        }

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        > header {

            a {

                color: inherit;
                text-decoration: none;

            }

        }

    }

    .card-div {

        border: none;
        flex-direction: column;
        padding: 20px;
        margin-bottom: 30px;

        header {

            margin-bottom: 18px;

        }

        .caption {

            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            margin-bottom: 15px;

        }

    }

    .admin-details {

        align-items: center;
        display: flex;
        flex-direction: row;

        .admin-name {

            color: var( --color-primary );
            font-size: 26px;
            font-weight: 900;
            line-height: 30px;
            margin-right: 120px;

        }

        .admin-email {

            color: #59595B;
            display: flex;
            flex-direction: column;
            font-size: 14px;
            line-height: 16px;
            margin-right: auto;

            label {

                color: var( --color-primary );
                font-size: 22px;
                font-weight: 700;
                line-height: 24px;
                margin-bottom: 8px;

            }

        }

        .actions {

            .button {

                margin-left: 25px;
                min-width: 215px;

                &--warning {

                    background-color: rgba( 195, 8, 112, 0.2 );
                    border-color: #C30870;
                    color: #C30870;
                    min-width: 171px;

                }

            }

        }

    }

    h2 {

        color: var(--color-primary);
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        margin: 0;

    }

    .admin-activities {

        max-height: 500px;
        padding-right: 20px;
        overflow-y: auto;

        &__empty {
            font-size: 14px;
            padding: 20px;
            text-align: center;
        }

    }

}
