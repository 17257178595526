.course-block {

    &__caption {

        color: var( --color-primary );
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 15px;

    }

    &__container {

        background-color: white;
        border-radius: 8px;
        box-shadow: 0 0 10px 0 rgba( 0, 0, 0, 0.08 );
        overflow: hidden;

    }

    &__image {

        aspect-ratio: 359 / 189;
        object-fit: cover;
        object-position: center center;
        width: 100%;

    }

    &__panel {

        display: flex;
        flex-wrap: wrap;
        padding: 8px 10px 12px 15px;

    }

    &__available {

        color: #C30870;
        font-size: 14px;
        line-height: 16px;

    }

    &__questions {

        color: #59595B;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
        margin-left: auto;

    }

    &__title {

        color: var( --color-primary );
        font-size: 18px;
        font-weight: 700;
        line-height: 21px;
        max-height: 42px;
        margin-top: 8px;
        width: 100%;

    }

}