.page--courses-list {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        align-items: flex-start;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;


        @media (max-width: 576px) {

            width: calc(100% - 0px);

        }

        .toolbar {

            display: flex;
            margin: 30px 0;

            button {

                margin-left: 15px;

            }

        }

        .card-div {

            border: none;
            margin-bottom: 30px;
            padding: 20px;
            width: calc( 100% - 40px );

            &--table {

                padding: 0 !important;
                width: 100%;

            }

            &--highlighted {

                flex-direction: column;

                h2 {

                    margin-bottom: 15px;

                }

                .card-div {

                    align-items: center;
                    display: flex;
                    margin-bottom: 0;

                    .title {

                        color: #363638;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 16px;
                        margin-right: 50px;
                        width: 400px;

                    }

                    .topic-holder {

                        display: flex;
                        justify-content: center;
                        margin-right: 50px;
                        width: 213px;

                        .course-topic {

                            width: min-content;

                        }

                    }

                    .start-date {

                        color: #363638;
                        font-size: 14px;
                        line-height: 16px;
                        margin-right: 50px;

                    }

                    .fills {

                        align-items: center;
                        color: #363638;
                        display: flex;
                        font-size: 17px;
                        line-height: 20px;
                        margin-right: 50px;

                        span {

                            font-size: 14px;
                            line-height: 16px;
                            margin-right: 4px;

                        }

                    }

                    .button--menu--container {

                        margin-left: auto;
                        width: 30px;

                    }

                }

            }

        }

    }

}