.push-message-table {

    .push-title {

       height: 16px;
        margin-right: 15px;
        overflow: hidden;
        word-break: break-all; 

    }
    
    .push-body {

        height: 16px;
        margin-right: 15px;
        overflow: hidden;
        word-break: break-all;

    }

}