
.page-create-politician {

    .info-slot {
        display: flex;
        flex-direction: column;
         padding: 0px;

        &__info {
            padding-top: 10px;
            margin-left: 0px ;
            margin-right: 0px;
        }

    }

    .stats-container {

        @media (max-width: 576px) {

            flex-direction: column;

            .card-div {

                margin-bottom: 15px;

                &:last-child {

                    margin-bottom: 0;

                }

            }

        }

    }
}