.winner-modal {

    .modal-title {

        color: var( --color-primary );
        font-size: 22px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 30px;

    }

    .columns {

        > .field {

            align-items: center;
            display: flex;
            margin-bottom: 30px;

            label {

                color: var( --color-primary );
                font-size: 18px;
                font-weight: 700;
                line-height: 20px;
                margin-right: 16px;
                width: 162px;

            }

            span {

                color: #363638;
                font-size: 16px;
                line-height: 17px;
                margin-top: 3px;
                margin-left: 14px;

            }

            input, textarea {

                color: #363638 !important;
                font-size: 16px !important;
                font-weight: 400 !important;
                line-height: 17px !important;
                margin-top: 4px;
                padding: 8px 16px !important;
                width: 398px !important;

            }

            textarea {

                color: var( --color-primary ) !important;
                font-weight: 700 !important;
                height: calc( 3 * 17px ) !important;

            }

            .area--text {

                margin-top: 0;

            }

        }

    }

    .color-select {

        display: flex;
        margin-top: 10px;

        .color {

            border: 1px solid transparent;
            border-radius: 50%;
            padding: 3px;

            .color-circle {

                border-radius: 50%;
                height: 26px;
                width: 26px;

            }

            &--selected {

                border-color: var( --color-primary );

            }

        }

    }

    footer {

        display: flex;
        justify-content: flex-end;

        .button {

            &:last-child {

                margin-left: 20px;

            }

        }

    }

}