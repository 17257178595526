.input-modal {

    width: 1156px;
    height: auto;

    @media (max-width: 576px) {

        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        width: 100%;

    }


    &__header {
        margin-top: 5px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        color: var(--color-primary);
        font-size: 26px;
        font-weight: 900;
        line-height: 30px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .close-button {
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 13px;
        color: var(--color-border);
        font-family: var(--font-primary);
        font-size: 18px;
        font-weight: 300;
        line-height: 18px;
        cursor: pointer;
        width: 26px;
        height: 26px;
        transition: background-color var(--transition-speed);
        &:hover {
            background-color: var(--color-highlight);
        }
    }

    &__footer {
        margin-top: 30px;
        display: flex;
        justify-content: right;
        align-items: right;
    }

}
