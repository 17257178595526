.floating-message-table {


    .message-body {

        height: 16px;
        margin-right: 15px;
        overflow: hidden;
        word-break: break-all;

    }

    .floating_icon {

        height: 50px;
        width: 50px;


    }

}