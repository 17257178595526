
.politician-phrase-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    .container-top-margin {
        margin-top: 28px;
    }

}