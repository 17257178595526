.politician-phrase-list {

    &__politician-phrase-element {
        list-style: none;
        margin-top: 10px;
        border: 1px solid var(--color-border);
        border-radius: 8px;
        padding: 8px 18px;
        width: calc(100% - 18px - 18px);
        min-height: calc(54px - 8px - 8px);
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .phrase-container {
            display:flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            width: 100% ;

            .phrase {
                width: 96%;
                font-size: 16px;
                font-weight: 400;
                line-height: 17px;
                margin: 0 !important;

            }
            
            .menu-container {
                width: 4%;
            }
        }

    }

}

